.login {
    margin-top: 1.5625rem;
    .error-box {
        margin-left: 85.7px;
        @media screen and (max-width: 767px) {
            margin-left: auto;
            width: 21.0625rem !important;
        }
    }
    &--over {
        box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17);
        background-color: #ffffff;
        z-index: 20;
        height: 100%;
        @media screen and (min-width: 768px) {
            margin-top: -7.8125rem;
            // max-width: 97%;
            margin-left: auto;
            margin-right: auto;
        }
        .login__form {
            background-color: #bf232a;
            height: 100%;
            h2 {
                color: #fff;
            }
            .form-group {
                label {
                    color: #fff;
                }
                input {
                    border: 0;
                    border-radius: 0;
                }
            }
            .login__form__info {
                h6 {
                    color: #fff;
                }
                a {
                    color: #fff;
                }
            }
            input[type="submit"] {
                color: $mainTextClr;
                background-color: #fff;
                background-image: url('assets/images/arrow-main-color.svg');
            }
            @media screen and (min-width: 768px) {
                padding: 1.875rem 2.25rem 1rem 1.25rem;
            }
        }
        .login__desc {
            background-color: #fff;
            padding: 1.875rem 1.25rem 1rem 2.375rem;
            h6 {
                font-weight: normal;
                font-size: 2.125rem;
            }
            ul {
                li {
                    &::before {
                        background-color: #353535;
                    }
                }
            }
        }
    }
    &__form {
        background-color: #fff;
        padding: 0.75rem 0.75rem 1.5rem 0.75rem;
        
        @media screen and (min-width: 768px) {
            padding: 0.75rem 1.875rem 2.5rem 1.4375rem;
        }
        @media screen and (max-width: 767px) {
            display: flex;
            flex-direction: column;
        }
        &__wrapper {
            .Error {
                input {
                    border:0;
                }
            }
            @media screen and (max-width: 575px) {
                max-width: 100% !important;
                width: 100% !important;
            }
            @media screen and (max-width: 767px) and (min-width: 576px) {
                width: 20.0625rem;
                align-self: center;
            }
            &--signup {
                @media screen and (max-width: 767px) {
                    width: 21.0625rem !important;
                }
                .form-group {
                    @media screen and (min-width: 768px) {
                        width: 18.85625rem;
                    
                    }
                    @media screen and (min-width: 992px) {
                        width: 22.85625rem;
                    }
                    .form-control {
                        @media screen and (min-width: 768px) {
                            width: 12rem !important;
                        }
                        @media screen and (min-width: 992px) {
                            width: 15rem !important;
                        }
                    }
                }
            }
        }
        h2 {
            font-size: 2.125rem;
            font-weight: normal;
            color: #353535;
            margin-bottom: 1.5rem;
        }
        .form-group {
            &--error {
                label {
                    color: #fe3636 !important;
                }
                .form-control {
                    border: solid 1px #fe3636 !important; 
                }
            }
            &--captcha {
                width: 15.3125rem !important;
                @media screen and (max-width: 767px) {
                    margin-bottom: 5.3125rem;
                }
            }
            .form-control {     
                width: 15rem;
                @media screen and (min-width: 992px) {
                    width: 17.5rem;
                }
                &:focus {
                    outline: none;
                    box-shadow: none;
                    border-color: #ced4da;
                }
                border-radius: 3px;
                border: solid 1px #e7e7e7;
            }
            label {
                font-size: 1rem;
                font-weight: bold;
                color: #353535;
                text-align: right;
            }
        }
        .custom-checkbox {
            margin-left: auto;
            margin-bottom: 1rem;
            width: 15rem;
            @media screen and (min-width: 992px) {
                width: 17.5rem;
            }
            input[type="checkbox"] {
                position: absolute;
                z-index: -1;
                opacity: 0;
                &:checked {
                    ~ label {
                        &::before {
                            border-color: #fff;
                            background-color: #fff;
                        }
                        &::after {
                            content: '\2713';
                            top: 2px;
                            left: 2px;
                            font-size: 0.875rem;
                            background-image: unset;
                            color: $mainTextClr;
                        }
                    }
                }
            }
            label {
                font-size: 1rem;
                font-weight: bold;
                color: #fff;
                &:before {
                    position: absolute;
                    left: 0;
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    pointer-events: none;
                    content: "";
                    background-color: #fff;
                    top: .5rem;
                    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
                }
                &:after {
                    position: absolute;
                    left: 0;
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    content: "";
                    background: no-repeat 50% / 50% 50%;
                    top: .5rem;
                }
            }
        }
        input[type="submit"] {
            font-weight: bold;
            font-size: 1rem;
            text-align: left;
            color: #fff;
            background-color: $mainTextClr;
            border:0;
            border-radius: 0;
            padding: 0.77rem 0.77rem;
            height: 3.375rem;
            background-image: url('assets/images/arrow.svg');
            
            background-repeat: no-repeat no-repeat;
            background-position: right 1.125rem center;
            margin-left: auto;
            width: 15rem;
            &.btn.btn-primary:not(.disabled):active, .btn.btn-primary:not(:disabled):active {
                background-color: #fff;
                border-color: #fff;
                color: $mainTextClr;
            }
            @media screen and (min-width: 992px) {
                width: 17.5rem;
                padding-left: 2rem;
            }
        }
        &__info {
            padding-top: 1.5625rem;
            h6, a {
                font-weight: bold;
            }
            a {
                color: $mainTextClr;
                text-decoration: underline;
            }
            color: #353535;
            text-align: right;
        }
    }
    &__desc {
        h6 {
            font-size: 1rem;
            font-weight: bold;
            line-height: normal;
            margin-bottom: 1.8125rem;
            color: #353535;
        }
        ul {
            li {
                font-size: 1rem;
                line-height: 1.38;
                color: #444;
                position: relative;
                padding-left: 1.0625rem;
                &:before {
                    content: '';
                    position: absolute;
                    left:0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 8px;
                    border-radius: 50%;
                    height: 8px;
                    
                    background-color: $mainTextClr;
                   
                }
            }
        }
        .content-text {
            padding-top: 1rem;
        }
    }
  
}