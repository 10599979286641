
nav.tabs-nav {
    border-top:0;
    ul {
        border-bottom: 0;
        li {
            &.nav-item {
                &:not(:last-child) {
                    @media screen and (min-width: 768px) {
                        margin-right: 6.25rem;
                    }
                }
            }
            a {
                font-weight: bold;
                color: #353535;
                &.nav-link {
                    border: 0;
                    &.active {
                        border: 0;
                        color: #bf232a;
                        background-color: transparent;
                        border-color: transparent;
                    }
                }
                &:hover {
                    border: 0;
                }
            }
        }
    }
    @media screen and (min-width: 768px) {
        margin-bottom: 1.5625rem;
    }
}
