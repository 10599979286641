.error-box {
    background-color: #fff8cf;
    color: #fe3636;
    font-size: 1rem;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    padding: 1rem 1rem;
    max-width: 17.5rem;
    display: none;
}

.form-group--error {
    label {
        color: #fe3636 !important;
    }
    input {
        border: solid 1px #fe3636 !important; 
    }
    textarea {
        border: solid 1px #fe3636 !important;
    }
}

// Kentico Error

.form-group label.Error{
    color: #fe3636 !important;
}

.EditingFormLabel.Error {
    color: #fe3636 !important;
}

.Error input[type="text"], .Error input[type="number"], .Error input[type="email"], .Error input[type="password"], .Error input[type="date"] {
    border: solid 1px #fe3636 !important; 
}

.form-group--required {
    label {
        position: relative;
        &:after {
            content: '*';
            position: absolute;
            right:6px;
            top: -3px;
            color: #fe3636;
            @media screen and (max-width: 767px) {
                right: -10px;
            }
        }
    }
}

span.InfoLabel {
    font-size: 55px;
}
.success-reset-password {
    background-color: #fff8cf;
    color: #fe3636;
    font-size: 1rem;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    padding: 1rem;
    max-width: 17.5rem;
    margin-top: 15px;
    margin-left: auto;
}

.form-group--required .Error {
    color: #fe3636;
}

.Error label {
    color: #fe3636 !important;
}


label.Error {
    color: #fe3636 !important;
}
.logon-password-retrieval-result {
    background-color: #fff8cf;
    color: #fe3636;
    font-size: 1rem;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    padding: 1rem;
    max-width: 17.5rem;
    margin-top: 15px;
    display: block;
    margin-left: auto;
}

@media screen and (max-width: 767px) {
    .login__form__wrapper--signup {
        .form-group--required label:after {
            right: 6px;
        }
    }
}
