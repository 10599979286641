@import "_request-quote";
@import "_saved-items";
@import "_my-profile";
@import "_my-profile";
@import "_rma";
@import "_css";

// Start Tabs Nav
// nav.digitron-tabs {
//     margin-top: 1.5625rem;
//     border-top: 0;
//     @media screen and (min-width: 768px) {
//         margin-top: 2.8125rem;
//     }
//     .nav-tabs {
//         flex-direction: column;
//         @media screen and (min-width: 768px) {
//             flex-direction: row;
//         }
//         border-bottom: 0;
//         a {
//             border:0;
//             @media screen and (max-width: 767px) {
//                 padding-left: 0;
//             }
//             &:first-child {
//                 padding-left: 0;
//             }
//             &:last-child {
//                 padding-right: 0;
//                 padding-left: 0;
//                 padding-top: 2.5rem;
//                 @media screen and (min-width: 768px) {
//                     padding-top: 0.5rem;
//                     margin-left: auto;
//                 }
//             }
//             color: #353535;
//             font-weight: bold;
//             &.active {
//                 color: $mainTextClr;
//                 border:0;
//                 background-color: transparent;
//             }
//             &:hover {
//                 border:0;
//             }
//             @media screen and (min-width: 1200px) {
//                 &:not(:first-child) {
//                     padding-left: 5rem
//                 }
//             }
//         }
//     }
// }

#digitron-tabContent {
    margin-top: 1rem;
    @media screen and (min-width: 768px) {
        margin-top: 2.8125rem;
    }
    table.table tbody tr td:first-child {
        align-items: flex-start;
    }
}