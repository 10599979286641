.news {
    &__story {
        border-left: 2px solid $mainTextClr;
        transition: all .25s ease-in-out;
        margin-bottom: 2rem;
        background-color: #fff;
        min-height: 8.125rem;
        color: #353535;
        padding: 2rem 1.375rem 2.3125rem 1rem;
        @media screen and (min-width: 768px) {
            padding: 0.9375rem 1.875rem 0.9375rem 0.9375rem;
        }
        &:hover {
            text-decoration: unset;
            color: #353535;
            box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17);
            cursor: pointer;
        }
        &__thumbnail {
            img {
                width: 6.25rem;
                height: 6.25rem;
                object-fit: cover;
            }
            &__text {
                width: 100%;
                h6 {
                    font-size: 1rem;
                    line-height: normal;
                }
                h2 {
                    font-size: 2.125rem;
                    line-height: normal;
                    margin-bottom: 0;
                }
                @media screen and (min-width: 768px) {
                    margin-left: 1.875rem;
                }
                @media screen and (min-width: 1200px) {
                    max-width: 25.9375rem;
                }
            }
        }
        &__text {
            width: 100%;
            @media screen and (min-width: 1200px) {
                padding-left: .8rem;
                max-width: 30.3125rem;
            }
            p {
                font-size: 0.875rem;
                line-height: 1.29;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            a {
                color: $mainTextClr;
                &:hover {
                    text-decoration: unset;
                }
            }
        }
    }
}