body.error-page {
    padding-bottom: 0;
    main {
        @media screen and (max-width: 767px) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }
}

// 404 page
main.not-found-page {
    .text {
        h1 {
            font-size: 6.25rem;
            font-weight: bold;
            color: $mainTextClr;
        }
        h2 {
            font-size: 2.625rem;
            color: #353535;
            font-weight: bold;
            text-transform: uppercase;
        }
        p {
            font-size: 1.1rem;
            line-height: 1.5rem;
            letter-spacing: 1.1px;
            text-transform: uppercase;
            color: #353535;
        }
    }
    .actions {
        margin-top: 2.5rem;
        a:hover {
            text-decoration: unset;
        }
        a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media screen and (min-width: 576px) {
                max-width: 14rem;
                width: 100%;
            }
        }
        a:first-child {
            color: #bf232a;
            border: 1px solid #bf232a;
            background-color: #fff;
            font-weight: 700;
            width: 100%;
            padding: 1rem 1rem 1rem 1.375rem;
            transition: all .25s ease-in-out;
            span {
                padding-right: 3.125rem;
            }
        }
        a:last-child {
            color: #fff;
            border: 1px solid #bf232a;
            background-color: #bf232a;
            font-weight: 700;
            width: 100%;
            padding: 1rem 1rem 1rem 1.375rem;
            transition: all .25s ease-in-out;
            span {
                padding-right: 3.125rem;
            }
        }
    }
    .emojii {
        @media screen and (max-width: 767px) {
            margin-top: 3rem;
        }
        svg {
            @media screen and (max-width: 767px) {
                max-width: 45%;
            }
            @media screen and (min-width: 992px) {
                width: 21.875rem;
            }
        }
    }
}