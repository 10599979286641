@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i&display=swap");
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit; }

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0; }

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch; }

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto;
  /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */ }

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table; }

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none; }

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0; }

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1; }

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden; }

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none; }

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all; }

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 7px;
  min-height: 10px; }

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: black;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear; }

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear; }

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px; }

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px; }

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto; }

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0; }

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll; }

.btn {
  padding: 0.77rem 0.77rem; }
  .btn.btn-primary {
    border-radius: 3px;
    border: solid 1px #bf232a;
    background-color: #bf232a;
    color: #fff;
    font-weight: bold; }
    .btn.btn-primary:not(.disabled):active, .btn.btn-primary:not(:disabled):active {
      background-color: #bf232a;
      border-color: #bf232a; }
    .btn.btn-primary:not(:disabled):not(.disabled):active:focus {
      outline: none;
      box-shadow: none; }
    .btn.btn-primary:focus {
      box-shadow: none; }
  .btn.btn-secondary {
    border-radius: 3px;
    border: solid 1px #bf232a;
    background-color: #ffffff;
    font-weight: bold;
    color: #bf232a; }

button.filtering-btn {
  color: #bf232a;
  border: solid 1px #bf232a;
  background-color: #ffffff;
  font-weight: bold;
  border-radius: 0;
  line-height: normal;
  width: 100%; }
  button.filtering-btn:focus {
    box-shadow: unset;
    outline: none; }
  @media screen and (min-width: 992px) {
    button.filtering-btn {
      width: auto; } }

table.table.table-selector thead {
  background-color: #fbfbfb; }
  table.table.table-selector thead th {
    font-size: 0.8rem;
    line-height: normal;
    padding: 0.7rem 0.5rem; }

table.table.table-selector tbody tr td {
  padding: 1rem 0rem; }
  table.table.table-selector tbody tr td .btn {
    padding: 0.4rem 0.4rem; }
  @media screen and (min-width: 992px) {
    table.table.table-selector tbody tr td {
      padding: 0.5rem 0.5rem; } }

table.table thead {
  border-radius: 3px;
  border: solid 1px #bf232a; }
  table.table thead tr th {
    font-size: 0.8rem;
    font-weight: normal;
    color: #bf232a;
    position: relative; }
    table.table thead tr th[colspan="1"] {
      padding-bottom: 0; }
    table.table thead tr th:not(:last-child):before {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0.5px;
      height: 100%;
      background-color: #ededed; }
    table.table thead tr th:not(:first-child):after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0.5px;
      height: 100%;
      background-color: #ededed; }
    table.table thead tr th[colspan="4"] + th:after {
      width: 1px; }
    table.table thead tr th[colspan="4"]:after {
      display: none; }
    table.table thead tr th[colspan="4"]:before {
      display: none; }
    table.table thead tr th span {
      font-size: 0.75rem;
      font-weight: bold; }
      table.table thead tr th span sub {
        font-size: 0.4375rem; }
  table.table thead tr.units th {
    padding: 0; }
    table.table thead tr.units th:last-child:before {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0.5px;
      height: 100%;
      background-color: #ededed; }
    table.table thead tr.units th sub {
      font-size: 85%; }
  table.table thead tr.units:last-of-type th {
    padding-bottom: 0.4rem; }

table.table th,
table.table td {
  border-bottom: 0;
  border-top: 0; }

table.table tbody tr:nth-of-type(odd) {
  background-color: #fbfbfb; }

table.table tbody tr:nth-of-type(even) {
  border-radius: 3px;
  background-color: #efefef; }

table.table tbody tr td {
  font-size: 0.75rem;
  font-weight: normal;
  color: #3b3b3b;
  padding: 0.5rem; }
  @media screen and (min-width: 992px) {
    table.table tbody tr td {
      font-size: 0.875rem; } }
  @media screen and (max-width: 1199px) {
    table.table tbody tr td:first-child {
      display: flex;
      flex-direction: column;
      align-items: center; } }
  @media screen and (min-width: 1200px) {
    table.table tbody tr td {
      padding: 2rem 1.25rem; } }
  table.table tbody tr td:hover {
    cursor: pointer;
    background-color: #d7d7d7; }
  table.table tbody tr td a {
    color: #bf232a; }
    table.table tbody tr td a:hover {
      text-decoration: underline; }
    table.table tbody tr td a.item-saved img {
      width: 25px;
      height: auto; }

.mobile-table {
  border-radius: 3px;
  border: solid 1px #9e9e9e; }
  .mobile-table:not(:first-child) {
    margin-top: 1rem; }
  .mobile-table .table__body__row:nth-child(odd) {
    background-color: #fbfbfb; }
  .mobile-table .table__body__row:nth-child(even) {
    background-color: #efefef; }
  .mobile-table .table__body__row__col {
    padding: 0.625rem 1rem; }
    .mobile-table .table__body__row__col:first-child {
      font-size: 0.75rem;
      font-weight: normal;
      line-height: normal;
      text-align: center;
      color: #1c1c1c;
      width: 40%;
      text-align: center;
      position: relative; }
      .mobile-table .table__body__row__col:first-child:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #9e9e9e; }
      .mobile-table .table__body__row__col:first-child span {
        font-size: 0.625rem;
        font-weight: bold; }
    .mobile-table .table__body__row__col:last-child {
      font-size: 0.875rem;
      font-weight: normal;
      font-style: normal;
      font-stretch: condensed;
      line-height: normal;
      letter-spacing: normal;
      color: #3b3b3b;
      width: 60%; }
      .mobile-table .table__body__row__col:last-child a {
        color: #bf232a; }
        .mobile-table .table__body__row__col:last-child a.item-saved img {
          width: 25px;
          height: auto; }
  .mobile-table .table__body__row input {
    font-size: 1rem;
    font-weight: bold;
    border-radius: 3px;
    border: solid 1px #e7e7e7;
    height: 1.875rem; }
    .mobile-table .table__body__row input:focus {
      outline: none;
      box-shadow: none;
      border: solid 1px #e7e7e7; }
  .mobile-table .table__body__rowspan__colspan {
    font-size: 0.75rem;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    color: #1c1c1c;
    width: 40%;
    position: relative; }
    .mobile-table .table__body__rowspan__colspan div:nth-child(1) {
      padding: 0 0.625rem; }
    .mobile-table .table__body__rowspan__colspan div:nth-child(2) div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 30px;
      padding: 0.625rem 0.625rem; }
      .mobile-table .table__body__rowspan__colspan div:nth-child(2) div:nth-child(even) {
        background-color: #fbfbfb; }
      .mobile-table .table__body__rowspan__colspan div:nth-child(2) div:nth-child(odd) {
        background-color: #efefef; }
      .mobile-table .table__body__rowspan__colspan div:nth-child(2) div:last-child {
        height: 45px; }
    .mobile-table .table__body__rowspan__colspan:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: #9e9e9e; }
  .mobile-table .table__body__rowspan__col {
    font-size: 0.875rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: condensed;
    line-height: normal;
    letter-spacing: normal;
    color: #3b3b3b;
    width: 60%; }
    .mobile-table .table__body__rowspan__col a {
      color: #bf232a; }
    .mobile-table .table__body__rowspan__col div {
      display: flex;
      align-items: center;
      height: 30px;
      padding: 0.625rem 1rem; }
      .mobile-table .table__body__rowspan__col div:nth-child(even) {
        background-color: #fbfbfb; }
      .mobile-table .table__body__rowspan__col div:nth-child(odd) {
        background-color: #efefef; }
      .mobile-table .table__body__rowspan__col div:last-child {
        height: 45px; }

small.arrow-up:hover {
  cursor: pointer; }

small.arrow-up:after {
  display: inline-block;
  margin-top: 0.1rem;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3rem solid;
  border-right: 0.3rem solid transparent;
  border-bottom: 0;
  border-left: 0.3rem solid transparent;
  color: #8d8d8d;
  transform: rotate(180deg); }

small.arrow-down:hover {
  cursor: pointer; }

small.arrow-down:after {
  display: inline-block;
  margin-top: 0.1rem;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3rem solid;
  border-right: 0.3rem solid transparent;
  border-bottom: 0;
  border-left: 0.3rem solid transparent;
  color: #8d8d8d; }

.product-type-table #scrollingcontent {
  position: relative; }

.product-type-table .table {
  min-width: 1020px;
  table-layout: auto; }

.product-type-table .table-sticky {
  transition: 0.25s ease-in-out;
  top: 0;
  position: relative; }
  @media screen and (min-width: 992px) {
    .product-type-table .table-sticky {
      position: sticky; } }
  @media screen and (max-width: 991px) {
    .product-type-table .table-sticky.isSticky, .product-type-table .table-sticky.isFixed {
      top: 166px; } }
  .product-type-table .table-sticky.isSticky thead, .product-type-table .table-sticky.isFixed thead {
    background-color: #fbfbfb; }

.product-type-table .hideTbody {
  table-layout: fixed; }
  .product-type-table .hideTbody thead {
    display: table;
    table-layout: fixed; }
  .product-type-table .hideTbody tbody {
    display: none;
    table-layout: fixed;
    height: 0px;
    z-index: -10;
    opacity: 0;
    visibility: hidden; }

.scrollingcontent {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

nav[aria-label="breadcrumb"] {
  border-top: 0; }
  nav[aria-label="breadcrumb"] ol.breadcrumb {
    background-color: transparent;
    border-radius: 0;
    padding: 1rem 1rem; }
    nav[aria-label="breadcrumb"] ol.breadcrumb li {
      font-size: 1rem; }
      nav[aria-label="breadcrumb"] ol.breadcrumb li.active {
        color: #444; }
      nav[aria-label="breadcrumb"] ol.breadcrumb li:before {
        color: #444; }
      nav[aria-label="breadcrumb"] ol.breadcrumb li .dropdown-menu {
        border: 0;
        border-radius: 0;
        padding: 0.8125rem 0.75rem 0.9375rem 0.75rem;
        box-shadow: 0 5px 10px 0 rgba(245, 0, 11, 0.17);
        background-color: rgba(255, 255, 255, 0.95);
        overflow-y: auto;
        width: 100%;
        margin-top: 0;
        height: 19.0625rem; }
        nav[aria-label="breadcrumb"] ol.breadcrumb li .dropdown-menu .simplebar-track.simplebar-vertical {
          background-color: #dedddd;
          width: 4px; }
          nav[aria-label="breadcrumb"] ol.breadcrumb li .dropdown-menu .simplebar-track.simplebar-vertical .simplebar-scrollbar {
            background-color: #bf232a;
            width: 4px;
            right: 0; }
            nav[aria-label="breadcrumb"] ol.breadcrumb li .dropdown-menu .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
              background-color: transparent; }
        nav[aria-label="breadcrumb"] ol.breadcrumb li .dropdown-menu a.dropdown-item {
          font-weight: bold;
          color: #353535;
          line-height: 1.19;
          white-space: normal;
          padding: 0;
          transition: all .3s ease-in-out; }
          nav[aria-label="breadcrumb"] ol.breadcrumb li .dropdown-menu a.dropdown-item:not(:last-child) {
            margin-bottom: 1rem; }
          nav[aria-label="breadcrumb"] ol.breadcrumb li .dropdown-menu a.dropdown-item:hover {
            background-color: transparent;
            color: #bf232a; }
      nav[aria-label="breadcrumb"] ol.breadcrumb li a {
        font-size: 1rem;
        color: #bf232a;
        display: inline-flex;
        align-items: center; }
        nav[aria-label="breadcrumb"] ol.breadcrumb li a svg {
          width: 9px;
          height: 9px; }
    nav[aria-label="breadcrumb"] ol.breadcrumb a {
      font-size: 1rem;
      color: #bf232a;
      display: inline-flex;
      align-items: center;
      padding-right: 5px; }
      nav[aria-label="breadcrumb"] ol.breadcrumb a svg {
        width: 9px;
        height: 9px; }
      nav[aria-label="breadcrumb"] ol.breadcrumb a:not(:first-child) {
        padding-left: 5px; }
    nav[aria-label="breadcrumb"] ol.breadcrumb span {
      font-size: 1rem;
      display: inline-flex;
      align-items: center;
      padding-left: 5px; }
      nav[aria-label="breadcrumb"] ol.breadcrumb span.active {
        color: #444; }

ul.pagination li:first-child {
  border: solid 1px #bf232a;
  margin-right: 0.875rem; }
  ul.pagination li:first-child .page-link {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0.2rem 0.5rem; }

ul.pagination li:last-child {
  border: solid 1px #bf232a;
  margin-left: 0.875rem; }
  ul.pagination li:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0.2rem 0.5rem; }

ul.pagination li:not(:first-child):not(:last-child):not(:nth-last-child(2)) {
  margin-right: .3rem; }

ul.pagination li.page-item {
  border-radius: 0; }
  ul.pagination li.page-item:not(:first-child):not(:last-child) {
    align-self: center; }
    ul.pagination li.page-item:not(:first-child):not(:last-child) .page-link {
      height: 1.3125rem;
      padding: 0rem 0.4em;
      line-height: normal; }
  ul.pagination li.page-item.active .page-link {
    background-color: #bf232a;
    border-color: #bf232a;
    color: #fff; }

ul.pagination .page-link {
  font-size: 1rem;
  font-weight: bold;
  color: #bf232a;
  background-color: transparent;
  border: 0;
  transition: all 0.25s ease-in-out;
  border-radius: 0; }
  ul.pagination .page-link:hover {
    background-color: #bf232a;
    color: #fff; }
    ul.pagination .page-link:hover svg path {
      stroke: #fff; }
  ul.pagination .page-link:focus {
    box-shadow: none;
    outline: none; }

.tooltip {
  z-index: 100; }
  .tooltip .arrow {
    display: none; }
  .tooltip.show {
    opacity: 1 !important; }
  .tooltip .tooltip-inner {
    font-size: 0.875rem;
    line-height: normal;
    letter-spacing: normal;
    font-weight: normal;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(255, 139, 60, 0.5);
    background-color: #fff4d5;
    color: #3b3b3b;
    opacity: 1; }

#filtering.show, #cat-links.show {
  transition: all 0.15s ease-in-out; }
  #filtering.show:after, #cat-links.show:after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 250px;
    background-color: rgba(0, 0, 0, 0.42);
    border-top: 2px solid #bf232a;
    transition: all 0.15s ease-in-out; }
    @media screen and (min-width: 768px) {
      #filtering.show:after, #cat-links.show:after {
        left: 328px; } }

.post {
  color: #fff; }
  .post--fullSize {
    background: rgba(0, 0, 0, 0.32) url("assets/images/post-full.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center !important;
    padding: 1.375rem 0.8125rem 1.875rem 1.25rem;
    min-height: 23.75rem;
    border-top: 2px solid #bf232a;
    padding-top: 12.5rem;
    z-index: 2; }
    @media screen and (min-width: 768px) {
      .post--fullSize {
        padding-top: 0; } }
    .post--fullSize a {
      color: #bf232a; }
      .post--fullSize a:hover {
        text-decoration: unset; }
    @media screen and (min-width: 576px) {
      .post--fullSize .post__desc {
        max-width: 80%; } }
    @media screen and (min-width: 992px) {
      .post--fullSize .post__desc {
        max-width: 50%; } }
  @media screen and (min-width: 768px) {
    .post--long {
      min-height: 34.0625rem;
      padding-bottom: 8.75rem; } }
  .post--light {
    background: url("assets/images/post-full-light.png");
    background-size: cover;
    background-repeat: no-repeat; }
  .post--threeColumn {
    padding: 1.375rem 2.125rem 1.125rem 1.875rem;
    min-height: 23.75rem;
    margin-top: 2.5rem;
    position: relative;
    z-index: 1; }
    .post--threeColumn:hover {
      text-decoration: none;
      color: #fff; }
    .post--threeColumn::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-top: 2px solid #bf232a;
      background: rgba(0, 0, 0, 0.45);
      filter: blur(1px);
      -webkit-filter: blur(1px);
      z-index: -2; }
    .post--threeColumn::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity .25s ease-in-out; }
    .post--threeColumn:hover::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(191, 35, 42, 0.72);
      z-index: -1;
      opacity: 1; }
    .post--threeColumn:hover .post__action {
      color: #fff;
      text-decoration: unset; }
    .post--threeColumn:hover h6 span {
      color: #fff; }
    .post--threeColumn h6 {
      line-height: 1.19; }
  .post__action {
    color: #bf232a;
    font-weight: bold;
    transition: all .25s ease-in-out; }
    .post__action:hover {
      text-decoration: unset; }
  .post h1 {
    font-size: 2.125rem;
    font-weight: normal;
    line-height: normal;
    margin-bottom: 1.5rem; }
  .post h6 {
    font-size: 1.2rem;
    font-weight: normal;
    line-height: normal;
    margin-bottom: 1rem; }
    @media screen and (max-width: 1199px) {
      .post h6 br {
        display: none; } }
    .post h6:last-child {
      margin-bottom: 0; }
    .post h6 span {
      color: #bf232a;
      transition: all .25s ease-in-out; }
  .post p {
    font-size: 0.875rem;
    line-height: 1.29;
    margin-bottom: 0.5rem; }

nav.tabs-nav {
  border-top: 0; }
  nav.tabs-nav ul {
    border-bottom: 0; }
    @media screen and (min-width: 768px) {
      nav.tabs-nav ul li.nav-item:not(:last-child) {
        margin-right: 6.25rem; } }
    nav.tabs-nav ul li a {
      font-weight: bold;
      color: #353535; }
      nav.tabs-nav ul li a.nav-link {
        border: 0; }
        nav.tabs-nav ul li a.nav-link.active {
          border: 0;
          color: #bf232a;
          background-color: transparent;
          border-color: transparent; }
      nav.tabs-nav ul li a:hover {
        border: 0; }
  @media screen and (min-width: 768px) {
    nav.tabs-nav {
      margin-bottom: 1.5625rem; } }

.about-item {
  transition: all .25s ease-in-out; }
  .about-item:hover {
    text-decoration: unset;
    color: #353535; }
  .about-item[data-href]:hover {
    box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17);
    cursor: pointer; }

.about-item {
  min-height: 21.25rem;
  background-color: #fff;
  padding: 1.25rem 1.25rem 0.8125rem 1.25rem; }
  @media screen and (min-width: 768px) {
    .about-item {
      padding: 2rem 2rem; } }
  .about-item--left {
    border-left: 2px solid #bf232a; }
    .about-item--left .about-item__info {
      padding-right: 0.625rem; }
  .about-item--right {
    border-right: 2px solid #bf232a; }
    .about-item--right .about-item__info {
      padding-left: 0.625rem; }
  .about-item__info {
    max-width: 30rem;
    color: #353535; }
    .about-item__info h1 {
      font-size: 2.125rem;
      font-weight: normal;
      line-height: normal;
      margin-bottom: 1.5rem; }
    .about-item__info h6 {
      font-size: 1rem;
      font-weight: normal;
      line-height: normal;
      margin-bottom: 1rem; }
      .about-item__info h6:last-child {
        margin-bottom: 0; }
      .about-item__info h6 span {
        color: #bf232a;
        transition: all .25s ease-in-out; }
      .about-item__info h6 a {
        color: #bf232a; }
        .about-item__info h6 a:hover {
          text-decoration: unset; }
    .about-item__info a.about-item-action {
      padding-top: 1.5rem;
      color: #bf232a;
      font-weight: bold;
      transition: all .25s ease-in-out;
      display: inline-block; }
      .about-item__info a.about-item-action:hover {
        text-decoration: unset; }
  .about-item__img img {
    width: 100%;
    height: 12.5rem;
    object-fit: cover; }
    @media screen and (min-width: 768px) {
      .about-item__img img {
        min-height: 17.5rem;
        height: 100%;
        max-width: 26.5625rem; } }
    @media screen and (min-width: 1200px) {
      .about-item__img img {
        max-width: 47.5rem; } }

.news__story {
  border-left: 2px solid #bf232a;
  transition: all .25s ease-in-out;
  margin-bottom: 2rem;
  background-color: #fff;
  min-height: 8.125rem;
  color: #353535;
  padding: 2rem 1.375rem 2.3125rem 1rem; }
  @media screen and (min-width: 768px) {
    .news__story {
      padding: 0.9375rem 1.875rem 0.9375rem 0.9375rem; } }
  .news__story:hover {
    text-decoration: unset;
    color: #353535;
    box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17);
    cursor: pointer; }
  .news__story__thumbnail img {
    width: 6.25rem;
    height: 6.25rem;
    object-fit: cover; }
  .news__story__thumbnail__text {
    width: 100%; }
    .news__story__thumbnail__text h6 {
      font-size: 1rem;
      line-height: normal; }
    .news__story__thumbnail__text h2 {
      font-size: 2.125rem;
      line-height: normal;
      margin-bottom: 0; }
    @media screen and (min-width: 768px) {
      .news__story__thumbnail__text {
        margin-left: 1.875rem; } }
    @media screen and (min-width: 1200px) {
      .news__story__thumbnail__text {
        max-width: 25.9375rem; } }
  .news__story__text {
    width: 100%; }
    @media screen and (min-width: 1200px) {
      .news__story__text {
        padding-left: .8rem;
        max-width: 30.3125rem; } }
    .news__story__text p {
      font-size: 0.875rem;
      line-height: 1.29; }
      .news__story__text p:last-child {
        margin-bottom: 0; }
    .news__story__text a {
      color: #bf232a; }
      .news__story__text a:hover {
        text-decoration: unset; }

.content-text {
  font-size: 1rem;
  color: #444;
  line-height: 1.38;
  padding: 3.125rem 0; }
  .content-text h2 {
    font-size: 2.125rem;
    color: #353535;
    font-weight: normal;
    margin-bottom: 1.1rem; }
  .content-text ul {
    list-style-type: none;
    padding-left: 0; }
    .content-text ul li {
      color: #444444;
      line-height: 1.38; }
      .content-text ul li:before {
        content: "- "; }
  .content-text a {
    color: #bf232a; }
    .content-text a:hover {
      text-decoration: unset; }
  @media screen and (max-width: 767px) {
    .content-text br {
      display: none; } }

.custom-checkbox input[type="checkbox"]:checked ~ label::before {
  border-color: #bf232a;
  background-color: #bf232a; }

.custom-checkbox input[type="checkbox"]:focus ~ label::before {
  box-shadow: none; }

.custom-checkbox input[type="checkbox"]:not(:disabled):active ~ label::before {
  border-color: #fff;
  background-color: #fff; }

.custom-checkbox input[type="checkbox"]:focus:not(:checked) ~ label::before {
  border-color: #bf232a; }

.custom-checkbox input[type="checkbox"]:focus {
  background: #fff; }

.custom-checkbox label {
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 2.14;
  color: #3b3b3b;
  border-radius: 0; }
  .custom-checkbox label::before {
    border-radius: 0 !important;
    width: 0.9375rem;
    height: 0.9375rem;
    border: 1px solid #bf232a;
    border-radius: 0; }
  .custom-checkbox label:hover {
    cursor: pointer; }
    .custom-checkbox label:hover::before {
      background-color: #f1f1f1; }

.error-box {
  background-color: #fff8cf;
  color: #fe3636;
  font-size: 1rem;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  padding: 1rem 1rem;
  max-width: 17.5rem;
  display: none; }

.form-group--error label {
  color: #fe3636 !important; }

.form-group--error input {
  border: solid 1px #fe3636 !important; }

.form-group--error textarea {
  border: solid 1px #fe3636 !important; }

.form-group label.Error {
  color: #fe3636 !important; }

.EditingFormLabel.Error {
  color: #fe3636 !important; }

.Error input[type="text"], .Error input[type="number"], .Error input[type="email"], .Error input[type="password"], .Error input[type="date"] {
  border: solid 1px #fe3636 !important; }

.form-group--required label {
  position: relative; }
  .form-group--required label:after {
    content: '*';
    position: absolute;
    right: 6px;
    top: -3px;
    color: #fe3636; }
    @media screen and (max-width: 767px) {
      .form-group--required label:after {
        right: -10px; } }

span.InfoLabel {
  font-size: 55px; }

.success-reset-password {
  background-color: #fff8cf;
  color: #fe3636;
  font-size: 1rem;
  font-weight: 700;
  line-height: normal;
  letter-spacing: normal;
  padding: 1rem;
  max-width: 17.5rem;
  margin-top: 15px;
  margin-left: auto; }

.form-group--required .Error {
  color: #fe3636; }

.Error label {
  color: #fe3636 !important; }

label.Error {
  color: #fe3636 !important; }

.logon-password-retrieval-result {
  background-color: #fff8cf;
  color: #fe3636;
  font-size: 1rem;
  font-weight: 700;
  line-height: normal;
  letter-spacing: normal;
  padding: 1rem;
  max-width: 17.5rem;
  margin-top: 15px;
  display: block;
  margin-left: auto; }

@media screen and (max-width: 767px) {
  .login__form__wrapper--signup .form-group--required label:after {
    right: 6px; } }

.captcha-wrapper {
  max-width: 100%;
  width: 100%;
  height: auto; }
  @media screen and (min-width: 576px) {
    .captcha-wrapper {
      max-width: 19rem; }
      .captcha-wrapper * {
        width: 100%;
        max-width: 100%;
        height: auto; } }
  .captcha-wrapper * {
    width: 100%;
    max-width: 100%;
    height: auto; }

input[type="submit"] {
  font-weight: bold;
  font-size: 1rem;
  text-align: left;
  color: #fff;
  background-color: #bf232a;
  border: 0;
  border-radius: 0 !important;
  padding: 0.77rem 0.77rem;
  height: 3.375rem !important;
  background-image: url("assets/images/arrow.svg");
  background-repeat: no-repeat no-repeat;
  background-position: right 1.125rem center;
  width: 15rem; }
  input[type="submit"]:focus {
    outline: none;
    box-shadow: none; }
  @media screen and (min-width: 992px) {
    input[type="submit"] {
      width: 17.5rem;
      padding-left: 2rem; } }

@media screen and (min-width: 576px) {
  .searchBox input[type="text"].form-control {
    width: 100%; } }

.toasts {
  background-color: #fff8cf;
  color: #fe3636;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  padding: 1rem 1.5rem;
  max-width: 17.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 15px;
  right: 10px;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear; }
  .toasts.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear; }
  .toasts a {
    color: #fe3636;
    text-decoration: underline;
    transition: opacity 0.25s ease-in-out; }
    .toasts a:hover {
      opacity: 0.8; }

.alert-custom {
  font-size: 1.1rem;
  position: fixed;
  bottom: 15px;
  right: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 1.25rem 1.25rem;
  z-index: 99; }
  @media screen and (min-width: 768px) {
    .alert-custom {
      right: 10px; } }
  .alert-custom .close {
    position: absolute;
    padding: 0 0 0 1.5rem;
    top: 0;
    right: 7px; }
    .alert-custom .close:focus {
      outline: none;
      box-shadow: none; }
    @media screen and (min-width: 768px) {
      .alert-custom .close {
        position: relative; } }

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fbfbfb;
  height: 100vh;
  width: 100%;
  z-index: 9999;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 1; }
  @media screen and (max-width: 767px) {
    .preloader img {
      max-width: 25%; } }

.load-filter {
  position: relative; }
  .load-filter:after {
    content: '';
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7); }
  .load-filter:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    z-index: 10;
    background-image: url("assets/images/loading.gif");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50px 50px;
    width: 100%;
    height: 100%;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }

nav {
  border-top: 2px solid #bf232a; }
  @media screen and (min-width: 1200px) {
    nav .search-form.active {
      width: 94.6%; }
      nav .search-form.active .input-group {
        width: 100%; } }
  @media screen and (max-width: 1199px) {
    nav .search-form.active {
      width: 100%; }
      nav .search-form.active .input-group {
        width: 100%; } }
  nav.navbar.navbar-home {
    box-shadow: 0 2px 11px 0 rgba(191, 35, 42, 0.2);
    background-color: #ffffff; }
    nav.navbar.navbar-home.sticky {
      box-shadow: 0 2px 11px 0 rgba(191, 35, 42, 0.2); }
    @media (min-width: 992px) {
      nav.navbar.navbar-home .navbar-toggler {
        display: none !important; } }
    nav.navbar.navbar-home .row {
      width: 1440px; }
    @media screen and (max-width: 991px) {
      nav.navbar.navbar-home .search-form.active {
        width: 100%; }
        nav.navbar.navbar-home .search-form.active .input-group {
          width: 100%; } }
    @media screen and (min-width: 992px) {
      nav.navbar.navbar-home .search-form.active {
        width: 90%; }
        nav.navbar.navbar-home .search-form.active .input-group {
          width: 100%; } }
    @media screen and (min-width: 768px) {
      nav.navbar.navbar-home {
        box-shadow: unset;
        background-color: #fbfbfb; } }
    nav.navbar.navbar-home ul {
      margin-left: auto;
      margin-right: auto;
      width: unset; }
    @media screen and (min-width: 1200px) {
      nav.navbar.navbar-home .search-form {
        background: #fbfbfb; }
        nav.navbar.navbar-home .search-form.active {
          background-color: #fff; } }
    @media screen and (min-width: 992px) {
      nav.navbar.navbar-home .search-form {
        position: absolute;
        right: 3.125rem; } }
    @media screen and (min-width: 992px) {
      nav.navbar.navbar-home .collapse.navbar-collapse ul li.nav-item:not(:last-child) {
        margin-right: 3.5rem; } }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      nav.navbar.navbar-home .collapse.navbar-collapse ul li:not(:last-child) {
        margin-right: 2rem; }
      nav.navbar.navbar-home .collapse.navbar-collapse ul li.nav-item.dropdown > .dropdown-toggle:active {
        pointer-events: auto; }
      nav.navbar.navbar-home .collapse.navbar-collapse ul .dropdown .dropdown-menu {
        display: none; } }
    @media screen and (min-width: 992px) {
      nav.navbar.navbar-home .collapse.navbar-collapse {
        position: relative; }
        nav.navbar.navbar-home .collapse.navbar-collapse ul li .dropdown-toggle::after {
          display: inline-block; }
        nav.navbar.navbar-home .collapse.navbar-collapse ul li.dropdown .dropdown-menu {
          background-color: rgba(255, 255, 255, 0.95); }
          nav.navbar.navbar-home .collapse.navbar-collapse ul li.dropdown .dropdown-menu.show {
            display: block; } }
  nav.navbar {
    box-shadow: 0 2px 11px 0 rgba(191, 35, 42, 0.2);
    background-color: #ffffff;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1031;
    width: 100%; }
    @media screen and (min-width: 1200px) {
      nav.navbar {
        padding-top: 25px;
        padding-bottom: 35px; } }
    @media screen and (max-width: 1199px) {
      nav.navbar {
        height: 97.75px; } }
    nav.navbar.sticky {
      height: 95px;
      box-shadow: 0 2px 11px 0 rgba(191, 35, 42, 0.2); }
      nav.navbar.sticky .slogan {
        display: none !important; }
      nav.navbar.sticky .navbar-brand {
        max-width: 7rem; }
        @media screen and (min-width: 1200px) {
          nav.navbar.sticky .navbar-brand {
            max-width: 9.875rem; } }
    nav.navbar .row {
      width: 1440px; }
    nav.navbar .navbar-brand {
      max-width: 7.375rem;
      z-index: 1034; }
      @media screen and (min-width: 992px) {
        nav.navbar .navbar-brand {
          max-width: 12.5625rem;
          z-index: 11; } }
    nav.navbar .navbar-toggler {
      z-index: 1034; }
      nav.navbar .navbar-toggler:focus {
        outline: unset; }
      nav.navbar .navbar-toggler[aria-expanded="false"] svg.opened {
        display: none; }
      nav.navbar .navbar-toggler[aria-expanded="true"] svg.opened {
        display: inline; }
      nav.navbar .navbar-toggler[aria-expanded="true"] svg.closed {
        display: none; }
    nav.navbar .slogan h6 {
      font-size: 1.25rem;
      text-shadow: 0 0 15px rgba(255, 255, 255, 0.85);
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #353535;
      margin-left: 3rem;
      border-left: 2px solid #bf232a;
      padding-left: 6px;
      margin-bottom: 1rem; }
    nav.navbar .slogan span {
      color: #bf232a; }
    nav.navbar .collapse {
      z-index: 1033; }
    nav.navbar .collapsing {
      top: 2px;
      left: 0;
      position: fixed;
      z-index: 25;
      height: 100vh;
      border-radius: 3px;
      border: solid 1px #e5e5e5;
      background-color: #ffffff;
      width: 0;
      transition: all 0.15s ease-in-out; }
      @media screen and (min-width: 768px) {
        nav.navbar .collapsing {
          right: 0;
          left: unset; }
          nav.navbar .collapsing ul {
            margin-left: 0; } }
      nav.navbar .collapsing ul {
        margin-top: 4.375rem;
        margin-left: 1rem; }
    nav.navbar .collapse.show {
      display: flex;
      align-items: flex-start;
      top: 2px;
      left: 0;
      position: fixed;
      height: 100vh;
      border-radius: 3px;
      border: solid 1px #e5e5e5;
      background-color: #ffffff;
      z-index: 1033;
      width: 100%;
      transition: all 0.15s ease-in-out; }
      nav.navbar .collapse.show ul {
        height: 100vh;
        width: 100%;
        overflow-y: auto;
        padding-bottom: 7rem; }
        @supports (-webkit-overflow-scrolling: touch) {
          nav.navbar .collapse.show ul {
            padding-bottom: 10rem; } }
      @media screen and (min-width: 768px) {
        nav.navbar .collapse.show:after {
          content: '';
          position: fixed;
          width: 100%;
          right: 328px;
          height: 100vh;
          top: 0;
          background-color: rgba(0, 0, 0, 0.42);
          border-top: 2px solid #bf232a;
          transition: all 0.15s ease-in-out; } }
      @media screen and (min-width: 768px) {
        nav.navbar .collapse.show {
          right: 0;
          left: unset;
          width: 328px;
          justify-content: center; }
          nav.navbar .collapse.show ul {
            margin-left: 0rem; } }
      nav.navbar .collapse.show ul {
        margin-top: 5.375rem;
        margin-left: 1rem; }
    nav.navbar .collapse.navbar-collapse {
      position: fixed;
      z-index: 1033; }
      @media screen and (min-width: 1200px) {
        nav.navbar .collapse.navbar-collapse {
          position: relative; } }
    @media screen and (min-width: 1200px) {
      nav.navbar ul {
        margin-left: 3rem;
        justify-content: space-between;
        width: calc(100% - 30rem); } }
    nav.navbar ul li.nav-item.active a.nav-link {
      color: #bf232a; }
    nav.navbar ul li.nav-item:focus {
      outline: none;
      box-shadow: none; }
    nav.navbar ul li.nav-item.dropdown .dropdown-toggle:hover {
      cursor: pointer; }
    @media screen and (min-width: 1200px) {
      nav.navbar ul li.nav-item.dropdown:hover > .dropdown-menu {
        display: block; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      nav.navbar ul li.nav-item.dropdown .dropdown-toggle::after {
        display: none; } }
    nav.navbar ul li.nav-item.dropdown .dropdown-menu {
      border: 0;
      border-radius: 0;
      padding: 0.8125rem 0.75rem 0.9375rem 0.75rem;
      box-shadow: 0 5px 10px 0 rgba(245, 0, 11, 0.17);
      background-color: rgba(255, 255, 255, 0.95);
      overflow-y: auto;
      width: 100%;
      margin-top: 0;
      width: unset;
      box-shadow: none; }
      nav.navbar ul li.nav-item.dropdown .dropdown-menu a.dropdown-item {
        font-weight: bold;
        color: #353535;
        line-height: 1.19;
        white-space: normal;
        padding: 0;
        transition: all .3s ease-in-out; }
        nav.navbar ul li.nav-item.dropdown .dropdown-menu a.dropdown-item:not(:last-child) {
          margin-bottom: 1rem; }
        nav.navbar ul li.nav-item.dropdown .dropdown-menu a.dropdown-item:hover {
          background-color: transparent;
          color: #bf232a; }
      @media screen and (max-width: 767px) {
        nav.navbar ul li.nav-item.dropdown .dropdown-menu {
          box-shadow: unset;
          background-color: transparent; } }
      @media screen and (min-width: 768px) and (max-width: 1199px) {
        nav.navbar ul li.nav-item.dropdown .dropdown-menu {
          display: block; }
          nav.navbar ul li.nav-item.dropdown .dropdown-menu .dropdown-toggle::after {
            display: none; } }
      @media screen and (min-width: 1200px) {
        nav.navbar ul li.nav-item.dropdown .dropdown-menu {
          box-shadow: 0 5px 10px 0 rgba(245, 0, 11, 0.17); } }
      nav.navbar ul li.nav-item.dropdown .dropdown-menu a.dropdown-item {
        white-space: nowrap; }
        @media screen and (min-width: 1200px) {
          nav.navbar ul li.nav-item.dropdown .dropdown-menu a.dropdown-item {
            font-size: 1.2rem; } }
    nav.navbar ul li.nav-item:first-child a {
      padding-left: 0; }
    nav.navbar ul li.nav-item:last-child a {
      padding-right: 0; }
    @media screen and (min-width: 1200px) {
      nav.navbar ul li.nav-item:not(:last-child) {
        margin-right: 5rem; } }
    @media screen and (min-width: 1200px) {
      nav.navbar ul li.nav-item:nth-last-child(2) {
        margin-right: 4rem; } }
    nav.navbar ul li.nav-item a.nav-link {
      font-size: 1.09375rem;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      color: #353535;
      transition: all 0.3s ease-in-out;
      position: relative; }
      @media screen and (min-width: 1200px) {
        nav.navbar ul li.nav-item a.nav-link {
          padding: 0; }
          nav.navbar ul li.nav-item a.nav-link::after {
            position: absolute;
            bottom: 7px; }
          nav.navbar ul li.nav-item a.nav-link::before {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0px;
            left: 0;
            right: 0;
            margin: auto;
            background-color: #bf232a;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out; }
          nav.navbar ul li.nav-item a.nav-link:hover::before {
            transform: scaleX(1);
            transform-origin: bottom left; } }
      nav.navbar ul li.nav-item a.nav-link:focus {
        outline: none;
        box-shadow: none; }
      nav.navbar ul li.nav-item a.nav-link:hover {
        color: #bf232a; }
      nav.navbar ul li.nav-item a.nav-link.dropdown-toggle::after {
        vertical-align: 0.1em;
        color: #8d8d8d; }
      @media screen and (min-width: 1200px) {
        nav.navbar ul li.nav-item a.nav-link {
          font-size: 1.3rem; } }
      @media screen and (min-width: 1200px) {
        nav.navbar ul li.nav-item a.nav-link {
          padding-bottom: 0; } }
    nav.navbar .search-form, nav.navbar .search-form-for {
      background-color: #fff; }
      @media screen and (min-width: 1200px) {
        nav.navbar .search-form, nav.navbar .search-form-for {
          position: absolute;
          right: 3.125rem; } }
      nav.navbar .search-form.active .input-group, nav.navbar .search-form-for.active .input-group {
        padding-left: 7px;
        padding-right: 7px; }
        nav.navbar .search-form.active .input-group .input-group-prepend, nav.navbar .search-form-for.active .input-group .input-group-prepend {
          background-color: #fff; }
        nav.navbar .search-form.active .input-group svg path, nav.navbar .search-form-for.active .input-group svg path {
          stroke: #bf232a; }
        @media screen and (max-width: 1199px) {
          nav.navbar .search-form.active .input-group .input-group-prepend, nav.navbar .search-form-for.active .input-group .input-group-prepend {
            border-top: solid 1px #e7e7e7;
            border-bottom: solid 1px #e7e7e7;
            border-left: solid 1px #e7e7e7;
            padding: 0 0.625rem;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px; }
          nav.navbar .search-form.active .input-group input.form-control, nav.navbar .search-form-for.active .input-group input.form-control {
            border-top: solid 1px #e7e7e7;
            border-bottom: solid 1px #e7e7e7;
            border-right: solid 1px #e7e7e7;
            border-radius: 3px;
            width: 100%; }
          nav.navbar .search-form.active .input-group .input-group-append, nav.navbar .search-form-for.active .input-group .input-group-append {
            padding: 0 0.9375rem; } }
        @media screen and (min-width: 1200px) {
          nav.navbar .search-form.active .input-group, nav.navbar .search-form-for.active .input-group {
            border-radius: 3px;
            border: solid 1px #e7e7e7; }
            nav.navbar .search-form.active .input-group svg path, nav.navbar .search-form-for.active .input-group svg path {
              stroke: #A8A8A8; } }
      nav.navbar .search-form .input-group .input-group-prepend,
      nav.navbar .search-form .input-group .input-group-append, nav.navbar .search-form-for .input-group .input-group-prepend,
      nav.navbar .search-form-for .input-group .input-group-append {
        background-color: transparent; }
        nav.navbar .search-form .input-group .input-group-prepend .input-group-text,
        nav.navbar .search-form .input-group .input-group-append .input-group-text, nav.navbar .search-form-for .input-group .input-group-prepend .input-group-text,
        nav.navbar .search-form-for .input-group .input-group-append .input-group-text {
          background-color: transparent;
          border: 0;
          padding: 0;
          outline: unset; }
      nav.navbar .search-form .input-group input.form-control, nav.navbar .search-form-for .input-group input.form-control {
        border: 0; }
        nav.navbar .search-form .input-group input.form-control::placeholder, nav.navbar .search-form-for .input-group input.form-control::placeholder {
          font-weight: bold;
          line-height: normal;
          color: #a8a8a8; }
        nav.navbar .search-form .input-group input.form-control:focus, nav.navbar .search-form-for .input-group input.form-control:focus {
          border-color: #e7e7e7;
          box-shadow: unset; }
      nav.navbar .search-form .input-group .searchBox, nav.navbar .search-form-for .input-group .searchBox {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0; }
        nav.navbar .search-form .input-group .searchBox *, nav.navbar .search-form-for .input-group .searchBox * {
          display: none; }
    @media screen and (min-width: 1200px) {
      nav.navbar .account-item {
        position: absolute;
        right: 0;
        margin-left: auto;
        z-index: 5; } }

@media screen and (max-width: 767px) {
  nav.navbar .collapse ul {
    margin-right: 1rem; }
    nav.navbar .collapse ul li.nav-item a.nav-link {
      font-size: 1.4rem;
      border-bottom: 1px solid #dee2e6; }
    nav.navbar .collapse ul li.nav-item.dropdown .dropdown-menu a.dropdown-item {
      font-size: 1.25rem; } }

nav.mydigitron-nav {
  margin-top: 1.3125rem;
  padding: 1rem 0;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #bf232a; }
  @media screen and (min-width: 992px) {
    nav.mydigitron-nav ul {
      justify-content: space-between; } }
  nav.mydigitron-nav ul li {
    line-height: normal;
    display: flex;
    align-items: center; }
    nav.mydigitron-nav ul li.active a span {
      color: #bf232a; }
    @media screen and (max-width: 991px) {
      nav.mydigitron-nav ul li:not(:first-child) {
        margin-top: 1.25rem; } }
    nav.mydigitron-nav ul li:last-child {
      position: relative; }
      @media screen and (max-width: 991px) {
        nav.mydigitron-nav ul li:last-child:before {
          content: '';
          position: absolute;
          left: 0;
          top: -.7rem;
          height: 1px;
          width: 100%;
          background: #bf232a; } }
      @media screen and (min-width: 992px) {
        nav.mydigitron-nav ul li:last-child:before {
          content: '';
          position: absolute;
          left: 0;
          top: -1.0625rem;
          height: 4.75rem;
          width: 1px;
          background: #bf232a; } }
    nav.mydigitron-nav ul li a {
      font-weight: bold;
      color: #353535;
      line-height: normal;
      display: flex;
      align-items: center;
      transition: .25s ease-in-out; }
      nav.mydigitron-nav ul li a img {
        height: 1.3rem; }
      nav.mydigitron-nav ul li a svg {
        margin-right: 0.75rem; }
        @media screen and (min-width: 992px) {
          nav.mydigitron-nav ul li a svg {
            margin-right: 0; } }
        @media screen and (min-width: 1200px) {
          nav.mydigitron-nav ul li a svg {
            margin-right: 0.4375rem; } }
      nav.mydigitron-nav ul li a:hover {
        text-decoration: unset;
        color: #bf232a; }

footer {
  box-shadow: 0 -2px 11px 0 rgba(191, 35, 42, 0.2);
  background-color: #fbfbfb;
  border-top: 2px solid #bf232a;
  padding: 1rem 0 1rem 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  height: 245px; }
  @media screen and (min-width: 992px) {
    footer {
      height: 185px; } }
  @media screen and (min-width: 1200px) {
    footer {
      height: 125px; } }
  footer .footer__brand {
    z-index: 10;
    max-width: 7rem; }
    @media screen and (min-width: 1200px) {
      footer .footer__brand {
        max-width: 9.875rem; } }
  footer .footer--main-clr {
    z-index: 3; }
    footer .footer--main-clr * {
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      transition: all 0.25s ease-in-out;
      color: #353535; }
  footer a {
    transition: all 0.3s ease-in-out;
    z-index: 3; }
    footer a:hover {
      text-decoration: unset;
      color: #bf232a; }
  footer .phone {
    transition: all 0.3s ease-in-out; }
    footer .phone:hover {
      color: #bf232a;
      text-decoration: unset; }
  footer ul {
    z-index: 3; }
    footer ul li {
      z-index: 3; }
      footer ul li:not(:last-child) {
        margin-right: 0.92rem; }
        footer ul li:not(:last-child) a:after {
          content: "";
          position: absolute;
          height: 100%;
          width: 2px;
          top: 0;
          right: -0.55rem;
          background-color: #444444;
          background-color: #bf232a; }
      footer ul li a {
        font-weight: bold;
        color: #353535;
        line-height: normal;
        letter-spacing: normal;
        position: relative;
        z-index: 3; }
        footer ul li a:hover {
          color: #bf232a;
          text-decoration: unset; }
        footer ul li a::before {
          content: '';
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          bottom: -1px;
          left: 0;
          right: 0;
          margin: auto;
          background-color: #bf232a;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out; }
        footer ul li a:hover::before {
          transform: scaleX(1);
          transform-origin: bottom left; }
  footer small {
    color: #444444;
    font-size: 0.875rem;
    line-height: 2.14; }
  @media screen and (max-width: 991px) {
    footer .footer__menu {
      position: absolute; }
      footer .footer__menu ul {
        flex-direction: column;
        align-items: flex-end; }
        footer .footer__menu ul li {
          margin-right: 0; }
          footer .footer__menu ul li a:after {
            display: none; }
    footer small {
      letter-spacing: -.5px; } }

.company-desc {
  margin-top: 1rem;
  margin-bottom: 2rem; }
  .company-desc img {
    width: 100%;
    max-width: 19.0625rem; }
  .company-desc h1 {
    font-size: 1.75rem;
    font-weight: normal;
    color: #353535;
    line-height: normal;
    text-shadow: 0 0 15px rgba(255, 255, 255, 0.85); }
    .company-desc h1 span {
      color: #bf232a; }

.showcase {
  padding-bottom: 2.8125rem; }
  .showcase .text-info-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #bf232a;
    height: 100%;
    color: #fff;
    padding: 1.375rem 2.125rem 1.125rem 1.875rem; }
    .showcase .text-info-item h1 {
      font-size: 2.125rem;
      font-weight: normal;
      line-height: normal;
      margin-bottom: 1.5rem; }
    .showcase .text-info-item h6 {
      font-size: 1rem;
      font-weight: bold;
      line-height: normal;
      margin-bottom: 0; }
  .showcase .carousel .image-info-item {
    height: 100%; }
    .showcase .carousel .image-info-item img {
      height: 7.5rem;
      object-position: top;
      object-fit: cover; }
      @media screen and (min-width: 576px) {
        .showcase .carousel .image-info-item img {
          height: 12rem; } }
      @media screen and (min-width: 768px) {
        .showcase .carousel .image-info-item img {
          height: 15rem; } }
      @media screen and (min-width: 1200px) {
        .showcase .carousel .image-info-item img {
          object-position: top;
          height: 20rem; } }
  .showcase .carousel .carousel-indicators li {
    width: 6px;
    height: 6px;
    border: 1px solid #bf232a;
    background-color: transparent;
    opacity: 1; }
    .showcase .carousel .carousel-indicators li.active {
      background-color: #bf232a; }

.all-products {
  background-color: #f2f2f2;
  padding: 3rem 0; }
  .all-products h2 {
    color: #353535;
    font-size: 2.125rem; }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .all-products .product {
      width: 100% !important; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .all-products .product-col:nth-child(2n) .product {
      margin-left: auto; } }
  @media screen and (min-width: 1200px) {
    .all-products .product-col:nth-child(3n) .product {
      margin-left: auto; }
    .all-products .product-col:nth-child(3n+2) .product {
      margin-left: auto;
      margin-right: auto; } }
  .all-products .product {
    margin-top: 2.375rem;
    transition: opacity .25s ease-in-out; }
    @media screen and (min-width: 768px) {
      .all-products .product {
        width: 22.7rem; } }
    .all-products .product.show {
      box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17); }
    @media screen and (max-width: 991px) {
      .all-products .product {
        max-width: 100%; }
        .all-products .product button, .all-products .product a.dropdown-toggle {
          width: 100%; }
          .all-products .product button span, .all-products .product a.dropdown-toggle span {
            width: calc(100% - 80px); } }
    .all-products .product button, .all-products .product a.dropdown-toggle {
      font-size: 1.4rem;
      font-weight: normal;
      line-height: normal;
      background-color: #ffffff;
      color: #353535;
      border: 0;
      border-radius: 0;
      padding: 0 0;
      transition: opacity .25s ease-in-out;
      display: flex;
      align-items: center;
      white-space: normal;
      text-align: left; }
      @media screen and (min-width: 992px) {
        .all-products .product button, .all-products .product a.dropdown-toggle {
          font-size: 1.75rem; } }
      .all-products .product button:hover, .all-products .product a.dropdown-toggle:hover {
        cursor: default; }
      .all-products .product button::after, .all-products .product a.dropdown-toggle::after {
        display: none; }
      .all-products .product button:focus, .all-products .product a.dropdown-toggle:focus {
        outline: unset;
        box-shadow: unset; }
      .all-products .product button span, .all-products .product button .link-to-products, .all-products .product a.dropdown-toggle span, .all-products .product a.dropdown-toggle .link-to-products {
        padding: 0.375rem 0.875rem; }
        @media screen and (min-width: 768px) {
          .all-products .product button span, .all-products .product button .link-to-products, .all-products .product a.dropdown-toggle span, .all-products .product a.dropdown-toggle .link-to-products {
            width: 17.75rem; } }
      .all-products .product button[aria-expanded="true"] svg, .all-products .product a.dropdown-toggle[aria-expanded="true"] svg {
        transform: rotate(180deg); }
        .all-products .product button[aria-expanded="true"] svg path, .all-products .product a.dropdown-toggle[aria-expanded="true"] svg path {
          stroke: #fff; }
      .all-products .product button[aria-expanded="true"] .btn__icon, .all-products .product a.dropdown-toggle[aria-expanded="true"] .btn__icon {
        background-color: #bf232a; }
      .all-products .product button .btn__icon, .all-products .product a.dropdown-toggle .btn__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5rem;
        width: 5rem; }
        .all-products .product button .btn__icon:hover, .all-products .product a.dropdown-toggle .btn__icon:hover {
          background-color: #bf232a;
          cursor: pointer; }
          button .all-products .product button .btn__icon:hover, button .all-products .product a.dropdown-toggle .btn__icon:hover {
            box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17); }
          .all-products .product button .btn__icon:hover svg path, .all-products .product a.dropdown-toggle .btn__icon:hover svg path {
            stroke: #fff; }
    .all-products .product .dropdown-menu {
      border: 0;
      border-radius: 0;
      padding: 0.8125rem 0.75rem 0.9375rem 0.75rem;
      box-shadow: 0 5px 10px 0 rgba(245, 0, 11, 0.17);
      background-color: rgba(255, 255, 255, 0.95);
      overflow-y: auto;
      width: 100%;
      margin-top: 0;
      bottom: 0;
      width: 100%;
      margin-top: 1px !important;
      height: 13.125rem;
      padding: 0.8125rem 0.875rem 0.9375rem 0.875rem;
      overflow-x: hidden; }
      .all-products .product .dropdown-menu .simplebar-track.simplebar-vertical {
        background-color: #dedddd;
        width: 4px; }
        .all-products .product .dropdown-menu .simplebar-track.simplebar-vertical .simplebar-scrollbar {
          background-color: #bf232a;
          width: 4px;
          right: 0; }
          .all-products .product .dropdown-menu .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            background-color: transparent; }
      .all-products .product .dropdown-menu a.dropdown-item {
        font-weight: bold;
        color: #353535;
        line-height: 1.19;
        white-space: normal;
        padding: 0;
        transition: all .3s ease-in-out; }
        .all-products .product .dropdown-menu a.dropdown-item:not(:last-child) {
          margin-bottom: 1rem; }
        .all-products .product .dropdown-menu a.dropdown-item:hover {
          background-color: transparent;
          color: #bf232a; }
      @media screen and (min-width: 768px) {
        .all-products .product .dropdown-menu {
          width: 22.75rem; } }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      .all-products .product {
        max-width: 28rem !important;
        width: unset !important; } }
    .all-products .product a.link-to-products {
      color: #353535;
      text-decoration: unset;
      transition: color 0.3s ease-in-out;
      padding: 0.375rem 0.875rem;
      z-index: 10; }
      .all-products .product a.link-to-products:hover {
        color: #bf232a;
        text-decoration: unset; }
    .all-products .product .dropdown-menu {
      background-color: white; }
    .all-products .product.btn-group.dropdown .link-to-product-page {
      font-size: 1.4rem;
      font-weight: normal;
      line-height: normal;
      background-color: #ffffff;
      color: #353535;
      border: 0;
      width: 100%;
      border-radius: 0;
      padding: 0 0;
      transition: all .25s ease-in-out;
      display: flex;
      align-items: center;
      white-space: normal;
      text-align: left;
      text-decoration: unset;
      background-color: #fff;
      padding: 0.375rem 0.875rem; }
      .all-products .product.btn-group.dropdown .link-to-product-page:hover {
        color: #bf232a; }
      @media screen and (min-width: 992px) {
        .all-products .product.btn-group.dropdown .link-to-product-page {
          font-size: 1.75rem; } }
    .all-products .product.btn-group.dropdown .dropdown-toggle-split {
      height: 5rem;
      width: 6.5625rem;
      transition: all .25s ease-in-out;
      display: flex;
      justify-content: center; }
      .all-products .product.btn-group.dropdown .dropdown-toggle-split[aria-expanded="true"] {
        background-color: #bf232a; }
      .all-products .product.btn-group.dropdown .dropdown-toggle-split:hover {
        background-color: #bf232a;
        cursor: pointer; }
        .all-products .product.btn-group.dropdown .dropdown-toggle-split:hover svg path {
          stroke: #fff; }

.posts {
  padding-top: 6.875rem; }

.news {
  margin-top: 5rem; }
  .news__read-more {
    color: #bf232a;
    border: solid 1px #bf232a;
    background-color: #ffffff;
    font-weight: bold;
    width: 100%;
    padding: 1rem 1rem 1rem 1.375rem;
    transition: all .25s ease-in-out; }
    .news__read-more span {
      padding-right: 1rem; }
      @media screen and (min-width: 768px) {
        .news__read-more span {
          padding-right: 3.125rem; } }
    .news__read-more:hover {
      background-color: #bf232a;
      color: #fff;
      text-decoration: unset; }
      .news__read-more:hover svg path {
        stroke: #fff; }

.product-info__title h1 {
  font-size: 1.5rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #5a5a5a; }
  @media screen and (min-width: 768px) {
    .product-info__title h1 {
      font-size: 2.125rem; } }

.product-info__img {
  border-radius: 0.1875rem;
  border: solid 0.0625rem #e5e5e5;
  background-color: #ffffff;
  padding: 0.625rem 0.75rem; }
  @media screen and (min-width: 768px) {
    .product-info__img {
      min-height: 17.5rem; } }

@media screen and (min-width: 768px) {
  .product-info__actions {
    min-height: 17.5rem; } }

.product-info__actions a {
  width: 100%; }
  .product-info__actions a.item-saved img {
    width: 25px;
    height: auto; }
  .product-info__actions a.btn svg {
    margin-right: 1rem; }
  .product-info__actions a svg {
    pointer-events: none; }
  @media screen and (max-width: 767px) {
    .product-info__actions a:last-child {
      width: 45%; } }

.product-info__actions__group {
  border-radius: 0.1875rem;
  border: solid 0.0625rem #e5e5e5;
  background-color: #ffffff;
  padding: 0.625rem 0.75rem; }
  .product-info__actions__group h6 {
    color: #353535;
    line-height: normal;
    letter-spacing: normal;
    font-weight: bold;
    margin-bottom: 0.625rem; }
  .product-info__actions__group ul li:not(:last-child) {
    margin-bottom: 0.5625rem; }
  .product-info__actions__group ul li a {
    font-size: 0.875rem;
    color: #bf232a; }
    .product-info__actions__group ul li a svg {
      margin-right: 0.6875rem; }
    .product-info__actions__group ul li a:hover {
      text-decoration: none; }

.product-info__desc {
  height: 100%;
  border-radius: 0.1875rem;
  border: solid 0.0625rem #e5e5e5;
  background-color: #ffffff;
  padding: 0.625rem 0.75rem;
  padding-right: 2rem; }
  .product-info__desc h6 {
    color: #353535;
    font-weight: bold;
    line-height: normal; }
    .product-info__desc h6:not(:first-child) {
      margin-top: 1; }
  .product-info__desc article {
    font-size: 0.875rem;
    line-height: 1.29;
    font-weight: normal;
    letter-spacing: normal;
    position: relative; }
    .product-info__desc article:not(:last-child) {
      margin-bottom: 3.125rem; }
      .product-info__desc article:not(:last-child):after {
        content: '';
        position: absolute;
        bottom: -2.125rem;
        left: 0;
        right: 0;
        width: 98%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        height: 1px;
        background-color: #e6e6e6; }

.product-stock {
  margin: 1.5625rem 0 7.1875rem 0; }
  .product-stock nav ul {
    border-bottom: 0; }
    @media screen and (min-width: 768px) {
      .product-stock nav ul li.nav-item:not(:last-child) {
        margin-right: 6.25rem; } }
    .product-stock nav ul li a {
      font-weight: bold;
      color: #353535; }
      .product-stock nav ul li a.nav-link {
        border: 0; }
        .product-stock nav ul li a.nav-link.active {
          border: 0;
          color: #bf232a;
          background-color: transparent;
          border-color: transparent; }
      .product-stock nav ul li a:hover {
        border: 0; }
  @media screen and (min-width: 768px) {
    .product-stock nav {
      margin-bottom: 1.5625rem; } }
  .product-stock .tab-pane .package-card {
    border-radius: 0.1875rem;
    border: solid 0.0625rem #e5e5e5;
    background-color: #ffffff;
    padding: 0.625rem 0.75rem; }
    @media screen and (min-width: 768px) {
      .product-stock .tab-pane .package-card {
        min-height: 40.9375rem; } }

.product-info__actions__group ul li .svd-item:hover {
  text-decoration: underline; }

.other-categories {
  margin-top: 1rem; }
  .other-categories__shortName {
    display: none; }
  @media screen and (max-width: 1199px) {
    .other-categories.sticky {
      position: fixed;
      top: 95px;
      z-index: 1030;
      width: 100%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      height: 66px;
      margin-top: 0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      background-color: #eaeaea; } }
  .other-categories.sticky button {
    background-color: transparent;
    border: 0; }
    .other-categories.sticky button.filtering-btn {
      background-color: transparent;
      border: 0; }
  .other-categories.sticky .cat-title {
    display: none; }
  .other-categories.sticky .other-categories__shortName {
    display: inline; }
  .other-categories.sticky .other-categories__fullName {
    display: none; }
  .other-categories h1 {
    font-size: 2.125rem;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5a5a5a; }
  .other-categories button {
    line-height: normal;
    border-radius: 0;
    border: solid 0.0625rem #e5e5e5;
    background-color: #ffffff;
    padding: 0.8125rem 0.75rem 0.9375rem 0.75rem; }
    .other-categories button:focus {
      box-shadow: unset; }
    .other-categories button span {
      font-weight: bold;
      color: #bf232a; }
  .other-categories .dropdown-menu {
    border: 0;
    border-radius: 0;
    padding: 0.8125rem 0.75rem 0.9375rem 0.75rem;
    box-shadow: 0 5px 10px 0 rgba(245, 0, 11, 0.17);
    background-color: rgba(255, 255, 255, 0.95);
    overflow-y: auto;
    width: 100%;
    margin-top: 0;
    height: 24.800rem;
    z-index: 1100; }
    .other-categories .dropdown-menu .simplebar-track.simplebar-vertical {
      background-color: #dedddd;
      width: 4px; }
      .other-categories .dropdown-menu .simplebar-track.simplebar-vertical .simplebar-scrollbar {
        background-color: #bf232a;
        width: 4px;
        right: 0; }
        .other-categories .dropdown-menu .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
          background-color: transparent; }
    .other-categories .dropdown-menu a.dropdown-item {
      font-weight: bold;
      color: #353535;
      line-height: 1.19;
      white-space: normal;
      padding: 0;
      transition: all .3s ease-in-out; }
      .other-categories .dropdown-menu a.dropdown-item:not(:last-child) {
        margin-bottom: 1rem; }
      .other-categories .dropdown-menu a.dropdown-item:hover {
        background-color: transparent;
        color: #bf232a; }
    .other-categories .dropdown-menu.show {
      position: absolute; }

.filters .clear-filters {
  font-size: 0.9rem;
  font-weight: 600;
  background-color: #bf232a;
  color: #fff;
  position: relative;
  padding: 0.5rem 1rem;
  border: 1px solid #bf232a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  z-index: 900; }
  @media screen and (max-width: 1199px) {
    .filters .clear-filters {
      position: absolute;
      width: 100%;
      left: 0;
      top: -1px;
      height: 52px; } }
  .filters .clear-filters svg {
    margin-left: 13px;
    pointer-events: none; }
    .filters .clear-filters svg polygon {
      color: #bf232a !important; }
  .filters .clear-filters:hover {
    background-color: transparent;
    box-shadow: unset;
    color: #bf232a; }
    .filters .clear-filters:hover svg polygon {
      fill: #bf232a !important; }
  .filters .clear-filters:focus {
    background-color: transparent;
    box-shadow: unset;
    color: #bf232a; }
    .filters .clear-filters:focus svg polygon {
      fill: #bf232a !important; }

.filters .close-sidenav {
  position: absolute;
  right: -2.5rem;
  top: 0.3125rem;
  z-index: 11; }

.filters.show .filters__form {
  overflow: auto;
  height: 100vh; }

.filters__form {
  border-radius: 0.1875rem;
  border: solid 0.0625rem #e5e5e5;
  background-color: #ffffff;
  font-weight: bold;
  color: #bf232a; }
  @media screen and (max-width: 1199px) {
    .filters__form .clear-btn-exist {
      margin-top: 52px; } }
  .filters__form__item {
    padding: 0.8125rem 0.75rem 0.9375rem 0.75rem;
    position: relative; }
    .filters__form__item .collapse {
      max-height: 20rem;
      overflow-y: auto; }
      .filters__form__item .collapse .simplebar-track.simplebar-vertical {
        background-color: #dedddd;
        width: 4px; }
        .filters__form__item .collapse .simplebar-track.simplebar-vertical .simplebar-scrollbar {
          background-color: #bf232a;
          width: 4px;
          right: 0; }
          .filters__form__item .collapse .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            background-color: transparent; }
    .filters__form__item:not(:first-child):before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      z-index: 2;
      background-color: #e5e5e5; }
    .filters__form__item a {
      color: #353535;
      font-weight: bold;
      line-height: normal;
      padding-right: 3.6rem;
      display: inline-block;
      position: relative; }
      .filters__form__item a:hover {
        text-decoration: none; }
      .filters__form__item a[aria-expanded="true"]::after {
        border-top: 0;
        border-left: 0.4em solid transparent;
        border-right: 0.4em solid transparent;
        border-bottom: 0.4em solid; }
      .filters__form__item a::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        border-top: 0.4em solid;
        border-right: 0.4em solid transparent;
        border-bottom: 0;
        border-left: 0.4em solid transparent;
        color: #bf232a; }
    .filters__form__item .card.card-body {
      border: 0;
      border-radius: 0;
      padding: 0 0;
      margin-top: 1.125rem;
      margin-bottom: 0.5rem; }
      .filters__form__item .card.card-body .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #bf232a;
        background-color: #bf232a; }
      .filters__form__item .card.card-body .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: none; }
      .filters__form__item .card.card-body .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
        border-color: #bf232a;
        background-color: #bf232a; }
      .filters__form__item .card.card-body label:hover {
        cursor: pointer; }
        .filters__form__item .card.card-body label:hover::before {
          background-color: #f1f1f1; }
      .filters__form__item .card.card-body label.custom-control-label {
        font-size: 0.875rem;
        font-weight: normal;
        line-height: 2.14;
        color: #3b3b3b; }
        .filters__form__item .card.card-body label.custom-control-label::before {
          border-radius: 0;
          width: 0.9375rem;
          height: 0.9375rem;
          border: 1px solid #bf232a; }

@media screen and (max-width: 1199px) {
  .filters {
    padding: 0; }
    .filters__form {
      border: 0; }
      .filters__form__item a {
        padding-right: 8.6rem; } }
      @media screen and (max-width: 1199px) and (max-width: 768px) {
        .filters__form__item a {
          padding-right: 5rem; } }

@media screen and (max-width: 1199px) {
    .filters.collapsing {
      top: 2px;
      left: 0;
      position: fixed;
      z-index: 1033;
      height: 100vh;
      border-radius: 3px;
      border: solid 1px #e5e5e5;
      background-color: #ffffff;
      width: 0;
      transition: all 0.15s ease-in-out; }
      .filters.collapsing ul {
        margin-top: 4.375rem; }
    .filters.collapse.show {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      top: 2px;
      left: 0;
      position: fixed;
      height: 100vh;
      border-radius: 3px;
      border: solid 1px #e5e5e5;
      background-color: #ffffff;
      z-index: 1033;
      width: 250px;
      transition: all 0.15s ease-in-out; } }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      .filters.collapse.show {
        width: 328px; } }

.info-link-details {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  text-indent: -9999px;
  overflow: hidden;
  background: url(http://digitron-dev.bitsorchestra.com/Digitron/media/Digitron/info-icon.svg) no-repeat; }

.scrollingcontent .scrollingcontentblock .PagerControl {
  display: none; }

.seacrh-results h1 {
  font-size: 2.125rem;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5a5a5a;
  margin-bottom: 2.8125rem; }

.seacrh-results .filtering-btn {
  padding: 0.8125rem 0.75rem 0.9375rem 0.75rem; }

.seacrh-results .search-form-for {
  margin-bottom: 2.0625rem; }
  .seacrh-results .search-form-for label {
    font-size: 0.875rem;
    color: #3b3b3b;
    line-height: normal;
    padding-right: 0.875rem; }
  .seacrh-results .search-form-for .input-group {
    width: auto;
    border-radius: 3px;
    border: solid 1px #e7e7e7; }
    .seacrh-results .search-form-for .input-group .input-group-prepend,
    .seacrh-results .search-form-for .input-group .input-group-append {
      background-color: #fbfbfb; }
      .seacrh-results .search-form-for .input-group .input-group-prepend .input-group-text,
      .seacrh-results .search-form-for .input-group .input-group-append .input-group-text {
        background-color: #fbfbfb;
        border: 0;
        outline: unset;
        padding: 0.375rem 0.375rem; }
    .seacrh-results .search-form-for .input-group input.form-control {
      width: 13rem;
      border: 0;
      font-weight: bold;
      color: #353535;
      line-height: normal;
      padding-left: 0;
      background-color: #fbfbfb; }
      @media screen and (min-width: 768px) {
        .seacrh-results .search-form-for .input-group input.form-control {
          width: 17.25rem; } }
      .seacrh-results .search-form-for .input-group input.form-control::placeholder {
        font-weight: bold;
        line-height: normal;
        color: #353535; }
      .seacrh-results .search-form-for .input-group input.form-control:focus {
        border-color: #e7e7e7;
        box-shadow: unset; }
    .seacrh-results .search-form-for .input-group .search-dialog .form-horizontal > .form-group {
      margin: 0; }
    .seacrh-results .search-form-for .input-group .search-dialog .form-group-submit, .seacrh-results .search-form-for .input-group .search-dialog .editing-form-label-cell {
      display: none; }
  .seacrh-results .search-form-for .search-form-for .dropdown {
    margin-top: 2.375rem;
    transition: opacity .25s ease-in-out;
    border: solid 1px #e7e7e7;
    border-radius: 3px;
    margin-top: 0; }
    @media screen and (min-width: 768px) {
      .seacrh-results .search-form-for .search-form-for .dropdown {
        width: 22.7rem; } }
    .seacrh-results .search-form-for .search-form-for .dropdown.show {
      box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17); }
    @media screen and (max-width: 991px) {
      .seacrh-results .search-form-for .search-form-for .dropdown {
        max-width: 100%; }
        .seacrh-results .search-form-for .search-form-for .dropdown button, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle {
          width: 100%; }
          .seacrh-results .search-form-for .search-form-for .dropdown button span, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle span {
            width: calc(100% - 80px); } }
    .seacrh-results .search-form-for .search-form-for .dropdown button, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle {
      font-size: 1.4rem;
      font-weight: normal;
      line-height: normal;
      background-color: #ffffff;
      color: #353535;
      border: 0;
      border-radius: 0;
      padding: 0 0;
      transition: opacity .25s ease-in-out;
      display: flex;
      align-items: center;
      white-space: normal;
      text-align: left; }
      @media screen and (min-width: 992px) {
        .seacrh-results .search-form-for .search-form-for .dropdown button, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle {
          font-size: 1.75rem; } }
      .seacrh-results .search-form-for .search-form-for .dropdown button:hover, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle:hover {
        cursor: default; }
      .seacrh-results .search-form-for .search-form-for .dropdown button::after, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle::after {
        display: none; }
      .seacrh-results .search-form-for .search-form-for .dropdown button:focus, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle:focus {
        outline: unset;
        box-shadow: unset; }
      .seacrh-results .search-form-for .search-form-for .dropdown button span, .seacrh-results .search-form-for .search-form-for .dropdown button .link-to-products, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle span, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle .link-to-products {
        padding: 0.375rem 0.875rem; }
        @media screen and (min-width: 768px) {
          .seacrh-results .search-form-for .search-form-for .dropdown button span, .seacrh-results .search-form-for .search-form-for .dropdown button .link-to-products, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle span, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle .link-to-products {
            width: 17.75rem; } }
      .seacrh-results .search-form-for .search-form-for .dropdown button[aria-expanded="true"] svg, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle[aria-expanded="true"] svg {
        transform: rotate(180deg); }
        .seacrh-results .search-form-for .search-form-for .dropdown button[aria-expanded="true"] svg path, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle[aria-expanded="true"] svg path {
          stroke: #fff; }
      .seacrh-results .search-form-for .search-form-for .dropdown button[aria-expanded="true"] .btn__icon, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle[aria-expanded="true"] .btn__icon {
        background-color: #bf232a; }
      .seacrh-results .search-form-for .search-form-for .dropdown button .btn__icon, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle .btn__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5rem;
        width: 5rem; }
        .seacrh-results .search-form-for .search-form-for .dropdown button .btn__icon:hover, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle .btn__icon:hover {
          background-color: #bf232a;
          cursor: pointer; }
          button .seacrh-results .search-form-for .search-form-for .dropdown button .btn__icon:hover, button .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle .btn__icon:hover {
            box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17); }
          .seacrh-results .search-form-for .search-form-for .dropdown button .btn__icon:hover svg path, .seacrh-results .search-form-for .search-form-for .dropdown a.dropdown-toggle .btn__icon:hover svg path {
            stroke: #fff; }
    .seacrh-results .search-form-for .search-form-for .dropdown .dropdown-menu {
      border: 0;
      border-radius: 0;
      padding: 0.8125rem 0.75rem 0.9375rem 0.75rem;
      box-shadow: 0 5px 10px 0 rgba(245, 0, 11, 0.17);
      background-color: rgba(255, 255, 255, 0.95);
      overflow-y: auto;
      width: 100%;
      margin-top: 0;
      bottom: 0;
      width: 100%;
      margin-top: 1px !important;
      height: 13.125rem;
      padding: 0.8125rem 0.875rem 0.9375rem 0.875rem;
      overflow-x: hidden; }
      .seacrh-results .search-form-for .search-form-for .dropdown .dropdown-menu .simplebar-track.simplebar-vertical {
        background-color: #dedddd;
        width: 4px; }
        .seacrh-results .search-form-for .search-form-for .dropdown .dropdown-menu .simplebar-track.simplebar-vertical .simplebar-scrollbar {
          background-color: #bf232a;
          width: 4px;
          right: 0; }
          .seacrh-results .search-form-for .search-form-for .dropdown .dropdown-menu .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            background-color: transparent; }
      .seacrh-results .search-form-for .search-form-for .dropdown .dropdown-menu a.dropdown-item {
        font-weight: bold;
        color: #353535;
        line-height: 1.19;
        white-space: normal;
        padding: 0;
        transition: all .3s ease-in-out; }
        .seacrh-results .search-form-for .search-form-for .dropdown .dropdown-menu a.dropdown-item:not(:last-child) {
          margin-bottom: 1rem; }
        .seacrh-results .search-form-for .search-form-for .dropdown .dropdown-menu a.dropdown-item:hover {
          background-color: transparent;
          color: #bf232a; }
      @media screen and (min-width: 768px) {
        .seacrh-results .search-form-for .search-form-for .dropdown .dropdown-menu {
          width: 22.75rem; } }
    @media screen and (max-width: 767px) {
      .seacrh-results .search-form-for .search-form-for .dropdown {
        width: 15.3125rem; } }
    @media screen and (min-width: 768px) {
      .seacrh-results .search-form-for .search-form-for .dropdown {
        margin-top: 0;
        width: 19.5rem; } }
    .seacrh-results .search-form-for .search-form-for .dropdown button {
      font-size: 1rem;
      font-weight: bold;
      color: #353535;
      padding: 0.53125rem 0.53125rem;
      width: 100%;
      background-color: #fbfbfb;
      position: relative; }
      @media screen and (min-width: 992px) {
        .seacrh-results .search-form-for .search-form-for .dropdown button {
          font-size: 1rem; } }
      .seacrh-results .search-form-for .search-form-for .dropdown button[aria-expanded="true"]::after {
        transform: rotate(180deg);
        right: 15px; }
      .seacrh-results .search-form-for .search-form-for .dropdown button::after {
        content: "";
        position: absolute;
        display: block;
        right: 10px;
        top: 50%;
        transform: translate(-50%);
        color: #8d8d8d;
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent; }
    .seacrh-results .search-form-for .search-form-for .dropdown .dropdown-menu {
      width: 19.375rem; }
      @media screen and (max-width: 767px) {
        .seacrh-results .search-form-for .search-form-for .dropdown .dropdown-menu {
          width: 15.3125rem; } }
  .seacrh-results .search-form-for .search-form-for select {
    width: 15.3125rem;
    height: 2.5rem;
    font-weight: bold;
    color: #353535;
    padding: 0.375rem 0.375rem;
    border-radius: 3px;
    border: solid 1px #e7e7e7;
    background-color: #fbfbfb;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position: 13.5rem center; }
    @media screen and (min-width: 768px) {
      .seacrh-results .search-form-for .search-form-for select {
        width: 19.5625rem;
        background-position: 17.8125rem center; } }
    .seacrh-results .search-form-for .search-form-for select:focus {
      outline: none; }
    .seacrh-results .search-form-for .search-form-for select option {
      font-weight: bold;
      color: #353535;
      background-color: #fbfbfb; }

.seacrh-results .search-filters {
  border-radius: 0.1875rem;
  border: solid 0.0625rem #e5e5e5;
  background-color: #ffffff;
  padding: 0.625rem 0.75rem; }
  @media screen and (max-width: 767px) {
    .seacrh-results .search-filters {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem; }
      .seacrh-results .search-filters .custom-control {
        display: flex;
        align-items: center; }
        .seacrh-results .search-filters .custom-control:not(:first-child) {
          margin-left: 1rem; } }
  @media screen and (min-width: 992px) {
    .seacrh-results .search-filters {
      max-width: 10rem; }
      .seacrh-results .search-filters tr:not(:first-child) label {
        margin-top: 1.75rem; }
      .seacrh-results .search-filters tr input[type=checkbox] {
        display: none; } }
  .seacrh-results .search-filters .custom-control {
    padding-left: 0; }
    .seacrh-results .search-filters .custom-control input {
      display: none; }
    @media screen and (min-width: 992px) {
      .seacrh-results .search-filters .custom-control:not(:first-child) {
        margin-top: 1.75rem; } }
  .seacrh-results .search-filters label {
    font-size: 1rem;
    font-weight: bold;
    color: #353535;
    line-height: normal; }
    .seacrh-results .search-filters label:hover {
      cursor: pointer; }
    .seacrh-results .search-filters label:before {
      display: none; }
    .seacrh-results .search-filters label:after {
      display: none; }
    .seacrh-results .search-filters label.active {
      color: #bf232a; }

.seacrh-results .single-result:not(:first-child) {
  margin-top: 3.75rem; }

.seacrh-results .single-result__title a {
  font-size: 1rem;
  color: #bf232a;
  font-weight: bold;
  line-height: normal; }
  .seacrh-results .single-result__title a:hover {
    color: #bf232a;
    text-decoration: unset; }

.seacrh-results .single-result__text p {
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.29;
  color: #353535; }
  .seacrh-results .single-result__text p span {
    background-color: #ffec00; }

@media screen and (max-width: 767px) {
  .seacrh-results .single-result__info {
    justify-content: space-between;
    flex-wrap: wrap; }
    .seacrh-results .single-result__info-progress {
      flex: 0 43%;
      order: 1; }
    .seacrh-results .single-result__info-url {
      flex: 0 100%;
      order: 3;
      margin-top: 1rem; }
      .seacrh-results .single-result__info-url * {
        font-size: 0.8rem; }
    .seacrh-results .single-result__info-date {
      display: none; } }

.seacrh-results .single-result__info-progress {
  margin-right: 0.625rem; }
  .seacrh-results .single-result__info-progress .progress {
    width: 3.625rem;
    border-radius: 0;
    background-color: #f0f0f0;
    border: 1px solid #bf232a; }
    .seacrh-results .single-result__info-progress .progress-bar {
      background-color: #bf232a; }

.seacrh-results .single-result__info-url {
  margin-right: 1.125rem; }
  .seacrh-results .single-result__info-url a {
    font-weight: bold;
    line-height: normal;
    color: #bf232a; }
    .seacrh-results .single-result__info-url a:hover {
      text-decoration: unset; }

.seacrh-results .single-result__info-date p {
  font-weight: bold;
  line-height: normal;
  color: #a8a8a8; }

.seacrh-results .search-form + .single-result {
  margin-top: 0; }

#p_lt_ctl01_pageplaceholder_p_lt_ctl01_SmartSearchDialog_srchDialog_lblSearchFor {
  display: none; }

.input-group-prepend {
  background-color: rgba(0, 0, 0, 0) !important;
  display: block; }

nav.navbar .search-form-for .input-group .searchBox *, nav.navbar .search-form .input-group .searchBox .predictiveSearchHolder {
  display: block !important; }

.predictiveSearchResults {
  left: -30px;
  width: 233px !important; }

.predictiveSearchResults > * {
  color: #bf232a !important;
  padding: 3px 4px; }

.predictiveSearchResults a:hover {
  color: #bf232a !important; }

.terms {
  margin-top: 1.4375rem; }
  .terms a {
    color: #bf232a; }
    .terms a:hover {
      text-decoration: unset; }
  .terms__list {
    list-style: none;
    color: #444;
    padding-left: 1.875rem;
    counter-reset: my-awesome-counter; }
    .terms__list h6 {
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.38; }
    .terms__list p {
      font-weight: normal;
      margin-bottom: 1.2rem; }
      .terms__list p b {
        font-weight: bold; }
      .terms__list p:last-child {
        margin-bottom: 0; }
    .terms__list li {
      position: relative; }
      .terms__list li::before {
        counter-increment: my-awesome-counter;
        content: counter(my-awesome-counter) ". ";
        left: -1.3rem;
        line-height: 1.38;
        top: 0;
        position: absolute;
        font-weight: bold;
        font-size: 1rem;
        color: #444; }
      .terms__list li:nth-child(n+10)::before {
        left: -1.8rem; }
      .terms__list li:not(:last-child) {
        margin-bottom: 1.5rem; }

.post-content {
  margin-top: 0.625rem; }
  .post-content__title h2 {
    font-size: 2.125rem;
    color: #353535;
    line-height: normal; }
  .post-content__date {
    margin-bottom: 2.1875rem; }
    .post-content__date p {
      font-weight: bold;
      line-height: normal;
      color: #a8a8a8; }
  .post-content__text p {
    color: #444444;
    font-weight: normal;
    line-height: 1.38; }
  .post-content__text a {
    color: #bf232a; }
    .post-content__text a:hover {
      text-decoration: unset; }

.news.blog {
  margin-bottom: 4rem; }

.about-items {
  margin-top: 1.5625rem; }
  @media screen and (min-width: 768px) {
    .about-items {
      margin-top: 2.8125rem; } }
  @media screen and (min-width: 1200px) {
    .about-items {
      margin-top: 8rem; } }

.certificates {
  background-color: #f2f2f2;
  padding: 3rem 0 5rem 0;
  margin-bottom: 2.5rem; }
  .certificates h2 {
    font-size: 2.125rem;
    color: #353535;
    line-height: normal;
    margin-bottom: 3.125rem; }
  @media screen and (min-width: 768px) {
    .certificates__group--wrapper {
      display: flex;
      width: 100%; } }
  @media screen and (min-width: 768px) {
    .certificates__group ul:last-child {
      margin-left: 5%; } }
  @media screen and (min-width: 992px) {
    .certificates__group ul:last-child {
      margin-left: 10%; } }
  @media screen and (min-width: 1200px) {
    .certificates__group ul:last-child {
      margin-left: 25%; } }
  @media screen and (max-width: 767px) {
    .certificates__group ul:last-child li:first-child {
      margin-top: 1.5rem; } }
  .certificates__group ul li:not(:last-child) {
    margin-bottom: 1.5rem; }
  .certificates__group ul li a {
    font-size: 1.75rem;
    color: #bf232a;
    line-height: normal; }
    .certificates__group ul li a:hover {
      text-decoration: unset;
      color: #bf232a; }
    .certificates__group ul li a svg {
      margin-right: 1rem; }

.login {
  margin-top: 1.5625rem; }
  .login .error-box {
    margin-left: 85.7px; }
    @media screen and (max-width: 767px) {
      .login .error-box {
        margin-left: auto;
        width: 21.0625rem !important; } }
  .login--over {
    box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17);
    background-color: #ffffff;
    z-index: 20;
    height: 100%; }
    @media screen and (min-width: 768px) {
      .login--over {
        margin-top: -7.8125rem;
        margin-left: auto;
        margin-right: auto; } }
    .login--over .login__form {
      background-color: #bf232a;
      height: 100%; }
      .login--over .login__form h2 {
        color: #fff; }
      .login--over .login__form .form-group label {
        color: #fff; }
      .login--over .login__form .form-group input {
        border: 0;
        border-radius: 0; }
      .login--over .login__form .login__form__info h6 {
        color: #fff; }
      .login--over .login__form .login__form__info a {
        color: #fff; }
      .login--over .login__form input[type="submit"] {
        color: #bf232a;
        background-color: #fff;
        background-image: url("assets/images/arrow-main-color.svg"); }
      @media screen and (min-width: 768px) {
        .login--over .login__form {
          padding: 1.875rem 2.25rem 1rem 1.25rem; } }
    .login--over .login__desc {
      background-color: #fff;
      padding: 1.875rem 1.25rem 1rem 2.375rem; }
      .login--over .login__desc h6 {
        font-weight: normal;
        font-size: 2.125rem; }
      .login--over .login__desc ul li::before {
        background-color: #353535; }
  .login__form {
    background-color: #fff;
    padding: 0.75rem 0.75rem 1.5rem 0.75rem; }
    @media screen and (min-width: 768px) {
      .login__form {
        padding: 0.75rem 1.875rem 2.5rem 1.4375rem; } }
    @media screen and (max-width: 767px) {
      .login__form {
        display: flex;
        flex-direction: column; } }
    .login__form__wrapper .Error input {
      border: 0; }
    @media screen and (max-width: 575px) {
      .login__form__wrapper {
        max-width: 100% !important;
        width: 100% !important; } }
    @media screen and (max-width: 767px) and (min-width: 576px) {
      .login__form__wrapper {
        width: 20.0625rem;
        align-self: center; } }
    @media screen and (max-width: 767px) {
      .login__form__wrapper--signup {
        width: 21.0625rem !important; } }
    @media screen and (min-width: 768px) {
      .login__form__wrapper--signup .form-group {
        width: 18.85625rem; } }
    @media screen and (min-width: 992px) {
      .login__form__wrapper--signup .form-group {
        width: 22.85625rem; } }
    @media screen and (min-width: 768px) {
      .login__form__wrapper--signup .form-group .form-control {
        width: 12rem !important; } }
    @media screen and (min-width: 992px) {
      .login__form__wrapper--signup .form-group .form-control {
        width: 15rem !important; } }
    .login__form h2 {
      font-size: 2.125rem;
      font-weight: normal;
      color: #353535;
      margin-bottom: 1.5rem; }
    .login__form .form-group--error label {
      color: #fe3636 !important; }
    .login__form .form-group--error .form-control {
      border: solid 1px #fe3636 !important; }
    .login__form .form-group--captcha {
      width: 15.3125rem !important; }
      @media screen and (max-width: 767px) {
        .login__form .form-group--captcha {
          margin-bottom: 5.3125rem; } }
    .login__form .form-group .form-control {
      width: 15rem;
      border-radius: 3px;
      border: solid 1px #e7e7e7; }
      @media screen and (min-width: 992px) {
        .login__form .form-group .form-control {
          width: 17.5rem; } }
      .login__form .form-group .form-control:focus {
        outline: none;
        box-shadow: none;
        border-color: #ced4da; }
    .login__form .form-group label {
      font-size: 1rem;
      font-weight: bold;
      color: #353535;
      text-align: right; }
    .login__form .custom-checkbox {
      margin-left: auto;
      margin-bottom: 1rem;
      width: 15rem; }
      @media screen and (min-width: 992px) {
        .login__form .custom-checkbox {
          width: 17.5rem; } }
      .login__form .custom-checkbox input[type="checkbox"] {
        position: absolute;
        z-index: -1;
        opacity: 0; }
        .login__form .custom-checkbox input[type="checkbox"]:checked ~ label::before {
          border-color: #fff;
          background-color: #fff; }
        .login__form .custom-checkbox input[type="checkbox"]:checked ~ label::after {
          content: '\2713';
          top: 2px;
          left: 2px;
          font-size: 0.875rem;
          background-image: unset;
          color: #bf232a; }
      .login__form .custom-checkbox label {
        font-size: 1rem;
        font-weight: bold;
        color: #fff; }
        .login__form .custom-checkbox label:before {
          position: absolute;
          left: 0;
          display: block;
          width: 1rem;
          height: 1rem;
          pointer-events: none;
          content: "";
          background-color: #fff;
          top: .5rem;
          transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
        .login__form .custom-checkbox label:after {
          position: absolute;
          left: 0;
          display: block;
          width: 1rem;
          height: 1rem;
          content: "";
          background: no-repeat 50% / 50% 50%;
          top: .5rem; }
    .login__form input[type="submit"] {
      font-weight: bold;
      font-size: 1rem;
      text-align: left;
      color: #fff;
      background-color: #bf232a;
      border: 0;
      border-radius: 0;
      padding: 0.77rem 0.77rem;
      height: 3.375rem;
      background-image: url("assets/images/arrow.svg");
      background-repeat: no-repeat no-repeat;
      background-position: right 1.125rem center;
      margin-left: auto;
      width: 15rem; }
      .login__form input[type="submit"].btn.btn-primary:not(.disabled):active, .login__form input[type="submit"] .btn.btn-primary:not(:disabled):active {
        background-color: #fff;
        border-color: #fff;
        color: #bf232a; }
      @media screen and (min-width: 992px) {
        .login__form input[type="submit"] {
          width: 17.5rem;
          padding-left: 2rem; } }
    .login__form__info {
      padding-top: 1.5625rem;
      color: #353535;
      text-align: right; }
      .login__form__info h6, .login__form__info a {
        font-weight: bold; }
      .login__form__info a {
        color: #bf232a;
        text-decoration: underline; }
  .login__desc h6 {
    font-size: 1rem;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 1.8125rem;
    color: #353535; }
  .login__desc ul li {
    font-size: 1rem;
    line-height: 1.38;
    color: #444;
    position: relative;
    padding-left: 1.0625rem; }
    .login__desc ul li:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      border-radius: 50%;
      height: 8px;
      background-color: #bf232a; }
  .login__desc .content-text {
    padding-top: 1rem; }

.desc-text h1, .desc-text h2, .desc-text h3, .desc-text h4, .desc-text h5, .desc-text h6 {
  font-size: 1rem;
  line-height: normal;
  color: #353535;
  font-weight: bold; }

.desc-text p, .desc-text ol, .desc-text ul, .desc-text li {
  color: #444444; }

.desc-text li {
  line-height: 1.38; }

.desc-text a {
  color: #bf232a;
  text-decoration: underline; }

.desc-text.or-text span {
  height: 2px;
  display: block;
  background: #a8a8a8;
  width: 8rem; }
  .desc-text.or-text span:first-child {
    margin-right: .7rem; }
  .desc-text.or-text span:last-child {
    margin-left: .7rem; }

.items-group__table table tbody td input {
  height: 2.1875rem;
  border-radius: 3px;
  border: solid 1px #e7e7e7; }
  .items-group__table table tbody td input:focus {
    outline: none;
    box-shadow: none;
    border: solid 1px #e7e7e7; }

.items-group__table table tbody td:nth-child(4) input {
  max-width: 5.625rem; }

.items-group__table table tbody td:nth-child(5) input {
  max-width: 24.25rem; }

.saved-parts-form {
  background-color: #fff;
  padding: 1.25rem 1.25rem 2.375rem 1.75rem; }
  @media screen and (min-width: 768px) {
    .saved-parts-form--lg .saved-parts-form-col:not(:first-child) {
      margin-left: 1.5rem; } }
  @media screen and (min-width: 992px) {
    .saved-parts-form--lg .saved-parts-form-col:not(:first-child) {
      margin-left: 10rem; } }
  .saved-parts-form--lg .saved-parts-form-col label {
    font-size: 1.75rem;
    font-weight: normal;
    line-height: normal; }
  @media screen and (min-width: 768px) {
    .saved-parts-form-col:not(:first-child) {
      margin-left: 4.375rem; } }
  .saved-parts-form-col input {
    height: 2.1875rem; }
  .saved-parts-form-col label {
    font-size: 1rem;
    font-weight: bold;
    line-height: normal;
    color: #353535; }
  .saved-parts-form-col input:not([type=submit]), .saved-parts-form-col textarea {
    font-size: 1rem;
    font-weight: bold;
    border-radius: 3px;
    border: solid 1px #e7e7e7;
    resize: none;
    width: 100%; }
    @media screen and (min-width: 768px) {
      .saved-parts-form-col input:not([type=submit]), .saved-parts-form-col textarea {
        width: 13.6rem; } }
    @media screen and (min-width: 992px) {
      .saved-parts-form-col input:not([type=submit]), .saved-parts-form-col textarea {
        width: 17.5rem; } }
    .saved-parts-form-col input:not([type=submit]):focus, .saved-parts-form-col textarea:focus {
      outline: none;
      box-shadow: none;
      border: solid 1px #e7e7e7; }
  .saved-parts-form-col .items-group__captcha .form-group label {
    color: #353535; }
  .saved-parts-form-col .items-group__captcha .form-group input {
    margin-right: 0.5rem;
    width: 7.9rem; }
    @media screen and (min-width: 768px) {
      .saved-parts-form-col .items-group__captcha .form-group input {
        width: 8.125rem; } }
  .saved-parts-form-col button.btn-primary {
    border-radius: 0;
    text-align: left;
    width: 14rem; }
    @media screen and (min-width: 768px) {
      .saved-parts-form-col button.btn-primary {
        width: 17.5rem;
        padding-left: 2rem; } }
  @media screen and (max-width: 767px) {
    .saved-parts-form-col .form-group-wrapper {
      width: 100%; }
      .saved-parts-form-col .form-group-wrapper .form-group {
        width: 100%; } }

.required-error {
  border: 1px solid #fe3636 !important; }

.requestTableFullWidth {
  width: 100%; }

.reCaptchaRequestPadding {
  margin-right: -2px; }

.items-group:not(.no-mobile) .items-group__table__row-body {
  padding: 0 0.875rem; }
  @media screen and (max-width: 767px) {
    .items-group:not(.no-mobile) .items-group__table__row-body {
      border-radius: 3px;
      border: solid 1px #9e9e9e; } }
  @media screen and (max-width: 767px) {
    .items-group:not(.no-mobile) .items-group__table__row-body:not(:first-child) {
      margin-top: 25px; } }
  @media screen and (min-width: 768px) {
    .items-group:not(.no-mobile) .items-group__table__row-body {
      padding: 0.78125rem 0.875rem; } }
  .items-group:not(.no-mobile) .items-group__table__row-body .column {
    position: relative; }
  @media screen and (max-width: 767px) {
    .items-group:not(.no-mobile) .items-group__table__row-body .column:first-child {
      font-size: .85rem;
      text-align: center;
      width: 40%;
      position: relative;
      justify-content: center; }
      .items-group:not(.no-mobile) .items-group__table__row-body .column:first-child:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #9e9e9e; }
    .items-group:not(.no-mobile) .items-group__table__row-body .column {
      height: 100%;
      padding: 0.625rem 1rem;
      display: flex;
      align-items: center; }
    .items-group:not(.no-mobile) .items-group__table__row-body .column:last-child {
      width: 60%; }
    .items-group:not(.no-mobile) .items-group__table__row-body .mobile-row:nth-of-type(even) {
      background-color: #efefef;
      border-right: 1px solid #9e9e9e;
      border-left: 1px solid #9e9e9e; } }
  .items-group:not(.no-mobile) .items-group__table__row-body:nth-of-type(odd) {
    background-color: transparent; }
  @media screen and (min-width: 768px) {
    .items-group:not(.no-mobile) .items-group__table__row-body .column {
      width: 100%; }
    .items-group:not(.no-mobile) .items-group__table__row-body:nth-of-type(odd) {
      border-radius: 3px;
      background-color: #efefef; } }

.items-group__title h2 {
  font-size: 2.125rem;
  color: #353535;
  line-height: normal; }

.items-group__title p {
  color: #444444;
  line-height: 1.38; }

.items-group table.table tbody tr td {
  font-size: .85rem; }
  .items-group table.table tbody tr td:hover {
    background-color: unset; }

.items-group__table__row-header {
  border: 1px solid #bf232a;
  border-radius: 3px;
  padding: 0.78125rem 0.875rem; }
  .items-group__table__row-header h6 {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: normal;
    margin-bottom: 0;
    color: #353535; }
    .items-group__table__row-header h6.link-head {
      color: #bf232a; }

.items-group__table__row-body {
  padding: 0.78125rem 0.875rem; }
  .items-group__table__row-body:nth-of-type(odd) {
    border-radius: 3px;
    background-color: #efefef; }
  .items-group__table__row-body p {
    font-size: 0.875rem;
    line-height: normal;
    margin-bottom: 0;
    color: #353535; }
  .items-group__table__row-body a {
    color: #bf232a;
    font-size: 0.875rem; }
    .items-group__table__row-body a:hover {
      text-decoration: unset; }
  .items-group__table__row-body input.form-control {
    border-radius: 3px;
    border: solid 1px #e7e7e7; }
    .items-group__table__row-body input.form-control:focus {
      outline: none;
      box-shadow: unset;
      border: solid 1px #e7e7e7; }

.items-group__table--border table {
  border-top: 2px solid #bf232a; }

.items-group__table table thead tr th {
  font-size: 0.875rem;
  line-height: normal; }
  .items-group__table table thead tr th::after {
    display: none; }
  .items-group__table table thead tr th::before {
    display: none; }
  .items-group__table table thead tr th.text-secondary {
    color: #353535 !important; }

.items-group__table table tbody td {
  padding: 0.75rem !important; }

.items-group textarea {
  font-size: 1rem;
  font-weight: bold;
  color: #a8a8a8;
  resize: none; }
  .items-group textarea:focus {
    outline: none;
    box-shadow: none;
    border-color: #ced4da; }

.items-group--form {
  padding: 2.375rem 1rem;
  background-color: #f2f2f2; }

.items-group__captcha .form-group label {
  font-size: 1rem;
  font-weight: bold;
  color: #a8a8a8;
  margin-bottom: 0; }

.items-group__captcha .form-group .form-control {
  width: 7rem;
  border-radius: 3px;
  border: solid 1px #e7e7e7; }
  @media screen and (min-width: 992px) {
    .items-group__captcha .form-group .form-control {
      width: 10rem; } }
  .items-group__captcha .form-group .form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: #ced4da; }

.items-group__captcha__img {
  height: 1.875rem;
  max-width: 5rem; }
  .items-group__captcha__img img {
    height: 100%;
    width: 100%; }

.items-group__captcha button.btn-primary {
  border-radius: 0;
  width: 11.25rem; }

.items-group:not(:first-child) {
  margin-top: 3.75rem; }
  @media screen and (min-width: 768px) {
    .items-group:not(:first-child) {
      margin-top: 10.3125rem; } }

.items-group:last-child {
  margin-top: 2.1875rem; }
  @media screen and (min-width: 768px) {
    .items-group:last-child {
      margin-top: 8rem; } }

.items-group__table__row-body .svd-item:hover {
  text-decoration: underline; }

.profile-card__left {
  padding: 1.25rem 1.875rem 1.875rem 1.875rem;
  background-color: #bf232a;
  height: 100%; }
  .profile-card__left__primary {
    color: #fff; }
    .profile-card__left__primary h3 {
      font-size: 2.125rem;
      font-weight: normal;
      line-height: normal; }
    .profile-card__left__primary h5 {
      font-size: 1rem;
      font-weight: bold;
      line-height: normal; }
      .profile-card__left__primary h5:last-child {
        margin-bottom: 0; }
  .profile-card__left__secondary {
    color: #eea1a5;
    padding-top: 1.5625rem; }
    .profile-card__left__secondary h5 {
      font-weight: bold;
      font-size: 1rem;
      line-height: normal; }
      .profile-card__left__secondary h5:not(:last-child) {
        margin-bottom: 1.875rem; }
      .profile-card__left__secondary h5:last-child {
        margin-bottom: 0; }

.profile-card__right {
  background-color: #fff;
  height: 100%;
  padding: 1.25rem 1.5rem 1.5rem 1.5rem; }
  @media screen and (min-width: 1200px) {
    .profile-card__right {
      padding: 1.25rem 1.875rem 1.875rem 3rem; } }
  .profile-card__right a {
    font-size: 1rem;
    font-weight: bold;
    color: #bf232a;
    line-height: normal; }
    .profile-card__right a:hover {
      text-decoration: unset; }
  .profile-card__right ul:first-child span {
    color: #a8a8a8;
    margin-right: 1.92rem; }
  .profile-card__right ul:first-child li {
    text-align: right; }
  .profile-card__right ul:last-child span {
    color: #353535; }
  .profile-card__right ul:last-child div:last-child span:first-child {
    color: #a8a8a8;
    margin-right: 1.125rem; }
  .profile-card__right ul li:not(:last-child) {
    margin-bottom: 1.875rem; }
  .profile-card__right ul li span {
    font-size: 1rem;
    font-weight: bold; }

.personal-info h2 {
  margin-bottom: 1.5625rem; }

.personal-info__form {
  background-color: #fff;
  padding: 1.25rem 1.5rem 1.5rem 1.5rem; }
  @media screen and (min-width: 768px) {
    .personal-info__form {
      padding: 1.875rem 2.8125rem 3.75rem 1.875rem; } }
  .personal-info__form .label label {
    font-size: 1rem;
    font-weight: bold;
    line-height: normal;
    color: #353535; }
  @media screen and (max-width: 767px) {
    .personal-info__form .form-group-wrapper {
      width: 100%; } }
  .personal-info__form .form-group label {
    font-weight: bold;
    color: #353535; }
  .personal-info__form .form-group input {
    font-size: 1rem;
    font-weight: bold;
    border-radius: 3px;
    border: solid 1px #e7e7e7; }
    @media screen and (min-width: 992px) {
      .personal-info__form .form-group input {
        width: 17.5rem; } }
    .personal-info__form .form-group input:focus {
      outline: none;
      box-shadow: none;
      border-color: #e7e7e7; }
  .personal-info__form .form-group--error label {
    color: #fe3636; }
  .personal-info__form .form-group--error input.form-control {
    border: solid 1px #fe3636; }
  .personal-info__form a {
    font-size: 1rem;
    font-weight: bold;
    color: #bf232a; }
    .personal-info__form a:hover {
      text-decoration: unset; }
  .personal-info__form input[type="submit"] {
    font-weight: bold;
    font-size: 1rem;
    text-align: left;
    color: #fff;
    background-color: #bf232a;
    border: 0;
    border-radius: 0;
    height: 3.375rem;
    background-image: url("assets/images/arrow.svg");
    background-repeat: no-repeat no-repeat;
    background-position: right 1.125rem center;
    width: 10.5rem;
    margin-left: 1rem; }
    @media screen and (min-width: 992px) {
      .personal-info__form input[type="submit"] {
        width: 11.25rem;
        padding-left: 2rem; } }

.profile-card__left {
  padding: 1.25rem 1.875rem 1.875rem 1.875rem;
  background-color: #bf232a;
  height: 100%; }
  .profile-card__left__primary {
    color: #fff; }
    .profile-card__left__primary h3 {
      font-size: 2.125rem;
      font-weight: normal;
      line-height: normal; }
    .profile-card__left__primary h5 {
      font-size: 1rem;
      font-weight: bold;
      line-height: normal; }
      .profile-card__left__primary h5:last-child {
        margin-bottom: 0; }
  .profile-card__left__secondary {
    color: #eea1a5;
    padding-top: 1.5625rem; }
    .profile-card__left__secondary h5 {
      font-weight: bold;
      font-size: 1rem;
      line-height: normal; }
      .profile-card__left__secondary h5:not(:last-child) {
        margin-bottom: 1.875rem; }
      .profile-card__left__secondary h5:last-child {
        margin-bottom: 0; }

.profile-card__right {
  background-color: #fff;
  height: 100%;
  padding: 1.25rem 1.5rem 1.5rem 1.5rem; }
  @media screen and (min-width: 1200px) {
    .profile-card__right {
      padding: 1.25rem 1.875rem 1.875rem 3rem; } }
  .profile-card__right a {
    font-size: 1rem;
    font-weight: bold;
    color: #bf232a;
    line-height: normal; }
    .profile-card__right a:hover {
      text-decoration: unset; }
  .profile-card__right ul:first-child span {
    color: #a8a8a8;
    margin-right: 1.92rem; }
  .profile-card__right ul:first-child li {
    text-align: right; }
  .profile-card__right ul:last-child span {
    color: #353535; }
  .profile-card__right ul:last-child div:last-child span:first-child {
    color: #a8a8a8;
    margin-right: 1.125rem; }
  .profile-card__right ul li:not(:last-child) {
    margin-bottom: 1.875rem; }
  .profile-card__right ul li span {
    font-size: 1rem;
    font-weight: bold; }

.personal-info h2 {
  margin-bottom: 1.5625rem; }

.personal-info__form {
  background-color: #fff;
  padding: 1.25rem 1.5rem 1.5rem 1.5rem; }
  @media screen and (min-width: 768px) {
    .personal-info__form {
      padding: 1.875rem 2.8125rem 3.75rem 1.875rem; } }
  .personal-info__form .label label {
    font-size: 1rem;
    font-weight: bold;
    line-height: normal;
    color: #353535; }
  @media screen and (max-width: 767px) {
    .personal-info__form .form-group-wrapper {
      width: 100%; } }
  .personal-info__form .form-group label {
    font-weight: bold;
    color: #353535; }
  .personal-info__form .form-group input {
    font-size: 1rem;
    font-weight: bold;
    border-radius: 3px;
    border: solid 1px #e7e7e7; }
    @media screen and (min-width: 992px) {
      .personal-info__form .form-group input {
        width: 17.5rem; } }
    .personal-info__form .form-group input:focus {
      outline: none;
      box-shadow: none;
      border-color: #e7e7e7; }
  .personal-info__form .form-group--error label {
    color: #fe3636; }
  .personal-info__form .form-group--error input.form-control {
    border: solid 1px #fe3636; }
  .personal-info__form a {
    font-size: 1rem;
    font-weight: bold;
    color: #bf232a; }
    .personal-info__form a:hover {
      text-decoration: unset; }
  .personal-info__form input[type="submit"] {
    font-weight: bold;
    font-size: 1rem;
    text-align: left;
    color: #fff;
    background-color: #bf232a;
    border: 0;
    border-radius: 0;
    height: 3.375rem;
    background-image: url("assets/images/arrow.svg");
    background-repeat: no-repeat no-repeat;
    background-position: right 1.125rem center;
    width: 10.5rem;
    margin-left: 1rem; }
    @media screen and (min-width: 992px) {
      .personal-info__form input[type="submit"] {
        width: 11.25rem;
        padding-left: 2rem; } }

input[type=date] {
  background: url("assets/images/calendar.png") no-repeat;
  background-position: 7px center;
  padding-left: 2.5rem; }

@media screen and (min-width: 768px) {
  .rma-table {
    margin: 6rem 0; } }

.rma-table .items-group__table__row-header {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid #bf232a;
  border-radius: 0; }
  .rma-table .items-group__table__row-header h6 {
    font-size: 0.875rem;
    font-weight: normal;
    color: #353535; }

.rma-table .items-group__table__row-body:nth-of-type(odd) {
  background-color: transparent !important; }

.rma-table button {
  border-radius: 0;
  border: solid 1px #bf232a;
  background-color: #bf232a;
  color: #fff;
  font-weight: bold;
  border: 0;
  border-radius: 0; }
  .rma-table button:focus {
    background-color: #bf232a;
    color: #fff;
    outline: none;
    box-shadow: unset; }

.rma-table .trash svg {
  width: 20px;
  height: auto;
  margin: auto; }
  .rma-table .trash svg path {
    pointer-events: none; }
  .rma-table .trash svg:hover {
    cursor: pointer; }

.rma-table .trash-mobile svg {
  width: 20px;
  height: auto;
  margin: auto; }
  .rma-table .trash-mobile svg:hover {
    cursor: pointer; }

.upload-file {
  background-color: #ffffff;
  padding: 1.875rem 1.5625rem 2.5rem 1.5625rem; }
  .upload-file h2 {
    font-size: 1.75rem;
    color: #353535;
    line-height: normal; }
  .upload-file__area {
    border: 1px dashed rgba(191, 35, 42, 0.4); }
    .upload-file__area__content {
      padding: 1.75rem 1.25rem;
      position: relative; }
      .upload-file__area__content h4 {
        font-size: 1rem;
        color: #444444;
        font-weight: bold;
        line-height: normal;
        letter-spacing: normal; }
        .upload-file__area__content h4 label {
          color: #bf232a;
          text-decoration: underline;
          margin-bottom: 0; }
          .upload-file__area__content h4 label:hover {
            cursor: pointer; }
      .upload-file__area__content input[type="file"] {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0; }

.submit-rma .rma-request-submit {
  border-radius: 0;
  border: solid 1px #bf232a;
  background-color: #bf232a;
  color: #fff;
  font-weight: bold;
  width: 15rem;
  padding-left: 1rem;
  border: 0;
  border-radius: 0;
  height: 3.375rem;
  background-image: url("assets/images/arrow.svg");
  background-repeat: no-repeat no-repeat;
  background-position: right 1.125rem center;
  margin-left: auto; }
  @media screen and (min-width: 576px) {
    .submit-rma .rma-request-submit {
      width: 17.5rem;
      padding-left: 2rem; } }
  .submit-rma .rma-request-submit.loading {
    background-image: url("assets/images/loader.gif");
    background-size: 35px 35px;
    background-repeat: no-repeat no-repeat;
    background-position: right 1.125rem center; }

.upload-saved-parts p {
  font-size: 1rem;
  color: #444;
  font-weight: 700;
  line-height: normal;
  letter-spacing: normal; }
  .upload-saved-parts p a {
    color: #bf232a;
    text-decoration: underline; }
    .upload-saved-parts p a:hover {
      color: #bf232a; }

.suggestions {
  position: absolute;
  left: 0;
  top: 38px;
  width: 100%;
  border: 0;
  border-radius: 0;
  padding: 0.8125rem 0.75rem 0.9375rem 0.75rem;
  box-shadow: 0 5px 10px 0 rgba(245, 0, 11, 0.17);
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 10; }
  .suggestions li {
    font-weight: bold;
    color: #353535;
    transition: all 0.3s ease-in-out; }
    .suggestions li:hover {
      color: #bf232a;
      cursor: pointer; }

.empt-title {
  padding-top: 20px; }

.quotes__title {
  padding: 1.25rem 1.875rem 1.875rem 1.875rem;
  background-color: #bf232a;
  color: #fff;
  height: 100%; }
  .quotes__title h3 {
    font-size: 1.75rem;
    font-weight: normal;
    line-height: normal; }
  .quotes__title h5 {
    font-size: 1rem;
    font-weight: bold;
    line-height: normal; }
    .quotes__title h5:last-child {
      margin-bottom: 0; }

.quotes__text {
  height: 100%;
  background-color: #fff;
  padding: 1.25rem 2.5rem 1.5rem 3.125rem; }
  .quotes__text p {
    display: inline-block;
    position: relative; }
    .quotes__text p:first-child::before {
      content: url("assets/images/quote-up.svg");
      position: absolute;
      left: -40px;
      top: 0; }
    .quotes__text p:last-child::after {
      content: url("assets/images/quote-down.svg");
      position: absolute;
      right: -40px;
      bottom: -10px; }
  .quotes__text * {
    font-size: 1rem;
    color: #444444;
    line-height: 1.38; }

.cards-col:nth-child(even) .card--question {
  margin-left: auto; }

.form-questions-no {
  font-size: 1.75rem;
  color: #353535;
  font-weight: normal;
  line-height: normal;
  margin-right: 0.8rem; }
  @media screen and (min-width: 992px) {
    .form-questions-no {
      margin-right: 1.875rem; } }

.form-questions .card.card--question {
  max-width: 100%;
  width: 100%;
  padding: 1rem 1.1875rem 0.8125rem 1.1875rem; }

.form-questions .card__question {
  margin-left: 0; }

.form-questions:nth-child(even) .card {
  background-color: #f4f4f4; }

.card {
  transition: .25s ease-in-out; }
  .card.card--question {
    max-width: 36.25rem;
    min-height: 7.1875rem;
    border: 0;
    border-radius: 0;
    padding: 1.5625rem 2.1875rem 1.5625rem 2.1875rem;
    margin-bottom: 0.75rem; }
  .card.card--active {
    box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17);
    transition: .25s ease-in-out; }
    .card.card--active svg {
      transition: .25s ease-in-out; }
      .card.card--active svg path {
        transition: .25s ease-in-out;
        stroke: #444; }
  @media screen and (min-width: 992px) {
    .card__question {
      margin-left: 2rem; } }
  .card__question h4 {
    font-size: 1.75rem;
    font-weight: normal;
    line-height: normal;
    color: #353535;
    margin-bottom: 1.25rem; }
  .card__question__answers span.mark-range {
    font-size: 1rem;
    font-weight: bold;
    line-height: normal;
    color: #353535; }
  .card__question__answers__form .rating-group span {
    display: inline-flex;
    margin: 0 0.5rem; }
  .card__question__answers__form .rating-group .rating__icon {
    pointer-events: none; }
  .card__question__answers__form .rating-group input[type="radio"] {
    position: absolute !important;
    left: -99999px !important;
    visibility: hidden; }
  .card__question__answers__form .rating-group .rating__input--none {
    display: none; }
  .card__question__answers__form .rating-group label {
    cursor: pointer;
    padding: 0 0.5rem;
    font-size: 1.6875rem;
    margin-bottom: 0;
    transition: .2s ease-in-out; }
  .card__question__answers__form .rating-group .clear-form .rating__icon--star {
    -webkit-text-stroke: 1px #bf232a;
    color: transparent; }
  .card__question__answers__form .rating-group .rating__icon--star {
    color: #bf232a;
    transition: .2s ease-in-out; }
  .card__question__answers__form .rating-group input[type="radio"]:checked ~ label .rating__icon--star {
    -webkit-text-stroke: 1px #bf232a;
    color: transparent; }
  @media screen and (min-width: 1025px) {
    .card__question__answers__form .rating-group label:hover .rating__icon--star {
      -webkit-text-stroke: 1px #bf232a;
      color: rgba(191, 35, 42, 0.26) !important; } }

.radio-form {
  margin-top: 11.25rem; }
  .radio-form-card {
    background-color: #fff;
    padding: 1rem 1.1875rem 1.6875rem 1.1875rem; }
    .radio-form-card h4 {
      font-size: 1.75rem;
      font-weight: normal;
      line-height: normal;
      color: #353535;
      margin-bottom: 1.25rem; }
    .radio-form-card .custom-control:first-child {
      margin-right: 2.5rem; }
    .radio-form-card label {
      font-size: 1rem;
      font-weight: bold;
      line-height: normal;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      color: #353535; }
      .radio-form-card label:hover {
        cursor: pointer; }
      .radio-form-card label:focus {
        box-shadow: unset;
        outline: none; }
      .radio-form-card label::before {
        width: 1.125rem;
        height: 1.125rem;
        background-color: transparent;
        border: 1px solid #bf232a; }
      .radio-form-card label::after {
        top: 7px;
        left: -21px;
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%; }
    .radio-form-card input[type="radio"] {
      position: absolute;
      z-index: -1;
      opacity: 0; }
    .radio-form-card input[type="radio"]:focus ~ label::before {
      box-shadow: unset; }
    .radio-form-card input[type="radio"]:not(:disabled):active ~ label::before {
      background-color: transparent;
      border-color: #bf232a; }
    .radio-form-card input[type="radio"]:checked ~ label::after {
      background-image: unset;
      background-color: #bf232a; }
    .radio-form-card input[type="radio"]:checked ~ label::before {
      border-color: #bf232a;
      background-color: transparent; }

.extra-form {
  margin-top: 5.625rem; }
  .extra-form label {
    font-size: 2.125rem;
    color: #353535;
    line-height: normal;
    margin-bottom: 1rem; }
  .extra-form textarea {
    font-size: 1rem;
    color: #353535;
    border-radius: 3px;
    border: solid 1px #e7e7e7;
    background-color: #ffffff;
    max-width: 56.25rem;
    height: 80px;
    resize: none; }
    .extra-form textarea:focus {
      outline: none;
      box-shadow: none;
      border: solid 1px #e7e7e7; }

.submit-button input[type="submit"] {
  font-weight: 700;
  font-size: 1rem;
  text-align: left;
  color: #fff;
  background-color: #bf232a;
  border: 0;
  border-radius: 0;
  padding: .77rem;
  height: 3.375rem;
  background-image: url(assets/images/arrow.svg);
  background-repeat: no-repeat;
  background-position: right 1.125rem center;
  width: 15rem; }

.ErrorLabel {
  display: none; }

#digitron-tabContent {
  margin-top: 1rem; }
  @media screen and (min-width: 768px) {
    #digitron-tabContent {
      margin-top: 2.8125rem; } }
  #digitron-tabContent table.table tbody tr td:first-child {
    align-items: flex-start; }

.open-positions__title {
  font-size: 2.5rem;
  color: #353535;
  letter-spacing: normal;
  line-height: normal;
  margin-bottom: 3.125rem; }

.open-positions .open-position {
  background-color: #fff;
  border-left: 2px solid #bf232a;
  transition: .25s ease-in-out;
  margin-bottom: 1.375rem;
  min-height: 18.75rem;
  padding: 0.9375rem 2rem 1rem 1.25rem; }
  @media screen and (min-width: 1200px) {
    .open-positions .open-position {
      padding: 0.9375rem 8.125rem 1rem 1.25rem; } }
  .open-positions .open-position.active {
    transition: .25s ease-in-out;
    box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17); }
  .open-positions .open-position__title {
    max-width: 28rem; }
    .open-positions .open-position__title h3 {
      font-size: 2.125rem;
      color: #353535;
      letter-spacing: normal;
      line-height: normal;
      margin-bottom: 1.875rem; }
    @media screen and (min-width: 992px) {
      .open-positions .open-position__title {
        margin-right: 1.5rem; } }
    @media screen and (min-width: 1200px) {
      .open-positions .open-position__title {
        margin-right: 3rem; } }
  .open-positions .open-position .apply-position {
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;
    border: solid 1px #bf232a;
    background-color: #bf232a;
    color: #fff;
    padding: 1rem 1rem;
    width: 15rem; }
    @media screen and (min-width: 576px) {
      .open-positions .open-position .apply-position {
        width: 17.5rem; } }
    .open-positions .open-position .apply-position span {
      padding-left: 1rem; }
    .open-positions .open-position .apply-position:hover {
      text-decoration: unset; }
  .open-positions .open-position__desc p, .open-positions .open-position__desc ul {
    font-size: 1rem;
    color: #444444; }
  .open-positions .open-position__desc ul {
    list-style-type: none;
    padding-left: 0; }
    .open-positions .open-position__desc ul li:before {
      content: "- "; }
  .open-positions .open-position__desc a {
    font-weight: bold;
    font-size: 1rem;
    color: #bf232a; }
    .open-positions .open-position__desc a:hover {
      text-decoration: unset; }

.submit-resume {
  background-color: #f2f2f2;
  margin-top: 6rem;
  padding: 2rem 0 3.8rem 0; }
  @media screen and (min-width: 768px) {
    .submit-resume {
      margin-top: 13.75rem; } }
  .submit-resume .section-title {
    margin-bottom: 2.5rem; }
    .submit-resume .section-title h2 {
      margin-bottom: 1rem; }
  .submit-resume .label {
    height: 35px; }
  .submit-resume label {
    font-weight: bold; }
  @media screen and (max-width: 767px) {
    .submit-resume .form-group-wrapper {
      width: 100%; } }
  @media screen and (min-width: 768px) {
    .submit-resume .right {
      margin-left: 4rem; } }
  @media screen and (min-width: 992px) {
    .submit-resume .right {
      margin-left: 13rem; } }
  .submit-resume .right .file-group {
    margin-top: 1rem; }
    .submit-resume .right .file-group button {
      font-weight: bold;
      color: #bf232a;
      padding: 0;
      vertical-align: top; }
      .submit-resume .right .file-group button:hover {
        cursor: pointer; }
      .submit-resume .right .file-group button:focus {
        outline: none;
        box-shadow: none; }
    .submit-resume .right .file-group span {
      color: #353535;
      font-weight: bold;
      display: inline-block;
      padding-left: .7rem; }
    .submit-resume .right .file-group span.EditingFormErrorLabel {
      max-width: 280px !important;
      display: block !important;
      padding-left: 0 !important; }
    .submit-resume .right .file-group span:not(.EditingFormErrorLabel) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 180px; }
    .submit-resume .right .file-group .custom-file {
      margin-left: 1.8rem; }
      .submit-resume .right .file-group .custom-file input[type=file] {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 82px;
        height: 26px;
        cursor: pointer; }
  .submit-resume input[type="text"], .submit-resume input[type="email"] {
    height: 35px; }
  .submit-resume input[type="text"], .submit-resume input[type="email"], .submit-resume textarea {
    font-size: 1rem;
    font-weight: bold;
    color: #353535;
    border-radius: 3px;
    border: solid 1px #e7e7e7; }
    .submit-resume input[type="text"]:focus, .submit-resume input[type="email"]:focus, .submit-resume textarea:focus {
      outline: none;
      box-shadow: unset;
      border: solid 1px #e7e7e7; }
    @media screen and (min-width: 768px) {
      .submit-resume input[type="text"], .submit-resume input[type="email"], .submit-resume textarea {
        width: 15rem; } }
    @media screen and (min-width: 992px) {
      .submit-resume input[type="text"], .submit-resume input[type="email"], .submit-resume textarea {
        width: 17.5rem; } }
  .submit-resume textarea {
    height: 8.5625rem;
    resize: none; }
  .submit-resume button.btn-primary {
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;
    border: solid 1px #bf232a;
    background-color: #bf232a;
    border-radius: 0 !important;
    color: #fff;
    padding: 1rem 1rem;
    width: 15rem; }
    @media screen and (min-width: 992px) {
      .submit-resume button.btn-primary {
        width: 17.5rem; } }
    .submit-resume button.btn-primary span {
      padding-left: 1rem; }
    .submit-resume button.btn-primary:hover {
      text-decoration: unset; }
  .submit-resume.apply-position-form {
    margin-top: 1.3125rem;
    padding: 0;
    background-color: transparent; }
    .submit-resume.apply-position-form .apply-position-form-wrapper {
      background-color: #fff;
      padding: 0.75rem 1.4375rem 2.3125rem 1.4375rem; }

.message-request {
  padding: 2rem 0 4.8rem 0; }

.contacts {
  margin-top: 2.4375rem; }
  .contacts .contacts__item {
    min-height: 10rem;
    background-color: #ffffff;
    border-left: 2px solid #bf232a;
    padding: 1rem 1.5rem 1rem 1.5rem; }
    @media screen and (min-width: 768px) {
      .contacts .contacts__item {
        padding: 1rem 3.75rem 1rem 2.875rem; } }
    .contacts .contacts__item .logo {
      font-size: 2.125rem;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #353535;
      margin-bottom: 0; }
    @media screen and (min-width: 768px) {
      .contacts .contacts__item .street {
        max-width: 21.875rem; } }
    .contacts .contacts__item .street p, .contacts .contacts__item .street a {
      font-size: 1.75rem;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #353535;
      margin-bottom: 0; }
    .contacts .contacts__item .street a {
      transition: color 0.3s ease-in-out; }
      .contacts .contacts__item .street a:hover {
        text-decoration: none;
        color: #bf232a; }
    .contacts .contacts__item .phone p {
      font-size: 1.75rem;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #353535;
      margin-bottom: 0; }
      .contacts .contacts__item .phone p a {
        color: #353535;
        transition: color .25s ease-in-out; }
        .contacts .contacts__item .phone p a:hover {
          color: #bf232a;
          text-decoration: unset; }

.distributors {
  margin-top: 1.75rem; }
  .distributors .title h4 {
    font-size: 2.125rem;
    color: #353535;
    margin-bottom: 1.75rem; }
  .distributors__wrapper {
    background-color: #ffffff;
    padding: 1.5625rem 2.8125rem 3.25rem 2.1875rem; }
    .distributors__wrapper img {
      max-width: 7.1875rem;
      margin-bottom: 2.1875rem; }
    @media screen and (max-width: 767px) {
      .distributors__wrapper .distributor__col:not(:nth-child(2)) {
        margin-top: 6.25rem; } }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      .distributors__wrapper .distributor__col:not(:nth-child(-n+3)) {
        margin-top: 6.25rem; } }
    @media screen and (min-width: 992px) {
      .distributors__wrapper .distributor__col:not(:nth-child(-n+4)) {
        margin-top: 6.25rem; } }
    @media screen and (min-width: 768px) {
      .distributors__wrapper__item {
        max-width: 21.5625rem; } }
    .distributors__wrapper__item__title {
      font-size: 1.55rem;
      line-height: normal;
      letter-spacing: normal;
      color: #353535;
      border-bottom: 2px solid #bf232a;
      padding-bottom: 0.75rem;
      margin-bottom: 0.75rem; }
    .distributors__wrapper__item__content ul li {
      font-size: 1.25rem;
      line-height: normal;
      letter-spacing: normal;
      color: #353535; }
      .distributors__wrapper__item__content ul li a {
        color: #353535;
        transition: color 0.3s ease-in-out; }
        .distributors__wrapper__item__content ul li a:hover {
          text-decoration: none;
          color: #bf232a; }

@media screen and (min-width: 768px) {
  .reCaptchaPadding div:first-of-type {
    padding-right: 80px;
    transform: scale(0.98); } }

body.error-page {
  padding-bottom: 0; }
  @media screen and (max-width: 767px) {
    body.error-page main {
      padding-top: 2rem;
      padding-bottom: 2rem; } }

main.not-found-page .text h1 {
  font-size: 6.25rem;
  font-weight: bold;
  color: #bf232a; }

main.not-found-page .text h2 {
  font-size: 2.625rem;
  color: #353535;
  font-weight: bold;
  text-transform: uppercase; }

main.not-found-page .text p {
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  color: #353535; }

main.not-found-page .actions {
  margin-top: 2.5rem; }
  main.not-found-page .actions a:hover {
    text-decoration: unset; }
  main.not-found-page .actions a {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media screen and (min-width: 576px) {
      main.not-found-page .actions a {
        max-width: 14rem;
        width: 100%; } }
  main.not-found-page .actions a:first-child {
    color: #bf232a;
    border: 1px solid #bf232a;
    background-color: #fff;
    font-weight: 700;
    width: 100%;
    padding: 1rem 1rem 1rem 1.375rem;
    transition: all .25s ease-in-out; }
    main.not-found-page .actions a:first-child span {
      padding-right: 3.125rem; }
  main.not-found-page .actions a:last-child {
    color: #fff;
    border: 1px solid #bf232a;
    background-color: #bf232a;
    font-weight: 700;
    width: 100%;
    padding: 1rem 1rem 1rem 1.375rem;
    transition: all .25s ease-in-out; }
    main.not-found-page .actions a:last-child span {
      padding-right: 3.125rem; }

@media screen and (max-width: 767px) {
  main.not-found-page .emojii {
    margin-top: 3rem; } }

@media screen and (max-width: 767px) {
  main.not-found-page .emojii svg {
    max-width: 45%; } }

@media screen and (min-width: 992px) {
  main.not-found-page .emojii svg {
    width: 21.875rem; } }

.product-types {
  margin: 2.5rem 0 6rem 0; }
  .product-types .product-overview__col:nth-last-child(-n+3) .product-overview__item {
    margin-bottom: 0; }

.product-overview__item {
  background-color: #fff;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  transition: all .25s ease-in-out;
  padding: 1rem 1rem 1rem 1rem; }
  .product-overview__item.active {
    box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17); }
  @media screen and (min-width: 768px) {
    .product-overview__item {
      min-height: 22rem;
      padding: 1.5rem 2rem 1.5rem 1rem; } }
  @media screen and (min-width: 992px) {
    .product-overview__item {
      min-height: 25rem; } }
  @media screen and (min-width: 1200px) {
    .product-overview__item {
      min-height: 22rem; } }
  .product-overview__item__title {
    font-size: 1.75rem;
    line-height: normal;
    letter-spacing: normal;
    color: #353535;
    border-bottom: 2px solid #bf232a;
    padding-bottom: .75rem;
    margin-bottom: .75rem; }
  .product-overview__item__desc {
    font-size: 1.2rem;
    line-height: normal;
    letter-spacing: normal;
    color: #353535;
    padding-bottom: 1rem; }
  .product-overview__item__action {
    margin-top: auto; }
    .product-overview__item__action a {
      font-weight: 700;
      font-size: 1rem;
      color: #bf232a;
      text-decoration: unset; }

.product-stock #scrollingcontent .scrollingcontentblock {
  overflow-x: auto; }


.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  max-width: 1400px; }
  .container-fluid--fullWidth {
    max-width: unset; }
    @media screen and (min-width: 768px) {
      .container-fluid--fullWidth .post--fullSize {
        min-height: 30.75rem; } }

.text-muted {
  color: #a8a8a8 !important; }

* {
  font-family: "Roboto Condensed", sans-serif; }

html {
  position: relative;
  min-height: 100%; }

body {
  background-color: #fbfbfb;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 240px; }
  @media screen and (min-width: 992px) {
    body {
      padding-bottom: 180px; } }
  @media screen and (min-width: 1200px) {
    body {
      padding-bottom: 120px; } }

main {
  padding-top: 4.75rem;
  padding-bottom: 9.5rem; }
  @media screen and (min-width: 1200px) {
    main {
      padding-top: 7.84rem; } }

.text-primary {
  color: #bf232a; }

.text-secondary {
  color: #3b3b3b; }

[type='file'],
[type='image'] {
  cursor: pointer; }

[type='file']::-webkit-file-upload-button {
  -webkit-appearance: button;
  cursor: pointer; }
