.profile-card {
    &__left {
        padding: 1.25rem 1.875rem 1.875rem 1.875rem;
        background-color: $mainTextClr;
        height: 100%;
        &__primary {
            color: #fff;
            h3 {
                font-size: 2.125rem;
                font-weight: normal;
                line-height: normal;
            }
            h5 {
                font-size: 1rem;
                font-weight: bold;
                line-height: normal;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &__secondary {
            color: #eea1a5;
            padding-top: 1.5625rem;
            h5 {
                font-weight: bold;
                font-size: 1rem;
                &:not(:last-child) {
                    margin-bottom: 1.875rem;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                line-height: normal;
            }
        }
    }
    &__right {
        background-color: #fff;
        height: 100%;
        padding: 1.25rem 1.5rem 1.5rem 1.5rem;
        @media screen and (min-width: 1200px) {
            padding: 1.25rem 1.875rem 1.875rem 3rem;
        }
        a {
            font-size: 1rem;
            font-weight: bold;
            color: $mainTextClr;
            line-height: normal;
            &:hover {
                text-decoration: unset;
            }
        }
        ul {
            &:first-child {
                span {
                    color: #a8a8a8;
                    margin-right: 1.92rem;
                }
                li {
                    text-align: right;
                }
            }
            &:last-child {
                span {
                    color: #353535;
                }
                div {
                    &:last-child {
                        span:first-child {
                            color: #a8a8a8;
                            margin-right: 1.125rem;
                        }
                    }
                }
            }
            li {
                &:not(:last-child) {
                    margin-bottom: 1.875rem;
                }
                span {
                    font-size: 1rem;
                    font-weight: bold;
                }
                
            }
        }
    }
}

// Start Form
.personal-info {
    h2 {
        margin-bottom: 1.5625rem;
    }
    &__form {
        background-color: #fff;
        padding: 1.25rem 1.5rem 1.5rem 1.5rem;
        @media screen and (min-width: 768px) {
            padding: 1.875rem 2.8125rem 3.75rem 1.875rem;
        }
        .label {
            label {
                font-size: 1rem;
                font-weight: bold;
                line-height: normal;
                color: #353535;
            }
        }
        .form-group-wrapper {
            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }
        .form-group {
            label {
                font-weight: bold;
                color: #353535;
            }
            input {
                font-size: 1rem;
                font-weight: bold;
                border-radius: 3px;
                border: solid 1px #e7e7e7;
                @media screen and (min-width: 992px) {
                    width: 17.5rem;
                }
                &:focus {
                    outline: none;
                    box-shadow: none;
                    border-color: #e7e7e7;
                }
            }
        }
        .form-group--error {
            label {
                color: #fe3636;
            }
            input.form-control {
                border: solid 1px #fe3636;
            }
        }
        a {
            font-size: 1rem;
            font-weight: bold;
            color: $mainTextClr;
            &:hover {
                text-decoration: unset;
            }
        }
        input[type="submit"] {
            font-weight: bold;
            font-size: 1rem;
            text-align: left;
            color: #fff;
            background-color: $mainTextClr;
            border:0;
            border-radius: 0;
            height: 3.375rem;
            background-image: url('assets/images/arrow.svg');
            background-repeat: no-repeat no-repeat;
            background-position: right 1.125rem center;
            width: 10.5rem;
            margin-left: 1rem;
            @media screen and (min-width: 992px) {
                width: 11.25rem;
                padding-left: 2rem;
            }
        }
    }
}