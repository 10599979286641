.contacts {
    margin-top: 2.4375rem;
    .contacts__item {
        min-height: 10rem;
        background-color: #ffffff;
        border-left:2px solid $mainTextClr;
        padding: 1rem 1.5rem 1rem 1.5rem;
        @media screen and (min-width: 768px) {
            padding: 1rem 3.75rem 1rem 2.875rem;
        }
        .logo {
            font-size: 2.125rem;
            font-weight: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #353535;
            margin-bottom: 0;
        }
        .street {
            @media screen and (min-width: 768px) {
                max-width: 21.875rem;
            }
            p,a {
                font-size: 1.75rem;
                font-weight: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #353535;
                margin-bottom: 0;
            }
            a {
                transition: color 0.3s ease-in-out;
                &:hover {
                    text-decoration: none;
                    color: $mainTextClr;
                }
            }
        }
        .phone {
            p {
                font-size: 1.75rem;
                font-weight: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #353535;
                margin-bottom: 0;
                a {
                    color: #353535;
                    transition: color .25s ease-in-out;
                    &:hover {
                        color: $mainTextClr;
                        text-decoration: unset;
                    }
                }
            }
        }
    }
}

.distributors {
    margin-top: 1.75rem;
    .title {
        h4 {
            font-size: 2.125rem;
            color: #353535;
            margin-bottom: 1.75rem;
        }
    }
    &__wrapper {
        background-color: #ffffff;
        padding: 1.5625rem 2.8125rem 3.25rem 2.1875rem;
        img {
            max-width: 7.1875rem;
            margin-bottom: 2.1875rem;
        }

        @media screen and (max-width: 767px) {
            .distributor__col:not(:nth-child(2)) {
                margin-top: 6.25rem;
            }
        }

        @media screen and (min-width: 768px ) and (max-width: 991px) {
            .distributor__col:not(:nth-child(-n+3)) {
                margin-top: 6.25rem;
            }
        }

        @media screen and (min-width: 992px ) {
            .distributor__col:not(:nth-child(-n+4)) {
                margin-top: 6.25rem;
            }
        }

        &__item {
            @media screen and (min-width: 768px) {
                max-width: 21.5625rem;
            }
           
            &__title {
                font-size: 1.55rem;
                line-height: normal;
                letter-spacing: normal;
                color: #353535;
                border-bottom: 2px solid $mainTextClr;
                padding-bottom: 0.75rem;
                margin-bottom: 0.75rem;
            }
            &__content {
                ul {
                    li {
                        font-size: 1.25rem;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #353535;
                        a {
                            color: #353535;
                            transition: color 0.3s ease-in-out;
                            &:hover {
                                text-decoration: none;
                                color: $mainTextClr;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px) {
    .reCaptchaPadding div:first-of-type{
        padding-right: 80px;
        transform: scale(0.98);
    }
}
