.terms {
    margin-top: 1.4375rem;
    a {
        color: $mainTextClr;
        &:hover {
            text-decoration: unset;
        }
    }
    &__list {
        list-style: none;
        color: #444;
        padding-left: 1.875rem;
        counter-reset: my-awesome-counter;
        h6 {
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.38;
        }
        p {
            font-weight: normal;
            margin-bottom: 1.2rem;
            b {
                font-weight: bold;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        li {
            position: relative;
            &::before {
                counter-increment: my-awesome-counter; 
                content: counter(my-awesome-counter) ". ";
                left: -1.3rem;
                line-height: 1.38;
                top:0;
                position: absolute;
                font-weight: bold;
                font-size: 1rem;
                color: #444;
            }
            &:nth-child(n+10) {
                &::before {
                    left: -1.8rem;
                }
            }
            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }
        }
    }
}