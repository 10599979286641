.desc-text {
    h1,h2,h3,h4,h5,h6 {
        font-size: 1rem;
        line-height: normal;
        color: #353535;
        font-weight: bold;
    }
    p, ol, ul, li {
        color: #444444;
    }
    li {
        line-height: 1.38;
    }
    a {
        color: $mainTextClr;
        text-decoration: underline;
    }
    &.or-text {
        span {
            height: 2px;
            display: block;
            background: #a8a8a8;
            width: 8rem;
            &:first-child {
                margin-right: .7rem;
            }
            &:last-child {
                margin-left: .7rem;
            }
            @media screen and (min-width: 768px) {
            }
        }
    }
}
.items-group {
    &__table {
        table {
            tbody {
                td {
                    input {
                        height: 2.1875rem;
                        border-radius: 3px;
                        border: solid 1px #e7e7e7;
                        &:focus {
                            outline: none;
                            box-shadow: none;
                            border: solid 1px #e7e7e7;
                        }
                    }
                    &:nth-child(4) {
                        input {
                            max-width: 5.625rem;
                        }
                    }
                    &:nth-child(5) {
                        input {
                            max-width: 24.25rem;
                        }
                    }
                }
            }
        }
    }
}

.saved-parts-form {
    background-color: #fff;
    padding: 1.25rem 1.25rem 2.375rem 1.75rem;
    &--lg {
        .saved-parts-form-col {
            @media screen and (min-width: 768px) {
                &:not(:first-child) {
                    margin-left: 1.5rem;
                }
            }

            @media screen and (min-width: 992px) {
                &:not(:first-child) {
                    margin-left: 10rem;
                }
            }
            
            label {
                font-size: 1.75rem;
                font-weight: normal;
                line-height: normal;
            }
        }
    }
    &-col {
        &:not(:first-child) {
            @media screen and (min-width: 768px){
                margin-left: 4.375rem;
            }
        } 
        input {
            height: 2.1875rem;
        }
        label {
            font-size: 1rem;
            font-weight: bold;
            line-height: normal;
            color: #353535;
        }
        input:not([type=submit]), textarea {
            font-size: 1rem;
            font-weight: bold;
            border-radius: 3px;
            border: solid 1px #e7e7e7;
            resize: none;
            width: 100%;
            @media screen and (min-width: 768px) {
                width: 13.6rem;
            }
            @media screen and (min-width: 992px) {
                width: 17.5rem;
            }
            &:focus {
                outline: none;
                box-shadow: none;
                border: solid 1px #e7e7e7;
            }
        }
        .items-group__captcha {
            .form-group {
                label {
                    color: #353535;
                }
                input {
                    margin-right: 0.5rem;
                    width: 7.9rem;
                    @media screen and (min-width: 768px) {
                        width: 8.125rem;
                    }
                }
            }
        }
        button.btn-primary {
            border-radius: 0;
            text-align: left;
            width: 14rem;
            @media screen and (min-width: 768px) {
                width: 17.5rem;
                padding-left: 2rem;
            }
        }
        @media screen and (max-width: 767px) {
            .form-group-wrapper {
                width: 100%;
                .form-group {
                    width: 100%;
                }
            }
        }
    }
}

.required-error{
    border: 1px solid #fe3636!important;
}

.requestTableFullWidth{
    width: 100%;
}

.reCaptchaRequestPadding{
    margin-right: -2px;
}
