.other-categories {
    margin-top: 1rem;
    &__shortName {
        display: none;
    }
    &.sticky {
        @media screen and (max-width: 1199px) {
            position: fixed;
            top: 95px;
            z-index: 1030;
            width: 100%;
            left:0;
            right:0;
            margin-left: auto;
            margin-right: auto;
            height: 66px;
            margin-top: 0;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            background-color: #eaeaea;
        }
        button {
            background-color: transparent;
            border:0;
            &.filtering-btn {
                background-color: transparent;
                border:0;
            }
        }
        .cat-title {
            display: none;
        }
        .other-categories__shortName {
            display: inline
        }
        .other-categories__fullName {
            display: none;
        }
    }
    h1 {
        font-size: 2.125rem;
        font-weight: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5a5a5a;
    }
    button {
        line-height: normal;
        border-radius: 0;
        border: solid 0.0625rem #e5e5e5;
        background-color: #ffffff;
        padding: 0.8125rem 0.75rem 0.9375rem 0.75rem;
        &:focus {
            box-shadow: unset;
        }
        span {
            font-weight: bold;
            color: $mainTextClr;
        }
        
    }
    .dropdown-menu {
        @include scrollBar;
        @include dropdown;
      //  height: 19.0625rem;
        height: 24.800rem;
        z-index: 1100;
        // transform: translate3d(0px, 60px, 0px)!important;
        &.show {
            position: absolute;
        }
    }
}

// Start Filters
.filters {
    .clear-filters {
        font-size: 0.9rem;
        font-weight: 600;
        background-color: $mainTextClr;
        color: #fff;
        position: relative;
        padding: 0.5rem 1rem;
        border: 1px solid $mainTextClr;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        z-index: 900;
        @media screen and (max-width: 1199px) {
            position: absolute;
            width: 100%;
            left: 0;
            top: -1px;
            height: 52px;
            // border:0;
            // &:hover {
            //     border:0;
            // }
        }
        svg {
            margin-left: 13px;
            pointer-events: none;
            polygon {
                color: $mainTextClr !important;
            }
        }
        &:hover {
            background-color: transparent;
            box-shadow: unset;
            color: $mainTextClr;
            svg {
                polygon {
                    fill: $mainTextClr !important
                }
            }
        }
        &:focus {
            background-color: transparent;
            box-shadow: unset;
            color: $mainTextClr;
            svg {
                polygon {
                    fill: $mainTextClr !important
                }
            }
        }
    }
    .close-sidenav {
        position: absolute;
        right: -2.5rem;
        top: 0.3125rem;
        z-index: 11;
    }
    &.show {
        .filters__form {
            overflow: auto;
            height: 100vh;
        }
    }
    &__form {
        border-radius: 0.1875rem;
        border: solid 0.0625rem #e5e5e5;
        background-color: #ffffff;
        font-weight: bold;
        color: $mainTextClr;
        @media screen and (max-width: 1199px) {
            .clear-btn-exist {
                margin-top: 52px;
            }
        }
        &__item {
            padding: 0.8125rem 0.75rem 0.9375rem 0.75rem;
            .collapse {
                max-height: 20rem;
                overflow-y: auto;
                @include scrollBar;
            }
            position: relative;
            &:not(:first-child) {
                &:before {
                    content: '';
                    position: absolute;
                    left:0;
                    top:0;
                    width: 100%;
                    height: 1px;
                    z-index: 2;
                    background-color: #e5e5e5;
                }
            }
            a {
                color: #353535;
                font-weight: bold;
                line-height: normal;
                padding-right: 3.6rem;
                display: inline-block;
                position: relative;
                &:hover {
                    text-decoration: none;
                }
                &[aria-expanded="true"] {
                    &::after {
                        border-top: 0;
                        border-left: 0.4em solid transparent;
                        border-right: 0.4em solid transparent;
                        border-bottom: 0.4em solid;
                    }
                }
                &::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border-top: 0.4em solid;
                    border-right: 0.4em solid transparent;
                    border-bottom: 0;
                    border-left: 0.4em solid transparent;
                    color: $mainTextClr;
                }
            }
            .card.card-body {
                border: 0;
                border-radius: 0;
                padding: 0 0;
                margin-top: 1.125rem;
                margin-bottom: 0.5rem;
                .custom-control-input:checked~.custom-control-label::before {
                    border-color: $mainTextClr;
                    background-color: $mainTextClr;
                }
                .custom-control-input:focus~.custom-control-label::before {
                    box-shadow: none;
                }
                .custom-control-input:not(:disabled):active~.custom-control-label::before {
                    border-color: $mainTextClr;
                    background-color: $mainTextClr;
                }
                label {
                    &:hover {
                        cursor: pointer;
                        &::before {
                            background-color: #f1f1f1;
                        }
                    }
                    &.custom-control-label {
                        font-size: 0.875rem;
                        font-weight: normal;
                        line-height: 2.14;
                        color: #3b3b3b;
                        &::before {
                            border-radius: 0;
                            width: 0.9375rem;
                            height: 0.9375rem;
                            border: 1px solid $mainTextClr;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1199px) {
        padding: 0;
        &__form {
            border: 0;
            &__item {
                a {
                    padding-right: 8.6rem;
                    @media screen and (max-width: 768px) {
                        padding-right: 5rem;
                    }
                }
            }
        }
        .collapse {
            // z-index: 1033;
        }
        &.collapsing {
            top: 2px;
            left: 0;
            position: fixed;
            z-index: 1033;
            height: 100vh;
            border-radius: 3px;
            border: solid 1px #e5e5e5;
            background-color: #ffffff;
            width: 0;
            transition: all 0.15s ease-in-out;
            ul {
                margin-top: 4.375rem;
            }
        }
        &.collapse.show {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            top: 2px;
            left: 0;
            position: fixed;
            height: 100vh;
            border-radius: 3px;
            border: solid 1px #e5e5e5;
            background-color: #ffffff;
            z-index: 1033;
            width: 250px;
            transition: all 0.15s ease-in-out;
            @media screen and (min-width: 768px) {
                width: 328px;
            }
        }
    }
}

.info-link-details{
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    text-indent: -9999px;
    overflow: hidden;
    background: url(http://digitron-dev.bitsorchestra.com/Digitron/media/Digitron/info-icon.svg) no-repeat;
}

.scrollingcontent .scrollingcontentblock .PagerControl{
    display: none;
}