nav[aria-label="breadcrumb"] {
    border-top: 0;
    ol.breadcrumb {
        background-color: transparent;
        border-radius: 0;
        padding: 1rem 1rem;
        li {
            font-size: 1rem;
            &.active {
                color: #444;
            }
            &:before {
                color: #444;
            }
            .dropdown-menu {
                @include scrollBar;
                @include dropdown;
                height: 19.0625rem;
            }
            a {
                font-size: 1rem;
                color: $mainTextClr;
                display: inline-flex;
                align-items: center;
                svg {
                    width: 9px;
                    height: 9px;
                }
            }
        }
        a {
            font-size: 1rem;
            color: $mainTextClr;
            display: inline-flex;
            align-items: center;
            svg {
                width: 9px;
                height: 9px;
            }
            padding-right: 5px;
            &:not(:first-child) {
                padding-left: 5px;
            }
           
        }

        span {
            font-size: 1rem;
            display: inline-flex;
            align-items: center;
            padding-left: 5px;
            &.active {
                color: #444;
            }
        }
        
    }
}