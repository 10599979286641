input[type=date] {
    background: url('assets/images/calendar.png') no-repeat;
    background-position: 7px center;
    padding-left: 2.5rem;
}
.rma-table {
    @media screen and (min-width: 768px) {
        margin: 6rem 0;
    }
    .items-group__table__row-header {
        border-top: 0;
        border-left:0;
        border-right: 0;
        border-bottom: 2px solid $mainTextClr;
        border-radius: 0;
        h6 {
            font-size: 0.875rem;
            font-weight: normal;
            color: #353535;
        }
    }
}

.rma-table {
    .items-group__table__row-body:nth-of-type(odd) {
        background-color: transparent !important;
    }
    button {
        border-radius: 0;
        border: solid 1px #bf232a;
        background-color: #bf232a;
        color: #fff;
        font-weight: bold;
        border: 0;
        border-radius: 0;
        &:focus {
            background-color: #bf232a;
            color: #fff;
            outline: none;
            box-shadow: unset;
        }
    }
    .trash {
        svg {
            width: 20px;
            height: auto;
            margin: auto;
            path {
                pointer-events: none;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
    .trash-mobile {
        svg {
            width: 20px;
            height: auto;
            margin: auto;
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.upload-file {
    background-color: #ffffff;
    padding: 1.875rem 1.5625rem 2.5rem 1.5625rem;
    h2 {
        font-size: 1.75rem;
        color: #353535;
        line-height: normal;
    }
    &__area {
        border: 1px dashed rgba($mainTextClr, 0.4);
        &__content {
            padding: 1.75rem 1.25rem;
            h4 {
                font-size: 1rem;
                color: #444444;
                font-weight: bold;
                line-height: normal;
                letter-spacing: normal;
                label {
                    color: $mainTextClr;
                    text-decoration: underline;
                    margin-bottom: 0;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            position: relative;
            input[type="file"] {
                position: absolute;
                left:0;
                right:0;
                top:0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }
    }
}

.submit-rma {
    .rma-request-submit {
        border-radius: 0;
        border: solid 1px #bf232a;
        background-color: #bf232a;
        color: #fff;
        font-weight: bold;
        width: 15rem;
        padding-left: 1rem;
        border:0;
        border-radius: 0;
        height: 3.375rem;
        background-image: url('assets/images/arrow.svg');
        background-repeat: no-repeat no-repeat;
        background-position: right 1.125rem center;
        margin-left: auto;
        @media screen and (min-width: 576px) {
            width: 17.5rem;
            padding-left: 2rem;
        }
        &.loading {
            background-image: url('assets/images/loader.gif');
            background-size: 35px 35px;
            background-repeat: no-repeat no-repeat;
            background-position: right 1.125rem center;
        }
    }
    
}

.upload-saved-parts {
    p {
        font-size: 1rem;
        color: #444;
        font-weight: 700;
        line-height: normal;
        letter-spacing: normal;
        a {
            color: #bf232a;
            text-decoration: underline;
            &:hover {
                color: #bf232a;
            }
        }
    }
}


.suggestions {
    position: absolute;
    left:0;
    top: 38px;
    width: 100%;
    border: 0;
    border-radius: 0;
    padding: 0.8125rem 0.75rem 0.9375rem 0.75rem;
    box-shadow: 0 5px 10px 0 rgba(245, 0, 11, 0.17);
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 10;
    li {
        font-weight: bold;
        color: #353535;
        transition: all 0.3s ease-in-out;
        &:hover {
            color: $mainTextClr;
            cursor: pointer;
        }
    }
}
.empt-title {
    padding-top: 20px; 
}