.quotes {
    &__title {
        padding: 1.25rem 1.875rem 1.875rem 1.875rem;
        background-color: $mainTextClr;
        color: #fff;
        height: 100%;
        h3 {
            font-size: 1.75rem;
            font-weight: normal;
            line-height: normal;
        }
        h5 {
            font-size: 1rem;
            font-weight: bold;
            line-height: normal;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__text {
        height: 100%;
        background-color: #fff;
        padding: 1.25rem 2.5rem 1.5rem 3.125rem;
        p {
            display: inline-block;
            position: relative;
            &:first-child {
                &::before {
                    content: url('assets/images/quote-up.svg');
                    position: absolute;
                    left:-40px;
                    top: 0;
                }
            }
            &:last-child {
                &::after {
                    content: url('assets/images/quote-down.svg');
                    position: absolute;
                    right: -40px;
                    bottom: -10px;
                }
            }
        }
        * {
            font-size: 1rem;
            color:#444444;
            line-height: 1.38;
        }
    }
}

.cards {
    &-col {
        &:nth-child(even) {
            .card--question {
                margin-left: auto;
            }
        }
    }
}
.form-questions {
    &-no {
        font-size: 1.75rem;
        color: #353535;
        font-weight: normal;
        line-height: normal;
        margin-right: 0.8rem;
        @media screen and (min-width: 992px){
            margin-right: 1.875rem;
        }
    }
    .card {
        &.card--question {
            max-width: 100%;
            width: 100%;
            padding: 1rem 1.1875rem 0.8125rem 1.1875rem;
        }
        &__question {
            margin-left: 0;
        }
    }
    &:nth-child(even) {
        .card {
            background-color: #f4f4f4;
        }
    }
}

.card {
    @import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css');
    transition: .25s ease-in-out;
    &.card--question {
        max-width: 36.25rem;
        min-height: 7.1875rem;
        border:0;
        border-radius: 0;
        padding: 1.5625rem 2.1875rem 1.5625rem 2.1875rem;
        margin-bottom: 0.75rem;
    }
    &.card--active {
        box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17);
        transition: .25s ease-in-out;
        svg {
            transition: .25s ease-in-out;
            path {
                transition: .25s ease-in-out;
                stroke: #444;
            }
        }
    }
    &__question {
        @media screen and (min-width: 992px) {
            margin-left: 2rem;
        }
        h4 {
            font-size: 1.75rem;
            font-weight: normal;
            line-height: normal;
            color: #353535;
            margin-bottom: 1.25rem;
        }
        &__answers {
            span.mark-range {
                font-size: 1rem;
                font-weight: bold;
                line-height: normal;
                color: #353535;
            }
            &__form {

                .rating-group {
                    span {
                        display: inline-flex;
                        margin: 0 0.5rem;
                    }

                    .rating__icon {
                        pointer-events: none;
                    }
                    
                    input[type="radio"] {
                        position: absolute !important;
                        left: -99999px !important;
                        visibility: hidden;
                    }

                    .rating__input--none {
                        display: none
                    }

                    label {
                        cursor: pointer;
                        padding: 0 0.5rem;
                        font-size: 1.6875rem;
                        margin-bottom: 0;
                        transition: .2s ease-in-out;
                    }
                    .clear-form  .rating__icon--star{
                        -webkit-text-stroke: 1px $mainTextClr;
                        color: transparent;
                    }
                    
                    .rating__icon--star {
                        color: $mainTextClr;
                        transition: .2s ease-in-out;
                    }
                    
                    
                    input[type="radio"]:checked ~ label .rating__icon--star {
                        -webkit-text-stroke: 1px $mainTextClr;
                        color: transparent;
                    }

                   @media screen and (min-width: 1025px) {
                        label:hover .rating__icon--star {
                            -webkit-text-stroke: 1px $mainTextClr;
                            color: rgba(191, 35, 42, 0.26) !important;
                        }
                   }
                }
            }       
        }
    }
}

.radio-form {
    margin-top: 11.25rem;
    &-card {
        background-color: #fff;
        padding: 1rem 1.1875rem 1.6875rem 1.1875rem;
        h4 {
            font-size: 1.75rem;
            font-weight: normal;
            line-height: normal;
            color: #353535;
            margin-bottom: 1.25rem;
        }
        .custom-control {
            &:first-child {
                margin-right: 2.5rem;
            }
        }
        label {
            font-size: 1rem;
            font-weight: bold;
            line-height: normal;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            color: #353535;
            &:hover {
                cursor: pointer;
            }
            &:focus {
                box-shadow: unset;
                outline: none;
            }
            &::before {
                width: 1.125rem;
                height: 1.125rem;
                background-color: transparent;
                border: 1px solid $mainTextClr;
            }
            &::after {
                top: 7px;
                left: -21px;
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 50%;
            }
        }
        input[type="radio"] {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
        input[type="radio"]:focus ~ label::before {
            box-shadow: unset;
        }
        input[type="radio"]:not(:disabled):active ~ label::before {
            background-color: transparent;
            border-color: $mainTextClr;
        }
        input[type="radio"]:checked ~ label {
            &::after {
                background-image: unset;
                background-color: $mainTextClr;
            }
            &::before {
                border-color: $mainTextClr;
                background-color: transparent;
            }
        }
    }
}

.extra-form {
    margin-top: 5.625rem;
    label {
        font-size: 2.125rem;
        color: #353535;
        line-height: normal;
        margin-bottom: 1rem;
    }
    textarea {
        font-size: 1rem;
        color: #353535;
        border-radius: 3px;
        border: solid 1px #e7e7e7;
        background-color: #ffffff;
        max-width: 56.25rem;
        height: 80px;
        resize: none;
        &:focus {
            outline: none;
            box-shadow: none;
            border: solid 1px #e7e7e7;
        }
    }
}

.submit-button input[type="submit"]{
    font-weight: 700;
    font-size: 1rem;
    text-align: left;
    color: #fff;
    background-color: #bf232a;
    border: 0;
    border-radius: 0;
    padding: .77rem;
    height: 3.375rem;
    background-image: url(assets/images/arrow.svg);
    background-repeat: no-repeat;
    background-position: right 1.125rem center;
    width: 15rem;
}

.ErrorLabel{
    display: none;
}