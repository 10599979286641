.seacrh-results {
    h1 {
        font-size: 2.125rem;
        font-weight: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5a5a5a;
        margin-bottom: 2.8125rem;
    }

    .filtering-btn {
        padding: 0.8125rem 0.75rem 0.9375rem 0.75rem;
    }
    // Search form
    .search-form-for {
        margin-bottom: 2.0625rem;

        label {
            font-size: 0.875rem;
            color: #3b3b3b;
            line-height: normal;
            padding-right: 0.875rem;
        }

        .input-group {
            width: auto;
            border-radius: 3px;
            border: solid 1px #e7e7e7;

            .input-group-prepend,
            .input-group-append {
                background-color: #fbfbfb;

                .input-group-text {
                    background-color: #fbfbfb;
                    border: 0;
                    outline: unset;
                    padding: 0.375rem 0.375rem;
                }
            }

            input.form-control {
                width: 13rem;

                @media screen and (min-width: 768px) {
                    width: 17.25rem;
                }

                border: 0;
                font-weight: bold;
                color: #353535;
                line-height: normal;
                padding-left: 0;
                background-color: #fbfbfb;

                &::placeholder {
                    font-weight: bold;
                    line-height: normal;
                    color: #353535;
                }

                &:focus {
                    border-color: #e7e7e7;
                    box-shadow: unset;
                }
            }

            .search-dialog {
                .form-horizontal > .form-group {
                    margin: 0;
                }

                .form-group-submit, .editing-form-label-cell {
                    display: none;
                }
            }
        }

        .search-form-for {
            .dropdown {
                @include customDropdown;
                border: solid 1px #e7e7e7;
                border-radius: 3px;
                margin-top: 0;

                @media screen and (max-width: 767px) {
                    width: 15.3125rem;
                }

                @media screen and (min-width: 768px) {
                    margin-top: 0;
                    width: 19.5rem;
                }

                ;

                button {
                    font-size: 1rem;
                    font-weight: bold;
                    color: #353535;
                    padding: 0.53125rem 0.53125rem;
                    width: 100%;
                    background-color: #fbfbfb;
                    position: relative;

                    @media screen and (min-width: 992px) {
                        font-size: 1rem;
                    }

                    ;

                    &[aria-expanded="true"] {
                        &::after {
                            transform: rotate(180deg);
                            right: 15px;
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        display: block;
                        right: 10px;
                        top: 50%;
                        transform: translate(-50%);
                        color: #8d8d8d;
                        border-top: 0.3em solid;
                        border-right: 0.3em solid transparent;
                        border-bottom: 0;
                        border-left: 0.3em solid transparent;
                    }
                }

                .dropdown-menu {
                    @media screen and (max-width: 767px) {
                        width: 15.3125rem;
                    }
                    // height: unset;
                    width: 19.375rem;
                }
            }
            // Start Select
            select {
                width: 15.3125rem;
                height: 2.5rem;
                font-weight: bold;
                color: #353535;
                padding: 0.375rem 0.375rem;
                border-radius: 3px;
                border: solid 1px #e7e7e7;
                background-color: #fbfbfb;
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                position: relative;
                background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                background-repeat: no-repeat;
                background-position: 13.5rem center;

                @media screen and (min-width: 768px) {
                    width: 19.5625rem;
                    background-position: 17.8125rem center;
                }

                &:focus {
                    outline: none;
                }

                option {
                    font-weight: bold;
                    color: #353535;
                    background-color: #fbfbfb;
                }
            }
        }
    }

    .search-filters {
        @include lightBgAndBorder;

        @media screen and (max-width: 767px) {
            display: flex;
            justify-content: center;
            align-items: center;

            .custom-control {
                display: flex;
                align-items: center;

                &:not(:first-child) {
                    margin-left: 1rem;
                }
            }

            margin-bottom: 1rem;
        }

        @media screen and (min-width: 992px) {
            max-width: 10rem;

            tr:not(:first-child) label {
                margin-top: 1.75rem;
            }

            tr input[type=checkbox] {
                display:none;
            }
        }

        .custom-control {
            padding-left: 0;

            input {
                display: none;
            }

            @media screen and (min-width: 992px) {
                &:not(:first-child) {
                    margin-top: 1.75rem;
                }
            }
        }

        label {
            font-size: 1rem;
            font-weight: bold;
            color: #353535;
            line-height: normal;

            &:hover {
                cursor: pointer;
            }

            &:before {
                display: none;
            }

            &:after {
                display: none;
            }

            &.active {
                color: $mainTextClr;
            }
        }
    }
    // Start Single Search Result
    .single-result {
        &:not(:first-child) {
            margin-top: 3.75rem;
        }

        &__title {
            a {
                font-size: 1rem;
                color: $mainTextClr;
                font-weight: bold;
                line-height: normal;

                &:hover {
                    color: $mainTextClr;
                    text-decoration: unset;
                }
            }
        }

        &__text {
            p {
                font-size: 0.875rem;
                font-weight: normal;
                line-height: 1.29;
                color: #353535;

                span {
                    background-color: #ffec00;
                }
            }
        }

        &__info {
            @media screen and (max-width: 767px) {
                justify-content: space-between;
                flex-wrap: wrap;

                &-progress {
                    flex: 0 43%;
                    order: 1;
                }

                &-url {
                    flex: 0 100%;
                    order: 3;
                    margin-top: 1rem;
                    * {
                        font-size: 0.8rem;
                    }
                }

                &-date {
                    display: none;
                }
            }

            &-progress {
                .progress {
                    width: 3.625rem;
                    border-radius: 0;
                    background-color: #f0f0f0;
                    border: 1px solid $mainTextClr;

                    &-bar {
                        background-color: $mainTextClr;
                    }
                }

                margin-right: 0.625rem;
            }

            &-url {
                a {
                    font-weight: bold;
                    line-height: normal;
                    color: $mainTextClr;

                    &:hover {
                        text-decoration: unset;
                    }
                }

                margin-right: 1.125rem;
            }

            &-date {
                p {
                    font-weight: bold;
                    line-height: normal;
                    color: #a8a8a8;
                }
            }
        }
    }

    .search-form + .single-result {
        margin-top: 0;
    }
}

#p_lt_ctl01_pageplaceholder_p_lt_ctl01_SmartSearchDialog_srchDialog_lblSearchFor {
    display:none;  
}
 
.input-group-prepend {
    background-color: rgba(0, 0, 0, 0)!important;
    display:block;
}


nav.navbar .search-form-for .input-group .searchBox *, nav.navbar .search-form .input-group .searchBox .predictiveSearchHolder{
    display: block!important;
}
.predictiveSearchResults{
    left: -30px;
    width: 233px!important;
}
.predictiveSearchResults>* {
    color: #bf232a!important;
    padding: 3px 4px;
}
.predictiveSearchResults a:hover{

    color: #bf232a!important;
}
