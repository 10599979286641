// Company Description
.company-desc {
    img {
        width: 100%;
        max-width: 19.0625rem;
    }
    h1 {
        font-size: 1.75rem;
        font-weight: normal;
        color: #353535;
        line-height: normal;
        text-shadow: 0 0 15px rgba(255, 255, 255, 0.85);
        span {
            color: $mainTextClr;
        }
    }
    margin-top: 1rem;
    margin-bottom: 2rem;
}

// Showcase
.showcase {
    
    .text-info-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-color: $mainTextClr;
        height: 100%;
        color: #fff;
        padding: 1.375rem 2.125rem 1.125rem 1.875rem;
        h1 {
            font-size: 2.125rem;
            font-weight: normal;
            line-height: normal;
            margin-bottom: 1.5rem;
        }
        h6 {
            font-size: 1rem;
            font-weight: bold;
            line-height: normal;
            margin-bottom: 0;
        }
    }
    .carousel {
        .image-info-item {
            height: 100%;;
            img {
                height: 7.5rem;
                object-position: top;
                object-fit: cover;
                @media screen and (min-width: 576px) {
                    height: 12rem;;
                }
                @media screen and (min-width: 768px) {
                    height: 15rem;
                }
                @media screen and (min-width: 1200px) {
                    object-position: top;
                    height: 20rem;
                }
            }
        }
        .carousel-indicators {
            li {
                width: 6px;
                height: 6px;
                border: 1px solid $mainTextClr;
                background-color: transparent;
                opacity: 1;
                &.active {
                    background-color: $mainTextClr;
                }
            }
        }
    }
    padding-bottom: 2.8125rem;
}
// All products
.all-products {
    background-color: #f2f2f2;
    padding: 3rem 0;
    
    h2 {
        color: #353535;
        font-size: 2.125rem;
    }

    .product {
        @media screen and (min-width: 992px) and (max-width: 1199px) {
            width: 100% !important;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        .product-col:nth-child(2n) {
            .product {
                margin-left: auto;
            }
        }
    }
    @media screen and (min-width: 1200px) {
        .product-col:nth-child(3n) {
            .product {
                margin-left: auto;
            }
        }
        .product-col:nth-child(3n+2) {
            .product {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .product {
        @include customDropdown;
        @media screen and (min-width: 768px) and (max-width: 991px) {
            max-width: 28rem !important;
            width: unset !important;
        }
        a.link-to-products {
            color: #353535;
            text-decoration: unset;
            transition: color 0.3s ease-in-out;
            padding: 0.375rem 0.875rem;
            z-index: 10;
            &:hover {
                color: $mainTextClr;
                text-decoration: unset;
            }
        }
        .dropdown-menu {
            background-color: hsla(0,0%,100%, 1);
        }
        // New Dropdown Type
        &.btn-group.dropdown {
            .link-to-product-page {
                font-size: 1.4rem;
                font-weight: normal;
                line-height: normal;
                background-color: #ffffff;
                color: #353535;
                border: 0;
                width: 100%;
                border-radius: 0;
                padding: 0 0;
                transition: all .25s ease-in-out;
                display: flex;
                align-items: center;
                white-space: normal;
                text-align: left;
                text-decoration: unset;
                background-color: #fff;
                padding: 0.375rem 0.875rem;
                &:hover {
                    color: $mainTextClr;
                }
                @media screen and (min-width: 992px) {
                    font-size:1.75rem;
                }
            }
            .dropdown-toggle-split {
                height: 5rem;
                width: 6.5625rem;
                transition: all .25s ease-in-out;
                display: flex;
                justify-content: center;
                &[aria-expanded="true"] {
                    background-color: #bf232a;
                }
                &:hover {
                    background-color: #bf232a;
                    cursor: pointer;
                    svg {
                        path {
                            stroke: #fff;
                        }
                    }
                }
            }
        }
    }
}

// Posts
.posts {
    padding-top: 6.875rem;
}

// News
.news {
    margin-top: 5rem;
    &__read-more {
        color: $mainTextClr;
        border: solid 1px $mainTextClr;
        background-color: #ffffff;
        font-weight: bold;
        width: 100%;
        padding: 1rem 1rem 1rem 1.375rem;
        transition: all .25s ease-in-out;
        span {
            padding-right: 1rem;
            @media screen and (min-width: 768px){
                padding-right: 3.125rem;
            }
        }
        &:hover {
            background-color: $mainTextClr;
            color: #fff;
            text-decoration: unset;
            svg {
                path {
                    stroke: #fff;
                }
            }
        }
    }
}