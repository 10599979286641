nav {
    border-top: 2px solid $mainTextClr;
    
    @media screen and (min-width: 1200px) {
        .search-form.active {
            width: 94.6%;
            .input-group {
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .search-form.active {
            width: 100%;
            .input-group {
                width: 100%;
            }
        }
    }
    &.navbar.navbar-home {
        box-shadow: 0 2px 11px 0 rgba(191, 35, 42, 0.2);
        &.sticky {
            box-shadow: 0 2px 11px 0 rgba(191, 35, 42, 0.2);
        }
        @media (min-width: 992px) {
            .navbar-toggler {
                display: none !important;
            }
        }
        background-color: #ffffff;
        .row {
            width: 1440px;
        }
        @media screen and (max-width: 991px) {
            .search-form.active {
                width: 100%;
                .input-group {
                    width: 100%;
                }
            }
        }
        @media screen and (min-width: 992px) {
            .search-form.active {
                width: 90%;
                .input-group {
                    width: 100%;
                }
            }
        }
        @media screen and (min-width: 768px) {
            box-shadow: unset;
            background-color: #fbfbfb;
        }
        ul {
            margin-left: auto;
            margin-right: auto;
            width: unset;
        }
        .search-form {
            @media screen and (min-width: 1200px) {
                background: #fbfbfb;
                &.active {
                    background-color: #fff;
                }
            }
            @media screen and (min-width: 992px) {
                position: absolute;
                right: 3.125rem;
            }
        }
        .collapse.navbar-collapse {
            @media screen and (min-width: 992px) {
                ul {
                    li {
                        &.nav-item:not(:last-child) {
                            margin-right: 3.5rem;
                        }
                    }
                }
            }

            @media screen and (min-width: 992px) and (max-width: 1199px) {
                ul {
                    li {
                        &:not(:last-child) {
                            margin-right: 2rem;
                        }
                        &.nav-item {
                            &.dropdown {
                                &>.dropdown-toggle:active {
                                    pointer-events: auto;
                                }
                            }
                        }
                    }
                    .dropdown {
                        .dropdown-menu {
                            display: none;
                        }
                    }
                }
            }

            @media screen and (min-width: 992px) {
                position: relative;
                ul {
                    li {
                        .dropdown-toggle {
                            &::after {
                                display: inline-block;
                            }
                        }
                        &.dropdown {
                            .dropdown-menu {
                                background-color: rgba(255, 255, 255, 0.95);
                                &.show {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.navbar {
        box-shadow: 0 2px 11px 0 rgba(191, 35, 42, 0.2);
        background-color: #ffffff;
        @media screen and (min-width: 1200px) {
            padding-top: 25px;
            padding-bottom: 35px;
        }
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1031;
        width: 100%;
        @media screen and (max-width: 1199px) {
            height: 97.75px;
        }
        &.sticky {
            height: 95px;
            box-shadow: 0 2px 11px 0 rgba(191, 35, 42, 0.2);
            .slogan {
                display: none !important;
            }
            .navbar-brand {
                max-width: 7rem;
                @media screen and (min-width: 1200px) {
                    max-width: 9.875rem;
                }
            }
        }
        .row {
            width: 1440px;
        }
        .navbar-brand {
            max-width: 7.375rem;
            z-index: 1034;
            @media screen and (min-width: 992px) {
                max-width: 12.5625rem;
                z-index: 11;
            }
        }
        .navbar-toggler {
            z-index: 1034;
            &:focus {
                outline: unset;
            }
            &[aria-expanded="false"] {
                svg.opened {
                    display: none;
                }
            }
            &[aria-expanded="true"] {
                svg.opened {
                    display: inline;
                }
                svg.closed {
                    display: none;
                }
            }
        }
        // Slogan
        .slogan {
            h6 {
                font-size: 1.25rem;
                text-shadow: 0 0 15px rgba(255, 255, 255, 0.85);
                font-weight: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #353535;
                margin-left: 3rem;
                // margin-left: 8rem;
                border-left: 2px solid $mainTextClr;
                padding-left: 6px;
                margin-bottom: 1rem;
            }
            
            span {
                color: $mainTextClr;
            }
        }
        .collapse {
            z-index: 1033;
        }
        .collapsing {
            top: 2px;
            left: 0;
            position: fixed;
            z-index: 25;
            height: 100vh;
            border-radius: 3px;
            border: solid 1px #e5e5e5;
            background-color: #ffffff;
            width: 0;
            transition: all 0.15s ease-in-out;
            @media screen and (min-width: 768px) {
                right: 0;
                left: unset;
                ul {
                    margin-left: 0;
                }
            }
            ul {
                margin-top: 4.375rem;
                margin-left: 1rem;
            }
        }
        .collapse.show {
            display: flex;
            align-items: flex-start;
            top: 2px;
            left: 0;
            position: fixed;
            height: 100vh;
            border-radius: 3px;
            border: solid 1px #e5e5e5;
            background-color: #ffffff;
            z-index: 1033;
            width: 100%;
            transition: all 0.15s ease-in-out;
            ul {
                height: 100vh;
                width: 100%;
                overflow-y: auto;
                padding-bottom: 7rem;
                @supports (-webkit-overflow-scrolling: touch) {
                    padding-bottom: 10rem;
                }
            }
            @media screen and (min-width: 768px) {
                &:after {
                    content: '';
                    position: fixed;
                    width: 100%;
                    right: 328px;
                    height: 100vh;
                    top: 0;
                    background-color: rgba(0, 0, 0, 0.42);
                    border-top: 2px solid #bf232a;
                    transition: all 0.15s ease-in-out;
                }
            }
            @media screen and (min-width: 768px) {
                right: 0;
                left: unset;
                width: 328px;
                justify-content: center;
                ul {
                    margin-left: 0rem;
                }
            }
            ul {
                margin-top: 5.375rem;
                // @media screen and (min-width: 768px) {
                    margin-left: 1rem;
                // }
            }
        }
        .collapse.navbar-collapse {
            position: fixed;
            z-index: 1033;
            @media screen and (min-width: 1200px) {
                position: relative;
            }
        }
        ul {
            @media screen and (min-width: 1200px) {
                // margin-left: 8rem;
                margin-left: 3rem;
                justify-content: space-between;
                width: calc(100% - 30rem);
            }
            li.nav-item {
                &.active {
                    a.nav-link {
                        color: $mainTextClr;
                    }
                }
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
                &.dropdown {
                    .dropdown-toggle {
                        &:hover {
                            cursor: pointer;
                        }
                    }
                    &:hover > .dropdown-menu {
                        @media screen and (min-width: 1200px) {
                            display: block;
                        }
                    }

                    & > .dropdown-toggle:active {
                        // pointer-events: none;
                    }
                    // Dropdow toggle icon on small devices
                    @media screen and (min-width: 768px) and (max-width: 1199px) {
                        .dropdown-toggle {
                            &::after {
                                display: none;
                            }
                        }
                    }
                    .dropdown-menu {
                        @include dropdown;
                        width: unset;
                        @media screen and (max-width: 767px) {
                            box-shadow: unset;
                            background-color: transparent;
                        }
                        @media screen and (min-width: 768px) and (max-width: 1199px) {
                            display: block;
                            
                            .dropdown-toggle {
                                &::after {
                                    display: none;
                                }
                            }
                        }
                        box-shadow: none;
                        @media screen and (min-width: 1200px) {
                            box-shadow: 0 5px 10px 0 rgba(245, 0, 11, 0.17);
                        }
                        a.dropdown-item {
                            white-space: nowrap;
                            @media screen and (min-width: 1200px) {
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
                &:first-child {
                    a {
                        padding-left: 0;
                    }
                }
                &:last-child {
                    a {
                        padding-right: 0;
                    }
                }
                &:not(:last-child) {
                    @media screen and (min-width: 1200px) {
                        margin-right: 5rem;
                    }
                }
                &:nth-last-child(2) {
                    @media screen and (min-width: 1200px) {
                        margin-right: 4rem;
                    }
                }
                a {
                    &.nav-link {
                        font-size: 1.09375rem;
                        font-weight: bold;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #353535;
                        transition: all 0.3s ease-in-out;
                        position: relative;
                        @media screen and (min-width: 1200px) {
                            padding:0;
                            &::after {
                                position: absolute;
                                bottom: 7px;
                            }
                            &::before {
                                content: '';
                                position: absolute;
                                width: 100%;
                                transform: scaleX(0);
                                height: 2px;
                                bottom: 0px;
                                left: 0;
                                right: 0;
                                margin: auto;
                                background-color: $mainTextClr;
                                transform-origin: bottom right;
                                transition: transform 0.25s ease-out;
                            }
                            &:hover {
                                &::before {
                                    transform: scaleX(1);
                                    transform-origin: bottom left;
                                }
                            }
                        }
                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                        &:hover {
                            color: $mainTextClr;
                        }
                        &.dropdown-toggle {
                            &::after {
                                vertical-align: 0.1em;
                                color: #8d8d8d;
                            }
                        }
                        @media screen and (min-width: 1200px) {
                            font-size: 1.3rem;
                        }
                        @media screen and (min-width: 1200px) {
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
        // Start Form
        .search-form, .search-form-for {
            background-color: #fff;
            @media screen and (min-width: 1200px) {
                position: absolute;
                right: 3.125rem;
            }
            &.active {
                .input-group {
                    padding-left: 7px;
                    padding-right: 7px;
                    .input-group-prepend {
                        background-color: #fff;
                    }
                    svg {
                        path {
                            stroke: $mainTextClr;
                        }
                    }
                    @media screen and (max-width: 1199px) {
                        .input-group-prepend {
                            border-top: solid 1px #e7e7e7;
                            border-bottom: solid 1px #e7e7e7;
                            border-left: solid 1px #e7e7e7;
                            padding: 0 0.625rem;
                            border-top-left-radius: 3px;
                            border-bottom-left-radius: 3px;
                        }
                        input.form-control {
                            border-top: solid 1px #e7e7e7;
                            border-bottom: solid 1px #e7e7e7;
                            border-right: solid 1px #e7e7e7;
                            border-radius: 3px;
                            width: 100%;
                        }
                        .input-group-append {
                            padding: 0 0.9375rem;
                        }
                    }
                    @media screen and (min-width: 1200px) {
                        border-radius: 3px;
                        border: solid 1px #e7e7e7;
                        svg {
                            path {
                                stroke: #A8A8A8;
                            }
                        }
                    }
                }
                
            }
            .input-group {
                .input-group-prepend,
                .input-group-append {
                    background-color: transparent;
                    .input-group-text {
                        background-color: transparent;
                        border: 0;
                        padding: 0;
                        outline: unset;
                    }
                }
                input.form-control {
                    border: 0;
                    &::placeholder {
                        font-weight: bold;
                        line-height: normal;
                        color: #a8a8a8;
                    }
                    &:focus {
                        border-color: #e7e7e7;
                        box-shadow: unset;
                    }
                }

                .searchBox {
                    position: relative;
                    flex: 1 1 auto;
                    width: 1%;
                    margin-bottom: 0;

                    * {
                        display: none;
                    }
                }
            }
        }
        // Start Account icon
        .account-item {
           @media screen and (min-width: 1200px) {
                position: absolute;
                right:0;
                margin-left: auto;
                z-index: 5;
           }
        }
    }
}

// Mobile Menu 

@media screen and (max-width: 767px) {
    nav.navbar .collapse ul {
        margin-right: 1rem ;
        // margin-top: 1rem !important;
        li.nav-item a.nav-link {
            font-size: 1.4rem;
            border-bottom: 1px solid #dee2e6;
        }
        li.nav-item.dropdown .dropdown-menu a.dropdown-item {
            font-size: 1.25rem;
        }
    }
}
