// .overlay {
//     position: fixed;
//     width: 100%;
//     height: 100vh;
//     background-color: rgba(0, 0, 0, 0.42);
//     z-index: 1031;
//     border-top: 2px solid #bf232a;
//     transition: all 0.15s ease-in-out;
//     visibility: hidden;
//     opacity: 0;
// }
#filtering.show, #cat-links.show {
    transition: all 0.15s ease-in-out;
    &:after {
        content: '';
        position: fixed;
        width: 100%;
        height: 100vh;
        top:0;
        left:250px;
        background-color: rgba(0, 0, 0, 0.42);
        border-top: 2px solid #bf232a;
        transition: all 0.15s ease-in-out;
        @media screen and (min-width: 768px) {
            left:328px;
        }
    }
}