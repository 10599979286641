.preloader {
    position: fixed;
    left:0;
    top:0;
    background-color: #fbfbfb;
    height: 100vh;
    width: 100%;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
    opacity: 1;
    @media screen and (max-width: 767px) {
        img {
            max-width: 25%;
        }
    }
}

.load-filter {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        z-index: 9;
        top:0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(#fff, 0.7);
    }
    &:before {  
        content: '';
        position: absolute;
        top:0;
        left: 0;
        margin: auto;
        z-index: 10;
        background-image: url('assets/images/loading.gif');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50px 50px;
        width: 100%;
        height: 100%;
        min-width: 50px;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}