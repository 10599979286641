.product-info {
    &__title {
        h1 {
            font-size: 1.5rem;
            font-weight: normal;
            font-style: normal;
            font-stretch: condensed;
            line-height: normal;
            letter-spacing: normal;
            color: #5a5a5a;
            @media screen and (min-width: 768px) {
                font-size: 2.125rem;
            }
        }
    }
    &__img {
        @include lightBgAndBorder;
        @media screen and (min-width: 768px) {
            min-height: 17.5rem;
        }
    }
    &__actions {
        @media screen and (min-width: 768px) {
            min-height: 17.5rem;
        }
        a {
            width: 100%;
            &.item-saved {
                img {
                    width: 25px;
                    height: auto;
                }
            }
            &.btn {
                svg {
                    margin-right: 1rem;
                }
            }
            svg {
                pointer-events: none;
            }
            @media screen and (max-width: 767px) {
                &:last-child {
                    width: 45%;
                }
            }
        }
        &__group {
            @include lightBgAndBorder;
            h6 {
                color: #353535;
                line-height: normal;
                letter-spacing: normal;
                font-weight: bold;
                margin-bottom: 0.625rem;
            }
            ul {
                li {
                    &:not(:last-child) {
                        margin-bottom: 0.5625rem;
                    }
                    a {
                        svg {
                            margin-right: 0.6875rem;
                        }
                        font-size: 0.875rem;
                        color: #bf232a;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    &__desc {
        height: 100%;
        @include lightBgAndBorder;
        padding-right: 2rem;
        h6 {
            color: #353535;
            font-weight: bold;
            line-height: normal;
            &:not(:first-child) {
                margin-top: 1;
            }
        }
        article {
            font-size: 0.875rem;
            line-height: 1.29;
            font-weight: normal;
            letter-spacing: normal;
            position: relative;
            &:not(:last-child) {
                margin-bottom: 3.125rem;
                &:after {
                    content: '';
                    position: absolute;
                    bottom: -2.125rem;
                    left: 0;
                    right: 0;
                    width: 98%;
                    margin-left: auto;
                    margin-right: auto;
                    display: flex;
                    height: 1px;
                    background-color: #e6e6e6;
                }
            }
        }
    }
}

// Start Product Stock
.product-stock {
    margin: 1.5625rem 0 7.1875rem 0;
    nav {
        ul {
            border-bottom: 0;
            li {
                &.nav-item {
                    &:not(:last-child) {
                        @media screen and (min-width: 768px) {
                            margin-right: 6.25rem;
                        }
                    }
                }
                a {
                    font-weight: bold;
                    color: #353535;
                    &.nav-link {
                        border: 0;
                        &.active {
                            border: 0;
                            color: #bf232a;
                            background-color: transparent;
                            border-color: transparent;
                        }
                    }
                    &:hover {
                        border: 0;
                    }
                }
            }
        }
        @media screen and (min-width: 768px) {
            margin-bottom: 1.5625rem;
        }
    }
    .tab-pane {
        .package-card {
            @include lightBgAndBorder;
            @media screen and (min-width: 768px) {
                min-height: 40.9375rem;
            }
        }
    }
}
.product-info__actions__group ul li .svd-item:hover {
    text-decoration: underline;
}