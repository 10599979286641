.content-text {
    font-size: 1rem;
    color: #444;
    line-height: 1.38;
    padding: 3.125rem 0;
    h2 {
        font-size: 2.125rem;
        color: #353535;
        font-weight: normal;
        margin-bottom: 1.1rem;
    }
    ul {
        list-style-type: none;
        padding-left: 0;
        li {
            color: #444444;
            line-height: 1.38;
            &:before {
                content: "- ";
            }
        }
    }
    a {
        color: $mainTextClr;
        &:hover {
            text-decoration: unset;
        }
    }
    @media screen and (max-width: 767px) {
        br {
            display: none;
        }
    }
}