// Start Captcha
.captcha-wrapper {
    max-width: 100%;
    width: 100%;
    @media screen and (min-width: 576px) {
        max-width: 19rem;
        * {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }
    height: auto;
    * {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

// Submit Btn
input[type="submit"] {
    font-weight: bold;
    font-size: 1rem;
    text-align: left;
    color: #fff;
    background-color: $mainTextClr;
    border:0;
    border-radius: 0 !important;
    padding: 0.77rem 0.77rem;
    height: 3.375rem !important;
    background-image: url('assets/images/arrow.svg');
    background-repeat: no-repeat no-repeat;
    background-position: right 1.125rem center;
    &:focus {
        outline: none;
        box-shadow: none;
    }
    width: 15rem;
    @media screen and (min-width: 992px) {
        width: 17.5rem;
        padding-left: 2rem;
    }
}

.searchBox {
    input[type="text"].form-control {
        @media screen and (min-width: 576px) {
            width: 100%;
        }
    }
}