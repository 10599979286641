// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i&display=swap");
// Modules
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/simplebar/dist/simplebar";

// Base Folder
@import "base/_normalize";
@import "base/_typography";
// Helpers Folder
@import "helpers/_functions";
@import "helpers/_mixins";
@import "helpers/_variables";
// Components Folder
@import "components/_buttons";
@import "components/_table";
@import "components/_breadcrumbs";
@import "components/_dropdown";
@import "components/_pagination";
@import "components/_tooltips";
@import "components/_overlay";
@import "components/_posts";
@import "components/_tab-navs";
@import "components/_about-item";
@import "components/_news-item";
@import "components/_content-text";
@import "components/_checkbox";
@import "components/_error";
@import "components/_form";
@import "components/_toasts";
@import "components/_preloader";
// Layout Folder
@import "layout/_header";
@import "layout/_navigation";
@import "layout/digitron-subnav";
@import "layout/_footer";
// Pages
@import "pages/_home";
@import "pages/_product";
@import "pages/_product-selector";
@import "pages/_search-results";
@import "pages/_terms";
@import "pages/_single-post";
@import "pages/_about";
@import "pages/_certificates";
@import "pages/mydigitron/_login";
@import "pages/mydigitron/_mydigitron";
@import "pages/_carrer";
@import "pages/_contacts";
@import "pages/_error-pages";
@import "pages/_product-overview";

// Start General Styles
.container-fluid {
    // max-width: 1440px;
    max-width: 1400px;
    &--fullWidth {
        max-width: unset;
        .post--fullSize {
            @media screen and (min-width: 768px) {
                min-height: 30.75rem;
            }
        }
    }
}

.text-muted {
    color: #a8a8a8 !important;
}

* {
    font-family: "Roboto Condensed", sans-serif;
}
html {
    position: relative;
    min-height: 100%;
}
body {
    background-color: $bodyBg;
    // max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 240px;
    @media screen and (min-width: 992px) {
        padding-bottom: 180px;
    }
    @media screen and (min-width: 1200px) {
        padding-bottom: 120px;
    }
}

main {
    padding-top: 4.75rem;
    @media screen and (min-width: 1200px) {
        padding-top: 7.84rem;
    }
    padding-bottom: 9.5rem;
}

.text-primary {
    color: $mainTextClr;
}

.text-secondary {
    color: #3b3b3b;
}

[type='file'],
[type='image'] {
cursor: pointer;
}
[type='file']::-webkit-file-upload-button {
-webkit-appearance: button;
cursor: pointer;
}