.post {
    color: #fff;
    &--fullSize {
        background: rgba(0, 0, 0, 0.32) url('assets/images/post-full.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center !important;
        padding: 1.375rem 0.8125rem 1.875rem 1.25rem;
        min-height: 23.75rem;
        border-top: 2px solid $mainTextClr;
        padding-top: 12.5rem;
        z-index: 2;
        @media screen and (min-width: 768px) {
            padding-top: 0;
        }
        a {
            color: $mainTextClr;
            &:hover {
                text-decoration: unset;
            }
        }
        .post__desc {
            @media screen and (min-width: 576px) {
                max-width: 80%;
            }
            @media screen and (min-width: 992px) {
                max-width: 50%;
            }
        }
    }
    &--long {
       @media screen and (min-width: 768px) {
            min-height: 34.0625rem;
            padding-bottom: 8.75rem;
       }
    }
    &--light {
        background:url('assets/images/post-full-light.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    &--threeColumn {
        padding: 1.375rem 2.125rem 1.125rem 1.875rem;
        min-height: 23.75rem;
        margin-top: 2.5rem;
        position: relative;
        z-index: 1;
        &:hover {
            text-decoration: none;
            color: #fff;
        }
        &::after {
            content: '';
            position: absolute;
            top:0;
            left:0;
            height: 100%;
            width: 100%;
            border-top: 2px solid $mainTextClr;
            background: rgba(0, 0, 0, 0.45);
            filter: blur(1px);
            -webkit-filter: blur(1px);
            z-index: -2;
            
        }
        &::before {
            content: '';
            position: absolute;
            top:0;
            left:0;
            opacity: 0;
            transition:  opacity .25s ease-in-out;
        }
        &:hover {
            &::before {
                position: absolute;
                top:0;
                left:0;
                height: 100%;
                width: 100%;
                background-color: rgba(191, 35, 42, 0.72);
                z-index: -1;
                opacity: 1;
            }
            .post__action {
                color: #fff;
                text-decoration: unset;
            }
            h6 {
                span {
                    color: #fff;
                }
            }
        }
        h6 {
            line-height: 1.19;
        }
    }
    &__action {
        color: $mainTextClr;
        font-weight: bold;
        transition:  all .25s ease-in-out;
        &:hover {
            text-decoration: unset;
        }
    }
    h1 {
        font-size: 2.125rem;
        font-weight: normal;
        line-height: normal;
        margin-bottom: 1.5rem;
    }
    h6 {
        // font-size: 1rem;
        // font-weight: bold;
        font-size: 1.2rem;
        font-weight: normal;
        line-height: normal;
        margin-bottom: 1rem;
        @media screen and (max-width: 1199px) {
            br {
                display: none;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        span {
            color: $mainTextClr;
            transition:  all .25s ease-in-out;
        }
    }
    p {
        font-size: 0.875rem;
        line-height: 1.29;
        margin-bottom: 0.5rem;
    }
}