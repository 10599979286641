.items-group:not(.no-mobile) {
    .items-group__table__row-body {
        padding: 0 0.875rem;
        @media screen and (max-width: 767px) {
            border-radius: 3px;
            border: solid 1px #9e9e9e;
        }
        @media screen and (max-width: 767px) {
            &:not(:first-child) {
                margin-top: 25px;
            }
        }
        @media screen and (min-width: 768px) {
            padding: 0.78125rem 0.875rem;
        }
        .column {
            position: relative;
        }
        @media screen and (max-width: 767px) {
            .column:first-child {
                font-size: .85rem;
                text-align: center;
                width: 40%;
                position: relative;
                justify-content: center;
                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 1px;
                    height: 100%;
                    background-color: #9e9e9e;
                }
            }
            .column {
                height: 100%;
                padding: 0.625rem 1rem;
                display: flex;
                align-items: center;
            }
            .column:last-child { 
                width: 60%;
                
            }
            .mobile-row {
                &:nth-of-type(even) {
                    background-color: #efefef;
                    border-right: 1px solid #9e9e9e;
                    border-left: 1px solid #9e9e9e;
                }
            }
        }
        &:nth-of-type(odd) {
            background-color: transparent;
        }
        @media screen and (min-width: 768px) {
            .column {
                width: 100%;
            }
            &:nth-of-type(odd) {
                border-radius: 3px;
                background-color: #efefef;
            }
        }
    }
}
// ==================================================
.items-group {
    &__title {
        h2 {
            font-size: 2.125rem;
            color: #353535;
            line-height: normal;
        }
        p {
            color: #444444;
            line-height: 1.38;
        }
    }
    table.table tbody tr td {
        font-size: .85rem;
        &:hover {
            background-color: unset;
        }
    }

    &__table {
        &__row-header {
            border: 1px solid $mainTextClr;
            border-radius: 3px;
            padding: 0.78125rem 0.875rem;
            h6 {
                font-size: 0.875rem;
                font-weight: normal;
                line-height: normal;
                margin-bottom: 0;
                color: #353535;
                &.link-head {
                    color: $mainTextClr;
                }
            }
        }
        &__row-body {
            padding: 0.78125rem 0.875rem;
            &:nth-of-type(odd) {
                border-radius: 3px;
                background-color: #efefef;
            }
            p {
                font-size: 0.875rem;
                line-height: normal;
                margin-bottom: 0;
                color: #353535 
            }
            a {
                color: $mainTextClr;
                font-size: 0.875rem;
                &:hover {
                    text-decoration: unset;
                }
            }
            input.form-control {
                border-radius: 3px;
                border: solid 1px #e7e7e7;
                &:focus {
                    outline: none;
                    box-shadow: unset;
                    border: solid 1px #e7e7e7;
                }
            }
        }
        &--border {
            table {
                border-top: 2px solid #bf232a;
            }
        }
        table {
            thead {
               tr {
                   th {
                       font-size: 0.875rem;
                       line-height: normal;
                        &::after {
                            display: none;
                        }
                        &::before {
                            display: none;
                        }
                        &.text-secondary {
                            color: #353535 !important;
                        }
                   }
               }
            }
            tbody {
                td {
                    padding: 0.75rem !important;
                }
            }
        }
    }
    
    textarea {
        font-size: 1rem;
        font-weight: bold;
        color: #a8a8a8;
        resize: none;
        &:focus {
            outline: none;
            box-shadow: none;
            border-color: #ced4da;
        }
    }
    &--form {
        padding: 2.375rem 1rem;
        background-color: #f2f2f2;
    }
    &__captcha {
        .form-group {
            label {
                font-size: 1rem;
                font-weight: bold;
                color: #a8a8a8;
                margin-bottom: 0;
            }
            .form-control {
                width: 7rem;
                @media screen and (min-width: 992px) {
                    width: 10rem;
                }
                &:focus {
                    outline: none;
                    box-shadow: none;
                    border-color: #ced4da;
                }
                border-radius: 3px;
                border: solid 1px #e7e7e7;
            }
        }
        &__img {
            height: 1.875rem;
            max-width: 5rem;
            img {
                height: 100%;
                width: 100%;
            }
        }
        button {
            &.btn-primary {
                border-radius: 0;
                width: 11.25rem;
            }
        }
    }
    &:not(:first-child) {
        margin-top: 3.75rem;
        @media screen and (min-width: 768px) {
            margin-top: 10.3125rem;
        }
    }
    &:last-child {
        margin-top: 2.1875rem;
        @media screen and (min-width: 768px) {
            margin-top: 8rem;
        }
    }
}
.items-group__table__row-body .svd-item:hover{
    text-decoration: underline;
}
