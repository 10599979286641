nav.mydigitron-nav {
    margin-top: 1.3125rem;
    padding: 1rem 0;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #bf232a;
    ul {
        @media screen and (min-width: 992px) {
            justify-content: space-between;
        }
        li {
            line-height: normal;
            display: flex;
            align-items: center;
            &.active {
                a {
                    span {
                        color: $mainTextClr;
                    }
                }
            }
            @media screen and (max-width: 991px) {
                &:not(:first-child) {
                    margin-top: 1.25rem;
                }
            }
            // &:not(:first-child):not(:last-child) {
            //     margin-left: 0rem;
            //     @media screen and (min-width: 1200px) {
            //         margin-left: 2.5rem;
            //     }
            // }
            &:last-child {
                position: relative;
                @media screen and (max-width: 991px) {
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: -.7rem;
                        height: 1px;
                        width: 100%;
                        background: $mainTextClr;
                    }
                }
                @media screen and (min-width: 992px) {
                    // margin-left: auto;
                    &:before {
                        content: '';
                        position: absolute;
                        left:0;
                        top: -1.0625rem;
                        height: 4.75rem;
                        width: 1px;
                        background: $mainTextClr;
                    }
                }
            }
            a {
                font-weight: bold;
                color: #353535;
                line-height: normal;
                display: flex;
                align-items: center;
                img {
                    height: 1.3rem;
                }
                svg {
                    margin-right: 0.75rem;
                    @media screen and (min-width: 992px) {
                        margin-right: 0;
                    }
                    @media screen and (min-width: 1200px) {
                        margin-right: 0.4375rem;
                    }
                }
                transition: .25s ease-in-out;
                &:hover {
                    text-decoration: unset;
                    color: $mainTextClr
                }
            }
        }
    }
}