.toasts {
    background-color: #fff8cf;
    color: #fe3636;
    font-size: 1.1rem;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    padding: 1rem 1.5rem;
    max-width: 17.5rem;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
    position: fixed;
    bottom: 15px;
    right: 10px;

    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
    &.hide {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
    }
    a {
        color: #fe3636;
        text-decoration: underline;
        transition: opacity 0.25s ease-in-out;
        &:hover {
            opacity: 0.8;
        }
    }
}


.alert-custom {
    font-size: 1.1rem;
    position: fixed;
    bottom: 15px;
    right:0;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
    padding: 1.25rem 1.25rem;
    z-index: 99;
    @media screen and (min-width: 768px) {
        right: 10px;
    }
    .close {
        position: absolute;
        padding: 0 0 0 1.5rem;
        top:0;
        right: 7px;
        &:focus {
            outline: none;
            box-shadow: none;
        }
        @media screen and (min-width: 768px) {
            position: relative;
        }
    }
}
