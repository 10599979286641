table.table {
  &.table-selector {
    thead {
      background-color: #fbfbfb;
      th {
        font-size: 0.8rem;
        line-height: normal;
        padding: 0.7rem 0.5rem;
      }
    }
    tbody {
      tr {
        td {
          padding: 1rem 0rem;
          .btn {
            padding: 0.4rem 0.4rem;
          }
          @media screen and (min-width: 992px) {
            padding: 0.5rem 0.5rem;
          }
        }
      }
    }
  }

  thead {
    border-radius: 3px;
    border: solid 1px $mainTextClr;
    tr {
      th {
        font-size: 0.8rem;
        font-weight: normal;
        color: $mainTextClr;
        position: relative;
        &[colspan="1"] {
          padding-bottom: 0;
        }
        &:not(:last-child) {
          &:before {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 0.5px;
            height: 100%;
            background-color: #ededed;
          }
        }
        &:not(:first-child) {
          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 0.5px;
            height: 100%;
            background-color: #ededed;
          }
        }
        &[colspan="4"] {
          & + th {
            &:after {
              width: 1px;
            }
          }
          &:after {
            display: none;
          }
          &:before {
            display: none;
          }
        }
        span {
          font-size: 0.75rem;
          font-weight: bold;
          sub {
            font-size: 0.4375rem;
          }
        }
      }
      &.units {
        th {
          padding: 0;
          // font-weight: 600;
          &:last-child {
            &:before {
              content: "";
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 0.5px;
              height: 100%;
              background-color: #ededed;
            }
          }
          sub {
            font-size: 85%;
          }
        }
        &:last-of-type {
          th {
            padding-bottom: 0.4rem;
          }
        }
      }
    }
  }
  th,
  td {
    border-bottom: 0;
    border-top: 0;
  }
  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: $bodyBg;
      }
      &:nth-of-type(even) {
        border-radius: 3px;
        background-color: #efefef;
      }
      td {
        font-size: 0.75rem;
        font-weight: normal;
        color: #3b3b3b;
        padding: 0.5rem;
        @media screen and (min-width: 992px) {
          font-size: 0.875rem;
        }
        @media screen and (max-width: 1199px) {
          &:first-child {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
        @media screen and (min-width: 1200px) {
          padding: 2rem 1.25rem;
        }
        &:hover {
          cursor: pointer;
          background-color: #d7d7d7;
        }
        a {
          color: $mainTextClr;
          &:hover {
            text-decoration: underline;
          }
          &.item-saved {
            img {
              width: 25px;
              height: auto;
            }
          }
        }
      }
    }
  }
}
// sub table

// Start Mobile Table
.mobile-table {
  border-radius: 3px;
  border: solid 1px #9e9e9e;
  &:not(:first-child) {
    margin-top: 1rem;
  }
  .table__body {
    &__row {
      &:nth-child(odd) {
        background-color: $bodyBg;
      }
      &:nth-child(even) {
        background-color: #efefef;
      }
      &__col {
        padding: 0.625rem 1rem;
        &:first-child {
          font-size: 0.75rem;
          font-weight: normal;
          line-height: normal;
          text-align: center;
          color: #1c1c1c;
          width: 40%;
          text-align: center;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            width: 1px;
            height: 100%;
            background-color: #9e9e9e;
          }
          span {
            font-size: 0.625rem;
            font-weight: bold;
          }
        }
        &:last-child {
          font-size: 0.875rem;
          font-weight: normal;
          font-style: normal;
          font-stretch: condensed;
          line-height: normal;
          letter-spacing: normal;
          color: #3b3b3b;
          width: 60%;
          a {
            color: $mainTextClr;
            // &:hover {
            //     text-decoration: unset;
            // }
            &.item-saved {
              img {
                width: 25px;
                height: auto;
              }
            }
          }
        }
      }
      input {
        font-size: 1rem;
        font-weight: bold;
        border-radius: 3px;
        border: solid 1px #e7e7e7;
        height: 1.875rem;
        &:focus {
          outline: none;
          box-shadow: none;
          border: solid 1px #e7e7e7;
        }
      }
    }
    &__rowspan {
      &__colspan {
        font-size: 0.75rem;
        font-weight: normal;
        line-height: normal;
        text-align: center;
        color: #1c1c1c;
        width: 40%;
        position: relative;
        div:nth-child(1) {
          padding: 0 0.625rem;
        }
        div:nth-child(2) {
          div {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 30px;
            padding: 0.625rem 0.625rem;
            &:nth-child(even) {
              background-color: $bodyBg;
            }
            &:nth-child(odd) {
              background-color: #efefef;
            }
            &:last-child {
              height: 45px;
            }
          }
        }
        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background-color: #9e9e9e;
        }
      }
      &__col {
        font-size: 0.875rem;
        font-weight: normal;
        font-style: normal;
        font-stretch: condensed;
        line-height: normal;
        letter-spacing: normal;
        color: #3b3b3b;
        width: 60%;
        a {
          color: $mainTextClr;
          // &:hover {
          //     text-decoration: unset;
          // }
        }
        div {
          display: flex;
          align-items: center;
          height: 30px;
          padding: 0.625rem 1rem;
          &:nth-child(even) {
            background-color: $bodyBg;
          }
          &:nth-child(odd) {
            background-color: #efefef;
          }
          &:last-child {
            height: 45px;
          }
        }
      }
    }
  }
}

// Sorting arrows
small.arrow-up {
  &:hover {
    cursor: pointer;
  }
  &:after {
    display: inline-block;
    margin-top: 0.1rem;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3rem solid;
    border-right: 0.3rem solid transparent;
    border-bottom: 0;
    border-left: 0.3rem solid transparent;
    color: #8d8d8d;
    transform: rotate(180deg);
  }
}

small.arrow-down {
  &:hover {
    cursor: pointer;
  }
  &:after {
    display: inline-block;
    margin-top: 0.1rem;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3rem solid;
    border-right: 0.3rem solid transparent;
    border-bottom: 0;
    border-left: 0.3rem solid transparent;
    color: #8d8d8d;
  }
}

.product-type-table {
  #scrollingcontent {
    position: relative;
  }
  .table {
    min-width: 1020px;
    table-layout: auto;
  }
  .table-sticky {
    transition: 0.25s ease-in-out;
    top: 0;
    position: relative;
    @media screen and (min-width: 992px) {
      //top: 96px;
      position: sticky;
    }
    &.isSticky,
    &.isFixed {
      @media screen and (max-width: 991px) {
        top: 166px;
      }
      thead {
        background-color: #fbfbfb;
      }
    }
  }
  .hideTbody {
    table-layout: fixed;
    thead {
      display: table;
      table-layout: fixed;
    }
    tbody {
      display: none;
      table-layout: fixed;
      height: 0px;
      z-index: -10;
      opacity: 0;
      visibility: hidden;
    }
  }
}

.scrollingcontent {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
