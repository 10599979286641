.post-content {
    margin-top: 0.625rem;
    &__title {
        h2 {
            font-size: 2.125rem;
            color: #353535;
            line-height: normal;
        }
    }
    &__date {
        margin-bottom: 2.1875rem;
        p {
            font-weight: bold;
            line-height: normal;
            color: #a8a8a8;
        }
    }
    &__text {
        p {
            color: #444444;
            font-weight: normal;
            line-height: 1.38;
        }
        a {
            color: $mainTextClr;
            &:hover {
                text-decoration: unset;
            }
        }
    }
}

.news.blog {
    margin-bottom: 4rem;
}