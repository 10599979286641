footer {
    box-shadow: 0 -2px 11px 0 rgba(191, 35, 42, 0.2);
    background-color: #fbfbfb;
    border-top: 2px solid $mainTextClr;
    padding: 1rem 0 1rem 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    height: 245px;
    @media screen and (min-width: 992px) {
        height: 185px;
    }
    @media screen and (min-width: 1200px) {
        height: 125px;
    }
    .footer__brand {
        z-index: 10;
        max-width: 7rem;
        @media screen and (min-width: 1200px) {
            max-width: 9.875rem;
        }
    }
    .footer--main-clr {
        z-index: 3;
        & * {
            font-weight: bold;
            line-height: normal;
            letter-spacing: normal;
            transition: all 0.25s ease-in-out;
            color: #353535;
        }
    }
    a {
        transition: all 0.3s ease-in-out;
        z-index: 3;
        &:hover {
            text-decoration: unset;
            color: $mainTextClr;
        }
    }
    .phone {
        transition: all 0.3s ease-in-out;
        &:hover {
            color: $mainTextClr;
            text-decoration: unset;
        }
    }
    ul {
        z-index: 3;
        li {
            z-index: 3;
            &:not(:last-child) {
                margin-right: 0.92rem;
                a {
                    &:after {
                        content: "";
                        position: absolute;
                        height: 100%;
                        width: 2px;
                        top: 0;
                        right: -0.55rem;
                        background-color: #444444;
                        background-color: $mainTextClr;
                    }
                }
            }
            a {
                font-weight: bold;
                color: #353535;
                line-height: normal;
                letter-spacing: normal;
                position: relative;
                z-index: 3;
                &:hover {
                    color: $mainTextClr;
                    text-decoration: unset;
                }
                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    transform: scaleX(0);
                    height: 2px;
                    bottom: -1px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    background-color: $mainTextClr;
                    transform-origin: bottom right;
                    transition: transform 0.25s ease-out;
                }
                &:hover {
                    &::before {
                        transform: scaleX(1);
                        transform-origin: bottom left;
                    }
                }
            }
        }
    }
    small {
        color: #444444;
        font-size: 0.875rem;
        line-height: 2.14;
    }
    @media screen and (max-width: 991px) {
        .footer__menu {
            position: absolute;
            ul {
                flex-direction: column;
                align-items: flex-end;
                li {
                    margin-right: 0;
                    a {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
        small {
            letter-spacing: -.5px;
        }
    }
}