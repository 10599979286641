.about-item {
    &:hover {
        text-decoration: unset;
        color: #353535;
    }
    &[data-href] {
        &:hover {
            box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17);
            cursor: pointer;
        }
    }
    transition:  all .25s ease-in-out;
}
.about-item {
    min-height: 21.25rem;
    background-color: #fff;
    padding: 1.25rem 1.25rem 0.8125rem 1.25rem;
  
    @media screen and (min-width: 768px) {
        padding: 2rem 2rem;
    }
    &--left {
        border-left: 2px solid #bf232a;
        .about-item__info {
            padding-right: 0.625rem;
        }
    }
    &--right {
        border-right: 2px solid #bf232a;
        .about-item__info {
            padding-left: 0.625rem;
        }
    }
    &__info {
        max-width: 30rem;
        color: #353535;
        h1 {
            font-size: 2.125rem;
            font-weight: normal;
            line-height: normal;
            margin-bottom: 1.5rem;
        }
        h6 {
            font-size: 1rem;
            // font-weight: bold;
            font-weight: normal;
            line-height: normal;
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
            span {
                color: $mainTextClr;
                transition:  all .25s ease-in-out;
            }
            a {
                color: $mainTextClr;
                &:hover {
                    text-decoration: unset;
                }
            }
        }
        a {
            &.about-item-action {
                padding-top: 1.5rem;
                color: $mainTextClr;
                font-weight: bold;
                transition:  all .25s ease-in-out;
                display: inline-block;
                &:hover {
                    text-decoration: unset;
                }
            }
        }
    }
    
    &__img {
        img {
            width: 100%;
            height: 12.5rem;
            object-fit: cover;
            @media screen and (min-width: 768px) {
                min-height: 17.5rem;
                height: 100%;
                max-width: 26.5625rem;
            }
            @media screen and (min-width: 1200px ){
                max-width: 47.5rem;
            }
        }
    }
}