@mixin lightBgAndBorder {
    border-radius: 0.1875rem;
    border: solid 0.0625rem #e5e5e5;
    background-color: #ffffff;
    padding: 0.625rem 0.75rem;
}

@mixin scrollBar {
    .simplebar-track {
        &.simplebar-vertical {
            background-color: #dedddd;
            width: 4px;
            .simplebar-scrollbar {
                // background-color: rgb(179, 179, 179);
                background-color: #bf232a;
                width: 4px;
                right: 0;
                &:before {
                    background-color: transparent;
                }
            }
        }
    }
}

@mixin dropdown {
    border: 0;
    border-radius: 0;
    padding: 0.8125rem 0.75rem 0.9375rem 0.75rem;
    box-shadow: 0 5px 10px 0 rgba(245, 0, 11, 0.17);
    background-color: rgba(#fff, 0.95);
    overflow-y: auto;
    width: 100%;
    margin-top: 0;
    a {
        &.dropdown-item {
            &:not(:last-child) {
                margin-bottom: 1rem;
            }
            font-weight: bold;
            color: #353535;
            line-height: 1.19;
            white-space: normal;
            padding: 0;
            transition: all .3s ease-in-out;
            &:hover {
                background-color: transparent;
                color: $mainTextClr;
            }
        }
    }
}

@mixin customDropdown {
    @media screen and (min-width: 768px) {
        width: 22.7rem;
    }
    margin-top: 2.375rem;
    &.show {
        box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17);
    }
    @media screen and (max-width: 991px) {
        max-width: 100%;
        button,a.dropdown-toggle {
            width: 100%;
            
            span {
                width: calc(100% - 80px);
            }
        }
    }
    button, a.dropdown-toggle {
        font-size: 1.4rem;
        @media screen and (min-width: 992px) {
            font-size: 1.75rem;
        }
        font-weight: normal;
        line-height: normal;
        background-color: #ffffff;
        color:#353535;
        border:0;
        border-radius: 0; 
        padding: 0 0;
        transition:  opacity .25s ease-in-out;
        display: flex;
        align-items: center;
        white-space: normal;
        text-align: left;
        
        &:hover {
            cursor: default;
        }
        &::after {
            display: none;
        }
        &:focus {
            outline: unset;
            box-shadow: unset;
        }
        span, .link-to-products {
            padding: 0.375rem 0.875rem;
            @media screen and (min-width: 768px) {
                width: 17.75rem;
            }
        }
        &[aria-expanded="true"] {
            svg {
                transform: rotate(180deg);
                path {
                    stroke: #fff;
                }
            }
            .btn__icon {
                background-color: $mainTextClr;
            }
        }
        .btn__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 5rem;
            width: 5rem;
            &:hover {
                background-color: $mainTextClr;
                cursor: pointer;
                button & {
                    box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17);
                }
                svg {
                    path {
                        stroke: #fff;
                    }
                }
            }
        }
    }
    .dropdown-menu {
        @include scrollBar;
        @include dropdown;
        bottom:0;
        width: 100%;
        margin-top: 1px !important;
        @media screen and (min-width: 768px) {
            width: 22.75rem;
        }
        height: 13.125rem;
        padding: 0.8125rem 0.875rem 0.9375rem 0.875rem;
        overflow-x: hidden;
    }
    transition:  opacity .25s ease-in-out;
}