ul.pagination {
  li:first-child {
    border: solid 1px #bf232a;
    margin-right: 0.875rem;
    .page-link {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 0.2rem 0.5rem;
    }
  }
  li:last-child {
    border: solid 1px #bf232a;
    margin-left: 0.875rem;
    .page-link {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding: 0.2rem 0.5rem;
    }
  }
  li {
    &:not(:first-child):not(:last-child):not(:nth-last-child(2)) {
      margin-right: .3rem;
    }
  }
  li.page-item {
    border-radius: 0;
    &:not(:first-child):not(:last-child) {
      align-self: center;
      .page-link {
        height: 1.3125rem;
        padding: 0rem 0.4em;
        line-height: normal;
      }
    }
    &.active {
      .page-link {
        background-color: $mainTextClr;
        border-color: $mainTextClr;
        color: #fff;
        
      }
    }
  }
  .page-link {
    font-size: 1rem;
    font-weight: bold;
    color: $mainTextClr;
    background-color: transparent;
    border: 0;
    transition: all 0.25s ease-in-out;
    border-radius: 0;
    &:hover {
      background-color: $mainTextClr;
      color: #fff;
      svg {
        path {
          stroke: #fff;
        }
      }
    }
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}
