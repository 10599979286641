.tooltip {
  .arrow {
    display: none;
  }
  &.show {
    opacity: 1 !important;
  }
  .tooltip-inner {
    font-size: 0.875rem;
    line-height: normal;
    letter-spacing: normal;
    font-weight: normal;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(255, 139, 60, 0.5);
    background-color: #fff4d5;
    color: #3b3b3b;
    opacity: 1;
  }
  z-index: 100;
}

// @media screen and (max-width: 1199px) {
//   .tooltip {
//     display: none !important;
//   }
// }
