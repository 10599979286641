.product-types {
    margin: 2.5rem 0 6rem 0;
    .product-overview__col {
        &:nth-last-child(-n+3) {
            .product-overview__item {
                margin-bottom: 0;
            }
        }
    }
}
.product-overview__item {
    background-color: #fff;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    transition: all .25s ease-in-out;
    &.active {
        box-shadow: 0 2px 10px 0 rgba(245,0,11,.17);
    }
    padding: 1rem 1rem 1rem 1rem;
    @media screen and (min-width: 768px) {
        min-height: 22rem;
        padding: 1.5rem 2rem 1.5rem 1rem;
    }
    @media screen and (min-width: 992px) {
        min-height: 25rem;
    }
    @media screen and (min-width: 1200px) {
        min-height: 22rem;
    }
    &__title {
        font-size: 1.75rem;
        line-height: normal;
        letter-spacing: normal;
        color: #353535;
        border-bottom: 2px solid #bf232a;
        padding-bottom: .75rem;
        margin-bottom: .75rem;
    }
    &__desc {
        font-size: 1.2rem;
        line-height: normal;
        letter-spacing: normal;
        color: #353535;
        padding-bottom: 1rem;
    }
    &__action {
        margin-top: auto;
        a {

            font-weight: 700;
            font-size: 1rem;
            color: #bf232a;
            text-decoration: unset;
        }
    }
}

.product-stock #scrollingcontent {
    .scrollingcontentblock {
        overflow-x: auto;
    }
}