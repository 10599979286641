.open-positions {
    &__title { 
        font-size: 2.5rem;
        color: #353535;
        letter-spacing: normal;
        line-height: normal;
        margin-bottom: 3.125rem;
    }
    .open-position {
        background-color: #fff;
        border-left: 2px solid $mainTextClr;
        transition: .25s ease-in-out;
        margin-bottom: 1.375rem;
        min-height: 18.75rem;
        padding: 0.9375rem 2rem 1rem 1.25rem;
        @media screen and (min-width: 1200px) {
            padding: 0.9375rem 8.125rem 1rem 1.25rem;
        }
        &.active {
            transition: .25s ease-in-out;
            box-shadow: 0 2px 10px 0 rgba(245, 0, 11, 0.17);
        }
        &__title {
            max-width: 28rem;
            h3 {
                font-size: 2.125rem;
                color: #353535;
                letter-spacing: normal;
                line-height: normal;
                margin-bottom: 1.875rem;
            }
            @media screen and (min-width: 992px) {
                margin-right: 1.5rem;
            }
            @media screen and (min-width: 1200px) {
                margin-right: 3rem;
            }
        }
        .apply-position {
            display: flex;
            font-weight: bold;
            align-items: center;
            justify-content: space-between;
            border-radius: 0;
            border: solid 1px #bf232a;
            background-color: #bf232a;
            color: #fff;
            padding: 1rem 1rem;
            width: 15rem;
            @media screen and (min-width: 576px) {
                width: 17.5rem;
            }
            span {
                padding-left: 1rem;
            }
            &:hover {
                text-decoration: unset;
            }
        }
        &__desc {
            p,ul {
                font-size: 1rem;
                color: #444444;
            }
            ul {
                list-style-type: none;
                padding-left: 0;
                li {
                    &:before {
                        content: "- ";
                    }
                }
            }
            a {
                font-weight: bold;
                font-size: 1rem;
                color: $mainTextClr;
                &:hover {
                    text-decoration: unset;
                }
            }
        }
    }
}
// Submit a Resume
.submit-resume {
    background-color: #f2f2f2;
    margin-top: 6rem;
    padding: 2rem 0 3.8rem 0;
    @media screen and (min-width: 768px) {
        margin-top: 13.75rem;
    }
    .section-title {
        h2 {
            margin-bottom: 1rem;
        }
        margin-bottom: 2.5rem;
    }
    .label {
        height: 35px;
    }

    label {
        font-weight: bold;
    }

    @media screen and (max-width: 767px) {
        .form-group-wrapper {
            width: 100%;
        }
    }
    .right {
        @media screen and (min-width: 768px) {
            margin-left: 4rem;
        }
        @media screen and (min-width: 992px) {
            margin-left: 13rem;
        }
        .file-group {
            margin-top: 1rem;
            button {
                font-weight: bold;
                color: $mainTextClr;
                padding: 0;
                vertical-align: top;
                &:hover {
                    cursor: pointer;
                }
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
            span {
                color: #353535;
                font-weight: bold;
                display: inline-block;
                padding-left: .7rem;
            }
            span.EditingFormErrorLabel {
                max-width: 280px !important;
                display: block !important;
                padding-left: 0 !important;
            }
            span:not(.EditingFormErrorLabel) {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 180px;
            }
            .custom-file {
                margin-left: 1.8rem;
                input[type=file] {
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    width: 82px;
                    height: 26px;
                    cursor: pointer;
                }
            }
        }
    }
    input[type="text"], input[type="email"] {
        height: 35px;
    }
    input[type="text"], input[type="email"],textarea {
        font-size: 1rem;
        font-weight: bold;
        color: #353535;
        
        border-radius: 3px;
        border: solid 1px #e7e7e7;
        &:focus {
            outline: none;
            box-shadow: unset;
            border: solid 1px #e7e7e7;
        }
        @media screen and (min-width: 768px) {
            width: 15rem;
        }
        @media screen and (min-width: 992px) {
            width: 17.5rem;
        }
    }
    textarea {
        height: 8.5625rem;
        resize: none;
    }
    button {
        &.btn-primary {
            display: flex;
            font-weight: bold;
            align-items: center;
            justify-content: space-between;
            border-radius: 0;
            border: solid 1px #bf232a;
            background-color: #bf232a;
            border-radius: 0 !important;
            color: #fff;
            padding: 1rem 1rem;
            width: 15rem;
            @media screen and (min-width: 992px) {
                width: 17.5rem;
            }
            span {
                padding-left: 1rem;
            }
            &:hover {
                text-decoration: unset;
            }
        }
    }
    &.apply-position-form {
        margin-top: 1.3125rem;
        padding:0;
        background-color: transparent;
        .apply-position-form-wrapper {
            background-color: #fff;
            padding: 0.75rem 1.4375rem 2.3125rem 1.4375rem;
        }
    }
}
.message-request{  
    padding: 2rem 0 4.8rem 0;
  }