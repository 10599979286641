.certificates {
    background-color: #f2f2f2;
    padding: 3rem 0 5rem 0;
    margin-bottom: 2.5rem;
    h2 {
        font-size: 2.125rem;
        color: #353535;
        line-height: normal;
        margin-bottom: 3.125rem;
    }
    &__group {
        &--wrapper {
            @media screen and (min-width: 768px) {
                display: flex;
                width: 100%;
            }
        }
        ul:last-child {
            @media screen and (min-width: 768px) {
                margin-left: 5%;
            }
            @media screen and (min-width: 992px) {
                margin-left: 10%;
            }
            @media screen and (min-width: 1200px) {
                margin-left: 25%;
            }
            @media screen and (max-width: 767px) {
                li:first-child {
                    margin-top: 1.5rem;
                }
            }
        }
        ul {
            li {
                &:not(:last-child) {
                    margin-bottom: 1.5rem;
                }
                a {
                    font-size: 1.75rem;
                    color: $mainTextClr;
                    line-height: normal;
                    &:hover {
                        text-decoration: unset;
                        color: $mainTextClr;
                    }
                    svg {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}