.btn {
    padding: 0.77rem 0.77rem;
    &.btn-primary {
        border-radius: 3px;
        border: solid 1px $mainTextClr;
        background-color: $mainTextClr;
        color: #fff;
        font-weight: bold;
        &:not(.disabled):active,
        &:not(:disabled):active {
            background-color: $mainTextClr;
            border-color: $mainTextClr;
        }
        &:not(:disabled):not(.disabled):active:focus {
            outline: none;
            box-shadow: none;
        }
        &:focus {
            box-shadow: none;
        }
    }
    &.btn-secondary {
        border-radius: 3px;
        border: solid 1px $mainTextClr;
        background-color: #ffffff;
        font-weight: bold;
        color: $mainTextClr;
    }
}

button {
    &.filtering-btn {
        color: $mainTextClr;
        border: solid 1px $mainTextClr;
        background-color: #ffffff;
        font-weight: bold;
        border-radius: 0;
        line-height: normal;
        width: 100%;
        &:focus {
            box-shadow: unset;
            outline: none;
        }
        @media screen and (min-width: 992px) {
            width: auto;
        }
    }
}