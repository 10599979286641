.custom-checkbox {
    input[type="checkbox"] {
        &:checked~label::before {
            border-color: $mainTextClr;
            background-color: $mainTextClr;
        }
        &:focus~label::before {
            box-shadow: none;
        }
        &:not(:disabled):active~label::before {
            border-color: #fff;
            background-color: #fff;
        }
        &:focus:not(:checked) ~ label::before {
            border-color: $mainTextClr;
        }
        &:focus {
            background: #fff;
        }
    }
    label { 
        font-size: 0.875rem;
        font-weight: normal;
        line-height: 2.14;
        color: #3b3b3b;
        border-radius: 0;
        &::before {
            border-radius: 0 !important;
            width: 0.9375rem;
            height: 0.9375rem;
            border: 1px solid $mainTextClr;
            border-radius: 0;
        }
        &:hover {
            cursor: pointer;
            &::before {
                background-color: #f1f1f1;
            }
        }
    }
}
